import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { forkJoin, isObservable, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IApplicationTranslatableLanguages } from '../../models/ITranslatableParameters';
import { LocalStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from 'src/app/shared/utils/local.storage.keys';
import { TranslateParser } from '@ngx-translate/core';
import { ErrorHandlerService } from './error.handler.service';

// tslint:disable: max-line-length
@Injectable()
export class TranslationConstants
{
    constructor(
        private readonly translateService: TranslateService,
        private readonly http: HttpClient,
        private readonly errorHandler: ErrorHandlerService,
        private readonly session: LocalStorageService,
        public parser: TranslateParser,
    )
    { }

    public translate(path: string): string
    {
        let translate = this.instant(this.translateService.currentLang, path);
        if(translate) {
            return translate;
        }
        return this.translateService.instant(path);
    }

    public instant(lang, key: any): any {
        let translationsRetrieved = this.getTextsFromMemory();

        if(!translationsRetrieved) {
            return undefined;
        }

        let translations = translationsRetrieved[lang];
        
        if(!translations) {
            return undefined;
        }
        
        let res: string | Observable<string> | undefined;

        if (key instanceof Array) {
            let result: any = {},
                observables: boolean = false;
            for (let k of key) {
                result[k] = this.instant(translations, k);
                if (isObservable(result[k])) {
                    observables = true;
                }
            }
            if (observables) {
                const sources = key.map(k => isObservable(result[k]) ? result[k] : of(result[k] as string));
                return forkJoin(sources).pipe(
                    map((arr: Array<string>) => {
                        let obj: any = {};
                        arr.forEach((value: string, index: number) => {
                            obj[key[index]] = value;
                        });
                        return obj;
                    })
                );
            }
            return result;
        }

        if (translations) {
            res = this.parser.interpolate(this.parser.getValue(translations, key));
        }

        if (typeof res === "undefined") {
            return undefined;
        }

        return typeof res !== "undefined" ? res : undefined;
    }

    public getTextsFromMemory(): IApplicationTranslatableLanguages {
        return this.session.get(LocalStorageKeys.TRANSLATABLE_TEXTS);
    }

    public getTexts(): Observable<IApplicationTranslatableLanguages> {
        return this.http.get<IApplicationTranslatableLanguages>(RestEndpoint.translatableTexts, { params: { 'applicationIdentifier': 'eniato' } })
            .pipe(
                map(data => {
                    if (data) {
                        this.session.set(LocalStorageKeys.TRANSLATABLE_TEXTS, data);
                    }
                }),
                catchError((err) => { return this.errorHandler.validate(err); })
            );
    }
}
