import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormatStringService {

    constructor() { }

    /**
     * Convert a text to limit the maximum number of characters, putting ellipsis in the middle to indicate that there is more text
     * @param string The text to be masked
     * @param maxLength Maximum number of characters in the string that can appear
     * @returns 
     */
    public getEllipsisInTheMiddle(string: string, maxLength: number = 6) {
        if (!string || maxLength < 1 || string.length <= maxLength) {
            return string;
        }

        if (maxLength === 1) {
            return string.substring(0, 1) + '...';
        }
    
        var middlePoint = Math.ceil(string.length / 2);
        var quantityToHide = string.length - maxLength;
        var leftString = Math.ceil(quantityToHide / 2);
        var rightString = quantityToHide - leftString;
        return `${string.substring(0, middlePoint - leftString)}...${string.substring(middlePoint + rightString)}`;
    }
}