import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { IPaymentConfigs } from "../../models/IPaymentConfigs";

@Injectable()
export class PaymentConfigsService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public getPaymentConfigs(): Observable<Array<IPaymentConfigs>> {

        return this.http.get(RestEndpoint.getPaymentConfigs)
            .pipe(
                map((data: Array<IPaymentConfigs>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }
}