/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * AXIA CONFIDENTIAL                                                             *
 * ------------------                                                            *
 *                                                                               *
 * This file, project or its parts can not be copied and/or distributed without  *
 * the express permission of Axia Digital Solutions LTDA.                        *
 *                                                                               *
 * Project: eniato                                                                 *
 * @file: IAssets.model.ts                                                             *
 * @Date: Wednesday, 17th February 2021                                          *
 * @author: Nayara Goulart (nayara.goulart@axiadigitalsolutions.com)             *
 * ----                                                                          *
 * Last Modified: Wednesday, 17th February 2021                                  *
 * Modified By: Nayara Goulart (nayara.goulart@axiadigitalsolutions.com)         *
 * ----                                                                          *
 * Copyright (C) 2020 Axia Digital Solutions LTDA - All Rights Reserved.         *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Address } from './IAddress.model';
import { IAssetsTokens } from './IAssetsTokens.model';
import { Modes } from './modes.enum';
import { Photo } from './photo.model';
import { Statuses } from './statuses.enum';
import { BigNumber } from 'bignumber.js';
import { NFTStakingApplicationRewards } from './INFTStaking';
import { Collections } from './collections';
import { AuctionModel } from './auction.model';

export class PaymentTypes {
    public unitOfMoney: string;
    public percentage: number;
}

export class Assets {
    public id: string;
    public address: Address;
    public name: string;
    public allow_split?: boolean;
    public short_name?: string; // When its a NFT Package
    public description: string;
    public mode: Modes;
    public quantity?: number;
    public status: Statuses;
    public category_id?: string;
    public disabled: Date;
    public mobiled: boolean;
    public vacancies: number;
    public type?: string = 'NFT';
    public contract: string;
    public bedrooms: number;
    public block_resell?: boolean;
    public enabled?: boolean;
    public bathrooms: number;
    public area: number;
    public acceptBnb?: boolean;
    public bnbPrice?: BigNumber;
    public acceptCoin?: boolean;
    public coinPrice?: BigNumber;
    public minimumToBuy?: number;
    public maxToBuy?: number;
    public bothMandatory?: boolean;
    public constructedArea: number;
    public photos: Array<Photo>;
    public url?: string;
    public when: Date;
    public generatedTokens: BigNumber;
    public characteristics?: Array<ProductCharacteristics> = [];
    public category?: any;
    public price: BigNumber;
    public external_link?: string;
    public skipPrice?: boolean;
    public nft_package_type?: string;
    public payment_types?: PaymentTypes[] = [];
    public only_package?: boolean;
    public token?: IAssetsTokens;
    public wallets_commission: Array<{id: string, commission: number}> = [];
    public resale?: AssetResaleData;
    public nftId?: string;
    public blockchainId?: string;
    public reward_details?: NFTStakingApplicationRewards;
    public on_staking?: boolean;
    public is_reward_prize?: boolean;
    public collection: Collections;
    public onAuction?: boolean;
    public collection_id?: string;
    public availableTokens: BigNumber;
    public auction?: AuctionModel;
    public auctionTimeExpiration?: string; // Used in frontend to display the time expiration.
    public onResell?: boolean;
    public originalPrice?: BigNumber;

    public getCorrectPrice(): number{
        if (this.resale?.onResale){
            return new BigNumber(this.resale.resaleValue).toNumber();
        }
        return new BigNumber(this.price).toNumber();
    }

    constructor (object?: Assets){
        if (object){
            Object.assign(this, object);
        }
    }
}

export class ResellAsset {
    public nftId: string;
    public only_for_customer: string;
    public resellValue: BigNumber;
}

export class AssetResaleData {
    public onResale: boolean;
    public resaleValue: BigNumber;
    public sellerName?: string;
    public sellerId?: string;
    public publicWalletKey?: string;
}

export class ProductCharacteristics {
    public key: string;
    public value: string;
    public isEditing?: boolean; // Used only in Frontend
}