import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { IOrderEvent } from '../../models/order.event';

@Injectable()
export class CheckoutService
{
    constructor(
        private readonly http: HttpClient
    )
    { }

    public createCheckout(order: IOrderEvent): Observable<IOrderEvent>
    {
        let endpoint = order?.userEmail ? RestEndpoint.createExternalCheckout : RestEndpoint.createCheckout; 
        return this.http.post<IOrderEvent>(
            endpoint, order
        );
    }
}
