import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FeaturesStatusService } from '../shared/services/util/features-status.service';
import { FeatureNames } from '../shared/models/feature-names.enum';
import { SidenavItem } from '../shared/models/sidenav-item.enum';
import { SessionStorageService } from 'angular-web-storage';
import { SessionlStorageKeys } from '../shared/utils/session.storage.keys';

/**
 * Block some route if is not logged
 */
@Injectable()
export class FeatureGuardService implements CanActivate {

    constructor (
        private readonly featuresStatusService: FeaturesStatusService,
        private readonly sessionStorage: SessionStorageService,
        private readonly router: Router
    )
    { }

    /**
     * Security guard for some routes
     */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let hasPermission: boolean = false
        const featureRequired: FeatureNames = route.data.featureRequired as FeatureNames;
        const shouldValidateToken: boolean = route.data.shouldValidateToken as boolean;
        if (featureRequired) {
            this.featuresStatusService.getFeatureStatus(featureRequired).subscribe(data => {
                hasPermission = data;
            });
        }
        if (!hasPermission) {
            void this.router.navigate(['/home']);
        }

        if(shouldValidateToken) {
            let isDisabled: boolean = true;
            let featureName: string;
            const featuresByToken: Array<SidenavItem> = await this.sessionStorage.get(SessionlStorageKeys.FEATURE_AVAILABLE_BY_TOKEN);
            featuresByToken?.forEach(feature => {
                if (state.url?.includes(feature?.name)) {
                    isDisabled = feature.isDisabled;
                    featureName = feature.name;
                }
            });
            const name = state?.url.split('/navigation/')[1];

            if(isDisabled && !state.url?.includes('stake/') && !state.url?.includes('buy')) {
                void this.router.navigate(['navigation/functionality-unavailable/' + name]);
            }
            return true;
        }
        return hasPermission;
    }
}
