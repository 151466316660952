import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ShareLinkService {
    constructor() { }
    
    /**
     * Method used to open share via whatsapp
     * @param message Message to be sent next to the link of the current page.
     */
    public shareWhatsapp(message: string, shareLink: boolean = true, linkToShare?: string): void {
        const shareableLink = linkToShare ? linkToShare : window.location.href;

        const finalText: string = shareLink ? `${message} ${encodeURI(shareableLink)}` : message;
        const link = `https://wa.me/?text=${encodeURIComponent(finalText)}`;

        if (navigator.userAgent.match(/iPhone|Android/i)) {
            window.open(`whatsapp://send?text=${encodeURIComponent(finalText)}`, 'whatsapp-share');
        } else {
            window.open(link, 'whatsapp-share');
        }
    }
}