import { Injectable } from '@angular/core';
import { ConfigReaderService } from './config.reader.service';

interface Scripts
{
    name: string;
    src: string;
}

@Injectable({
    providedIn: 'root'
})
export class ScriptService
{
    private readonly scripts: any = {};

    constructor(
        private readonly myConfigReaderService: ConfigReaderService
    )
    {
        let scriptStore: Array<Scripts> = [];

        this.myConfigReaderService.getPaymentScripts().subscribe(data => {
            scriptStore = [
                {name: 'paypal', src: data.paypal},
                {name: 'pagseguro', src: data.pagseguro}
            ];
        });

        scriptStore.forEach((script: Scripts) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    public load(...scripts: Array<string>): Promise<Array<any>>
    {
        const promises: Array<Promise<any>> = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    public loadScript(name: string): Promise<any>
    {
        return new Promise((resolve, reject) => {

            if (this.scripts[name].loaded)
            {
                resolve({script: name, loaded: true, status: 'Already Loaded'});
            }
            else
            {
                const script: HTMLScriptElement  = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;

                script.onload = () => {
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                };

                script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
