export enum InternalPagesNames {
    RECEIVE = 'receive',
    TRANSFER = 'transfer',
    HISTORY = 'history',
    FRIENDS = 'friends',
    BUYTOKEN = 'buy-token',
    INDICATIONS = 'indications',
    STAKE = 'stake',
    WITHDRAWAL = 'withdrawal',
    NFTS = 'nfts',
    HELP = 'help',
    NEGOTIATE = 'negotiate',
    ECOSYSTEM = 'ecosystem',
    OTHERS = 'others',
    SELL = 'sell',
    PAYMENTS = 'my-payments',
    PERSONAL_DOCUMENTS = 'personal-documents',
    ESCROW = 'escrow',
    INVOICE = 'invoice',
    BASIC_TRADING = 'purchase-and-sale',
}