import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { IContactDetails } from "../../models/IContactDetails";
import { IDigitalBankingConfigs, IBankingFeaturesControlResponse } from "../../models/IDigitalBankingConfigs";
import { IExternalLinks } from "../../models/IExternalLinks";
import { IPaymentScripts } from "../../models/IPaymentScripts";
import { ITransferDetails } from "../../models/ITransferDetails";
import { BankingAccountFeatures } from 'src/app/shared/models/BankingAccount';
import { IStorageParameters } from "../../models/IStorageParameters";
import { IKairosConfig } from "../../models/IKairosConfig";
import { IEniatoConfig } from "../../models/IEniatoConfig";
import { IConfigForIN1888 } from "../../models/IConfigForIN1888";

@Injectable()
export class ConfigReaderService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public getEniatoConfigs(): Observable<IEniatoConfig> {

        return this.http.get(RestEndpoint.getEniatoConfig)
            .pipe(
                map((data: IEniatoConfig) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserLocation(): Observable<any> {
        return this.http.get<any>('https://geolocation-db.com/json/')
            .pipe(
                catchError(err => {
                    throw (err);
                }),
                map(response => {
                    return response;
                })
            )

    }

    public getAllExternalLinks(): Observable<IExternalLinks> {

        return this.http.get(RestEndpoint.getExternalLinks)
            .pipe(
                map((data: IExternalLinks) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getKairosConfig(): Observable<IKairosConfig> {
        return this.http.get(RestEndpoint.getKairosConfig)
            .pipe(
                map((data: IKairosConfig) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getContactDetails(): Observable<IContactDetails> {

        return this.http.get(RestEndpoint.getContactDetails)
            .pipe(
                map((data: IContactDetails) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getTransferDetails(): Observable<ITransferDetails> {

        return this.http.get(RestEndpoint.getTransferDetails)
            .pipe(
                map((data: ITransferDetails) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getPaymentScripts(): Observable<IPaymentScripts> {

        return this.http.get(RestEndpoint.getExternalLinks)
            .pipe(
                map((data: IPaymentScripts) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getDigitalBankingConfigs(): Observable<IDigitalBankingConfigs> {

        return this.http.get(RestEndpoint.getDigitalBankingConfigs)
            .pipe(
                map((data: IDigitalBankingConfigs) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getConfigForIN1888(): Observable<IConfigForIN1888> {

        return this.http.get(RestEndpoint.getConfigForIN1888)
            .pipe(
                map((data: IConfigForIN1888) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getImageStorageParameters(): Observable<IStorageParameters> {
        return this.http.get(RestEndpoint.getImageStorageParameters)
            .pipe(
                map((res: { data: IStorageParameters }) => {
                    return res.data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getDigitalBankingFeatureAvailability(featureName: BankingAccountFeatures): Observable<IBankingFeaturesControlResponse> {

        return this.http.get(RestEndpoint.getDigitalBankingFeatureAvailability, {
            params: { 'featureName': featureName }
        })
            .pipe(
                map((res: { data: IBankingFeaturesControlResponse }) => {
                    res.data.featureConfig.featureName = featureName;
                    return res.data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}