import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { SnackBarTheme } from 'src/app/shared/models/snackbar-theme.enum';
import { AssetsService } from 'src/app/shared/services/account/asset.services';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { EthUtils } from 'src/app/shared/utils/eth.utils';

@Component({
    selector: 'app-modal-send',
    templateUrl: './modal-send.component.html',
    styleUrls: ['./modal-send.component.scss']
})
export class ModalSendComponent implements OnInit {

    public loading: boolean;
    public assetId: string = '';
    public nftId: string = '';
    public blockchainId: string = '';
    public walletNumber: string = '';
    public asset: Assets;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {asset: Assets},
        private readonly dialogRef: MatDialogRef<ModalSendComponent>,
        private readonly snackbarComponent: SnackbarComponent,
        private readonly assetsService: AssetsService,
        private readonly translationConstants: TranslationConstants
    ) { }

    public ngOnInit(): void {
        if (this.data) {
            this.assetId = this.data.asset.id;
            this.nftId = this.data.asset.nftId;
            this.blockchainId = this.data.asset.blockchainId;
            this.asset = new Assets(this.data.asset);
        }
    }

    public getMainPhoto(asset: Assets): string {
        return this.assetsService.getAssetMainPhoto(asset);
    }

    public close(): void {
        this.dialogRef.close();
    }

    public validateWalletFormat(): boolean{
        return EthUtils.checkAddress(this.walletNumber);
    }

    public sendAsset(): void {
        this.loading = true;
        this.assetsService.sendNft({
            assetId: this.assetId,
            customerIdToTransferTo: this.walletNumber,
            unitOfMoney: this.assetId,
            nftId: this.nftId,
            blockchainId: this.blockchainId
        }).subscribe(success => {
            if (success?.result === 'error') {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modal.sendError'), SnackBarTheme.error, 4000);
            } else {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modal.sendSuccess'), SnackBarTheme.success, 4000);
                this.dialogRef.close(true);
            }
        }, error => {
            if(error?.error?.message?.includes('find the destiny user')) {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modal.user'), SnackBarTheme.error, 4000);
            } else {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modal.sendError'), SnackBarTheme.error, 4000);
            }

        }).add(() => {
            this.loading = false;
        })
    }
}
