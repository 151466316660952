import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReloadSidenavBalance {

    public reloadBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public shouldReloadBalance$: Observable<boolean> = this.reloadBalance.asObservable();

    constructor(

    ) {
    }

}