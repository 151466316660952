/**
 * This regex checks if the wallet format entered by the user matches the format of an Ethereum wallet.
 */
export  class EthUtils {
    public static checkAddress(address: string): boolean {
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
            // Check if it has the basic requirements of an address
            return false;
        }
        return true;
    }
}