import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { IChatCreationRequest, IChatFullRequest, IChatGroupRequest, IChatMessage, IEmojiReaction, IGetGroupByUserId, IListLastUnreadChatMessages, IMessageReadRequest, TMessageType, WebSocketActions } from '../../models/chat.model';
import { RestEndpoint } from 'src/app/constants/rest-endpoint.constants';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from '../account/account.services';
import { webSocket , WebSocketSubject} from 'rxjs/webSocket';
import { parseISO } from 'date-fns';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChatService {
    public userId: string = '';
    private subject : WebSocketSubject<any> | undefined;
    private sendNotification : WebSocketSubject<any> | undefined;
    private messageSubject: Subject<any> = new Subject<any>();
    public message$: Observable<any> = this.messageSubject.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly accountService: AccountService,
    ) {
        this.accountService.getLoggedUserDetails().subscribe((user) => {
            this.userId = user?.id;
        }).add(() => this.connectWebsocket());
    }

    public connectWebsocket() {
        this.subject = webSocket(environment.webService + '/register');

        // Registering client id
        this.subject.next(this.userId);

        //Subscribing to WebSocket messages
        this.subject.subscribe({
            next: (msg: {senderId: string, message: any}) => {
                try {
                    const parsedMsg = { senderId: msg?.senderId, message: JSON.parse(msg.message)} ;

                    this.messageSubject.next(parsedMsg);
                } catch (e) {
                    // console.error('Message is not a valid JSON:', e);
                }
            }
        });
    }

    public sendMessage(receiverId: string, message: any) {
        this.sendNotification = webSocket(environment.webService + '/broadcast');

        // Registering client id
        this.sendNotification.next({ senderId: this.userId,  receiverId: receiverId, message: message }); 

        // Subscribing to WebSocket messages
        this.sendNotification.subscribe();
    }
     
    public ngOnDestroy() {
        this.subject?.unsubscribe();
        this.sendNotification.unsubscribe();
    }

    public createChat(chatRequest: IChatCreationRequest, usersIds?: Array<{ userId: string }>): Observable<any> {
        chatRequest.message = JSON.stringify(chatRequest.message) as any;

        return this.http.post<any>(RestEndpoint.createChat, chatRequest)
            .pipe(
                map((data: any) => {
                    const receivedId = chatRequest.groupId ? chatRequest.groupId : chatRequest.receiverId;
                    if(usersIds) {
                        usersIds.map((user) => this.sendMessage(user.userId, chatRequest.message));
                    } else {
                        this.sendMessage(receivedId, chatRequest.message);
                    }
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getChatList(partId: string): Observable<{correspondentId: string }[]> {
        return this.http.get<any>(RestEndpoint.getListChats, { params: { partId }})
            .pipe(
                map((data: { data: Array<{correspondentId: string }>}) => {
                    return data.data
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getListFullChat(partId1: string, partId2: string): Observable<IChatMessage[]> {
        return this.http.get<any>(RestEndpoint.getListFullChat, { params: { partId1, partId2 }})
            .pipe(
                map((data: { data: IChatFullRequest[] }) => {
                    const listFullChat: IChatMessage[] = data?.data?.map((list) => {
                        const message = JSON.parse(list.message as any as string);

                        return {
                            id: list?.id,
                            content: message?.content ? message?.content : '',
                            messageType: message?.type ? message?.type : 'text',
                            reactions: message?.reactions,
                            readyBy: [],
                            edited: message?.edited,
                            when: parseISO(list.sentWhen as any as string),
                            sender: {
                                name: list?.sender?.name,
                                userId: list?.sender?.id,
                                username: list?.sender?.email,
                                imageUrl: list?.sender?.selfieImage ? list?.sender?.selfieImage : 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
                            },
                            receiver: {
                                name: list?.receiver?.name,
                                userId: list?.receiver?.id,
                                username: list?.receiver?.email,
                                imageUrl: list?.receiver?.selfieImage ? list?.receiver?.selfieImage : 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
                            },
                            groupId: list?.groupId,
                            readWhen: list?.readWhen
                        }
                    });

                    return listFullChat;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateMessage(messageId: string, message: string | File, requesterId: string, chatId: string, membersGroup?: Array<{ userId: string }>): Observable<any> {
        const messageEdit = JSON.stringify({ content: message, edited: new Date()});

        return this.http.post<any>(RestEndpoint.updateMessageInChat, { id: messageId, message: messageEdit, requesterId: requesterId })
            .pipe(
                map((data: any) => {
                    const messageWebsocket = JSON.stringify({ content: message, edited: new Date(), chatId: chatId, messageId: messageId, isGroup: membersGroup ? true : false });
                    if(membersGroup) {
                        membersGroup.map((user) => this.sendMessage(user.userId, messageWebsocket));
                    } else {
                        this.sendMessage(chatId, messageWebsocket);
                    }
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateReaction(messageId: string, message: string | File, messageType: TMessageType, requesterId: string, chatId: string, reactions: IEmojiReaction[], membersGroup?: Array<{ userId: string }>): Observable<any> {
        const messageEdit = JSON.stringify({ content: message, reactions: reactions, type: messageType, chatId: chatId, isGroup: membersGroup ? true : false });

        return this.http.post<any>(RestEndpoint.updateMessageInChat, { id: messageId, message: messageEdit, requesterId: requesterId })
            .pipe(
                map((data: any) => {
                    const messageWebsocket = JSON.stringify(
                        { 
                            content: message, 
                            chatId: chatId, 
                            reactions: reactions, 
                            messageId: messageId, 
                            isGroup: membersGroup ? true : false,
                            action: WebSocketActions.REACTION_MSG
                        });
                    if(membersGroup) {
                        membersGroup.map((user) => this.sendMessage(user.userId, messageWebsocket));
                    } else {
                        this.sendMessage(chatId, messageWebsocket);
                    }
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public imageUrl(img: string | undefined): string {
        return img ? img : 'assets/images/avatar-chat.png';
    }

    public getListLastUnreadChatMessages(partId: string): Observable<IListLastUnreadChatMessages[]> {
        return this.http.get<any>(RestEndpoint.getListLastUnreadChatMessages, { params: { partId}})
            .pipe(
                map((listLastUnreadChatMessages: { data: IListLastUnreadChatMessages[]} ) => {
                    return listLastUnreadChatMessages?.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public deleteMessage(messageId: string, requesterId: string): Observable<any> {
        return this.http.post<any>(RestEndpoint.deleteMessageInChat, { id: messageId, requesterId })
            .pipe(
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createChatGroup(groupName: string, ownerId: string, users: Array<{ userId: string }>): Observable<any> {
        return this.http.post<any>(RestEndpoint.createChatGroup, { groupName, ownerId,  users})
            .pipe(
                map((data: any ) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getListGroupByUserId(userId: string): Observable<IGetGroupByUserId[]> {
        return this.http.get<any>(RestEndpoint.getListGroupByUserId, { params: { userId: userId } })
            .pipe(
                map((data: { data: IGetGroupByUserId[] } ) => {
                    return data?.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getGroupById(groupId: string): Observable<IGetGroupByUserId> {
        return this.http.get<any>(RestEndpoint.getChatGroupById + groupId, {})
            .pipe(
                map((data: { data: IGetGroupByUserId } ) => {
                    return data?.data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the group');
                })
            );
    }

    public deleteChatGroup(groupId: string): Observable<any> {
        return this.http.delete<any>(RestEndpoint.deleteChatGroup + groupId, {})
            .pipe(
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public flagRead(messageRead: IMessageReadRequest): Observable<any> {
        return this.http.post<any>(RestEndpoint.chats + messageRead.chatId + RestEndpoint.flagReadInChat, messageRead , { params: { id: messageRead.chatId }})
            .pipe(
                map((data: any ) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }
    
    public groupUpdate( groupUpdated: IChatGroupRequest ): Observable<any> {
        return this.http.put<any>(RestEndpoint.updateChatGroup, { 
            id: groupUpdated.id, 
            groupName: groupUpdated.groupName,
            ownerId: groupUpdated.ownerId,
            users:  groupUpdated.users
        }).pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    };

    // WEBSOCKET ACTIONS
    public sendNotificationCreateGroup(membersGroup?: Array<{ userId: string }> ) {
        const messageWebsocket = JSON.stringify({ action: WebSocketActions.CREATED_CHAT_GROUP });
        
        if(membersGroup) {
            membersGroup.map((user) => this.sendMessage(user.userId, messageWebsocket));
        };
    }

    public sendNotificationDeletedGroup(groupId, membersGroup?: Array<{ userId: string }> ) {
        const messageWebsocket = JSON.stringify({ action: WebSocketActions.DELETED_CHAT_GROUP, chatId: groupId});
        
        if(membersGroup) {
            membersGroup.map((user) => this.sendMessage(user.userId, messageWebsocket));
        };
    }

    public sendNotificationAddedGroup(groupId: string, membersGroup?: Array<{ userId: string }> ) {
        const messageWebsocket = JSON.stringify({ action: WebSocketActions.MEMBER_ADDED_CHAT_GROUP, chatId: groupId });
        
        if(membersGroup) {
            membersGroup.map((user) => this.sendMessage(user.userId, messageWebsocket));
        };
    }
};