import { ShoppingCartItem } from './shopping-cart-item.model';
import { Store } from './store.model';

export class ShoppingCart
{
    public store?: Store;

    constructor(
        public id: string,
        public userId: string,
        public storeId: string,
        public items: Array<ShoppingCartItem>,
        public creationDate?: Date,
        public lastUpdate?: Date,
        public totalAmount?: number,
        public tokenAmount?: number,
        public shippingAmount?: number
    )
    {}
}
