import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ShoppingCartItem } from '../../../models/shopping-cart-item.model';
import BigNumber from 'bignumber.js';
import { ShoppingCart } from '../../../models/shopping-cart.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ShoppingCartAdapter } from '../adapters/shopping-cart.adapter';
import { RestEndpoint } from '../../../../constants/rest-endpoint.constants';
import { PlatformBalance } from '../../../models/wallet.balance';

/**
 * Shopping WiBX cart service
 */
@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    private shoppingCarts: Array<ShoppingCart> = [];
    private readonly cachedShoppingCartsByEnteprise: Map<string, ShoppingCart>;
    private readonly ZERO: BigNumber = new BigNumber(0);

    constructor(
        private readonly http: HttpClient,
        private readonly shoppingCartAdapter: ShoppingCartAdapter
    ) {
        this.cachedShoppingCartsByEnteprise = new Map();
    }

    /**
     * Gets the shopping carts of the current user session.
     */
    public getShoppingCarts(cached: boolean = true): Observable<Array<ShoppingCart>> {
        if (cached && this.shoppingCarts.length > 0) {
            return of(this.shoppingCarts);
        }

        return this.http.get<Array<any>>(RestEndpoint.shoppingCarts)
            .pipe(
                map((data: Array<any>) => data.map((item: any) => {
                    return this.shoppingCartAdapter.adapt(item);
                })),
                tap((shoppingCartsFounded: Array<ShoppingCart>) => this.shoppingCarts = shoppingCartsFounded),
                tap((shoppingCartsFounded: Array<ShoppingCart>) => {
                    shoppingCartsFounded.forEach((shoppingCart: ShoppingCart) => {
                        this.cachedShoppingCartsByEnteprise.set(shoppingCart.storeId.trim(), shoppingCart);
                    });
                })
            );
    }

    /**
     * Gets the shopping cart total amount of the given enterprise.
     *
     * This total amount includes the discount if it exists.
     *
     * @param storeId the interprise identifier.
     */
    public getTotalByEnterprise(storeId: string): BigNumber {
        const shoppingCart: ShoppingCart = this.cachedShoppingCartsByEnteprise.get(storeId.trim());

        if (shoppingCart && shoppingCart.items.length > 0) {
            let total: BigNumber = shoppingCart.items
                .map((cartItem: ShoppingCartItem) => new BigNumber(cartItem.item.price).multipliedBy(cartItem.quantity))
                .reduce((previousValue: BigNumber, currentValue: BigNumber) => previousValue.plus(currentValue), this.ZERO);

            return total;
        }

        return this.ZERO;
    }


    /**
     * Gets the shopping cart total amount of the given enterprise considering the wibx Percentage.
     *
     * This total amount includes the discount if it exists.
     *
     * @param storeId the interprise identifier.
     */
    public getTotalWithPercentageByEnterprise(storeId: string): BigNumber {
        const shoppingCart: ShoppingCart = this.cachedShoppingCartsByEnteprise.get(storeId.trim());

        if (shoppingCart && shoppingCart.items.length > 0) {
            let total: BigNumber = shoppingCart.items
                .map((cartItem: ShoppingCartItem) => {
                    return new BigNumber(cartItem.item.price).multipliedBy(cartItem.quantity);

                })
                .reduce((previousValue: BigNumber, currentValue: BigNumber) => previousValue.plus(currentValue), this.ZERO);

            return total;
        }

        return this.ZERO;
    }

    /**
     * Gets the shopping cart subtotal amount of the given enterprise.
     *
     * @param storeId the interprise identifier.
     */
    public getSubTotalByEnterprise(storeId: string): BigNumber {
        const shoppingCart: ShoppingCart = this.cachedShoppingCartsByEnteprise.get(storeId.trim());

        if (shoppingCart && shoppingCart.items.length > 0) {
            const total: BigNumber = shoppingCart.items
                .map((cartItem: ShoppingCartItem) => new BigNumber(cartItem.item.price)
                    .multipliedBy(cartItem.quantity))
                .reduce((previousValue: BigNumber, currentValue: BigNumber) => previousValue.plus(currentValue), this.ZERO);
            return total;
        }

        return this.ZERO;
    }

    /**
     * Gets the total of items of a shopping cart of the give enterprise.
     *
     * @param storeId the enterprise id.
     */
    public getTotalOfItemsByEnterprise(storeId: string): BigNumber {
        const shoppingCart: ShoppingCart = this.cachedShoppingCartsByEnteprise.get(storeId);

        if (shoppingCart && shoppingCart.items.length > 0) {
            return shoppingCart.items
                .map((cartItem: ShoppingCartItem) => {

                    return cartItem.quantity;
                })
                .reduce((previousValue: BigNumber, currentValue: BigNumber) => previousValue.plus(currentValue), this.ZERO);
        }

        return this.ZERO;
    }

    /**
     * Gets the total of items of a shopping cart
     */
    public getTotalOfItems(): BigNumber {
        let quantityTotal: BigNumber = this.ZERO;

        for (const shoppingCart of this.cachedShoppingCartsByEnteprise.values()) {
            const cartQuantityTotal = shoppingCart.items.map((cartItem: ShoppingCartItem) => {
                return cartItem.quantity;
            })
                .reduce((previousValue: BigNumber, currentValue: BigNumber) => previousValue.plus(currentValue), this.ZERO);

            quantityTotal = quantityTotal.plus(cartQuantityTotal);
        }

        return quantityTotal;
    }

    /**
     * Gets the shopping cart by enterprise id.
     *
     * @param storeId the enterprise id.
     */
    public getShoppingCartByEnterprise(storeId: string): ShoppingCart {
        return this.cachedShoppingCartsByEnteprise.get(storeId?.trim());
    }

    /**
     * Removes the shopping cart item of the give enterprise.
     *
     * @param storeId the enterprise identifier.
     * @param shoppingCartItem the shopping cart item to be removed.
     */
    public removeShoppingCartItemByEnterprise(storeId: string, shoppingCartItem: ShoppingCartItem): Observable<void> {
        const shoppingCart: ShoppingCart = this.cachedShoppingCartsByEnteprise.get(storeId.trim());
        return this.removeShoppingCartItem(shoppingCart, shoppingCartItem);
    }

    /**
     * Add a shopping cart item.
     *
     * The quantity of the shopping cart item will be increased.
     *
     * @param shoppingCart the shopping cart tha the item belongs to.
     * @param shoppingCartItem the shopping cart item.
     */
    public addShoppingCartItem(shoppingCart: ShoppingCart, shoppingCartItem: ShoppingCartItem): Observable<string> {
        return this.http.post<any>(`${RestEndpoint.shoppingCarts}`, {
            storeId: shoppingCart.storeId,
            itemId: shoppingCartItem.item.id,
            product_name: shoppingCartItem.item.name,
            quantity: shoppingCartItem.quantity || 1
        })
            .pipe(
                map((data: any) => data.id)
            );
    }

    public quotations(currency: string): Observable<any> {
        let unitOfMoney = currency;
        return this.http.get(RestEndpoint.quotations, { params: { currency, unitOfMoney } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((error) => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public balance(unitOfMoney): Observable<PlatformBalance[]> {
        return this.http.get(RestEndpoint.balance, {
            params: {
                unitOfMoney
            }
        })
            .pipe(
                map((data: PlatformBalance[]) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    /**
     * Removes a shopping cart item.
     *
     * The quantity of the shopping cart item will be decreased.
     *
     * @param shoppingCart the shopping cart tha the item belongs to.
     * @param shoppingCartItem the shopping cart item.
     */
    public removeShoppingCartItem(shoppingCart: ShoppingCart, shoppingCartItem: ShoppingCartItem, quantity?: number): Observable<void> {
        return this.http.put<void>(`${RestEndpoint.shoppingCarts}${shoppingCart.id}/item/${shoppingCartItem.item.id}`, {
            shoppingCartId: shoppingCart.id,
            itemId: shoppingCartItem.item.id,
            product_name: shoppingCartItem.item.name,
            quantity: quantity ? quantity : 1
        })
            .pipe();
    }


    public clearCachedShoppingCarts(enterprise?: string): void {
        if (enterprise) {
            this.cachedShoppingCartsByEnteprise.delete(enterprise);
        } else {
            this.cachedShoppingCartsByEnteprise.clear();
        }
    }



    public allBalances(): Observable<PlatformBalance[]> {
        return this.http.get(RestEndpoint.balance, {
        })
            .pipe(
                map((data: PlatformBalance[]) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

}
