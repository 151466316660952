import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrderBook } from '../../models/order.book.journal.dto';
import { RestEndpoint } from 'src/app/constants/rest-endpoint.constants';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ExchangeService {
    // TODO: remove fake
    public randomDateTime = [
        '2023/06/01 14:07:24',
        '2023/06/01 13:09:12',
        '2023/06/01 09:38:02',
        '2023/06/01 00:26:27',
        '2023/06/01 16:05:30',
        '2023/06/01 19:19:59',
        '2023/06/01 11:45:29',
        '2023/06/01 14:00:33',
        '2023/06/01 14:12:41',
        '2023/06/01 18:42:27',
        '2023/06/01 00:44:43',
        '2023/06/01 22:05:19',
        '2023/06/01 18:59:10',
        '2023/06/01 13:47:59',
        '2023/06/01 10:11:24',
        '2023/06/01 13:55:34',
        '2023/06/01 08:10:35',
        '2023/06/01 06:28:02',
        '2023/06/01 03:09:57',
        '2023/06/01 07:28:40',
        '2023/06/01 20:18:02',
        '2023/06/01 16:09:18',
        '2023/06/01 17:23:12',
        '2023/06/01 21:10:55',
        '2023/06/01 18:22:50',
        '2023/06/01 15:13:56',
        '2023/06/01 15:47:23',
        '2023/06/01 02:37:39',
        '2023/06/01 18:48:48',
        '2023/06/01 05:29:20',
        '2023/06/01 04:26:49',
        '2023/06/01 00:01:50'
    ];
    
    constructor(
        private readonly http: HttpClient,
    ) { }

    public getSpreadData(coinSymbol: string): Observable<{price: number, percentage: number}> {
        return of ({price: 70.24, percentage: 0.27 });
    }

    public getLatestTradesList(unitOfMoney: string): Observable<Array<OrderBook>> {
        return this.http.get<any>(RestEndpoint.getAllUnitOrder, {params: {unitOfMoney: unitOfMoney}})
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getLatestTradesListFromKraken(unitOfMoney: string): Observable<any> {
        return this.http.get<any>(RestEndpoint.getKrakenOrderBook, {params: {unitOfMoney: unitOfMoney}})
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }


    public getKrakenTrades(unitOfMoney: string): Observable<any> {
        return this.http.get<any>(RestEndpoint.getKrakenTrades, {params: {unitOfMoney: unitOfMoney}})
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }
}