import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { ProductSearch } from "../../models/product-search.model";
import { Product } from "../../models/product.model";
import { ProductsBackend } from "../../models/products.model";


import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import { AccountService } from '../account/account.services';
import { LocalStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from "../../utils/local.storage.keys";
import { IKairosBanners } from "../../models/IKairosBanners.model";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {

    public _settingsSource = new BehaviorSubject<number>(0);
    public favoriteCounted$ = this._settingsSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly accountService: AccountService,
        private readonly storage: LocalStorageService,
    ) { }

    public getProducts(): Observable<Array<Product>> {
        return this.http.get(RestEndpoint.getAllProducts, {})
            .pipe(
                map((data: Array<Product>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public searchProduct(filter: ProductSearch): Observable<Array<ProductsBackend>> {
        return this.http.post(RestEndpoint.searchProducts, filter)
            .pipe(
                map((data: Array<ProductsBackend>) => {
                    const convertedList: Array<ProductsBackend> = [];
                    if(data?.length > 0) {
                        for(let dt of data) {
                            convertedList.push(new ProductsBackend(dt));
                        }
                    }
                    return convertedList;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getProductById(productIdentifier: string): Observable<ProductsBackend> {
        return this.http.get(RestEndpoint.getProduct, { params: { productIdentifier } })
            .pipe(
                map((data: ProductsBackend) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public deleteProduct(id: string): Observable<boolean> {
        const url = RestEndpoint.deleteProduct + id;
        return this.http.delete<boolean>(url)
            .pipe(
                catchError((err) => {
                    throw err;

                })
            );
    }


    public saveProduct(newParameter: ProductsBackend): Observable<boolean> {
        return this.http.put<boolean>(RestEndpoint.updateProducts, newParameter)
            .pipe(
                map((updated: boolean) => {
                    return updated;
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public createProduct(product: ProductsBackend): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createProduct, product)
            .pipe(
                map((templateId: string) => {
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    /**
     * @param productId The product tha will be change the favorite
     * @param addFavorite If you are going to add the favorite
     * @param redirectUrl Route to go after logging in (if user was not logged in)
     * @returns Returns if the operation was successful
     */
    public toggleFavoriteProduct(productId: string, addFavorite?: boolean, redirectUrl?: string): Observable<boolean> {
        let toggleFavoriteSuccess: boolean = false;
        return this.http.post<boolean>( RestEndpoint.isAuthenticated, { })
        .pipe(
            map((authenticated: any) => {
                if ( !authenticated || !authenticated.isAuthenticated)
                {
                    const favoriteProductSaved: string = this.storage.get(LocalStorageKeys.FAVORITE_PRODUCT_ID);
                    // To prevent looping redirecting to login, in case the user gives up logging in.
                    if (!favoriteProductSaved) {
                        this.router.navigate(['/account/login'], {
                            queryParams: {
                                redirectUrl: redirectUrl,
                            }
                        });
                        this.storage.set(LocalStorageKeys.FAVORITE_PRODUCT_ID, productId);
                    } else {
                        this.storage.remove(LocalStorageKeys.FAVORITE_PRODUCT_ID);
                        toggleFavoriteSuccess = false;
                    }
                    toggleFavoriteSuccess = false;

                    return toggleFavoriteSuccess;
                } else {
                    if (addFavorite) {
                        this.createFavorites(productId).subscribe();
                    } else {
                        this.deleteFavorites(productId).subscribe();
                    }
                    toggleFavoriteSuccess = true;
                    this.storage.remove(LocalStorageKeys.FAVORITE_PRODUCT_ID);
                    return toggleFavoriteSuccess;
                }
            })
        );
    }

    public deleteFavorites(productId: string): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.updateFavorite, { productId: productId })
            .pipe(
                map((templateId: string) => {
                    this.countFavorites().subscribe();
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public getProductIdFromStorage(): string {
        return this.storage.get(LocalStorageKeys.FAVORITE_PRODUCT_ID);
    }

    public createFavorites(productId: string): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createFavorite, { productId: productId })
            .pipe(
                map((templateId: string) => {
                    this.countFavorites().subscribe();
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public createWatch(productId: string, email: string): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createWatch, { productId: productId, email: email })
            .pipe(
                map((templateId: string) => {
                    if (templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public getBanners(): Observable<Array<IKairosBanners>> {
        return this.http.get(RestEndpoint.getAllKairosBanners, {})
            .pipe(
                map((data: any) => {
                    this._settingsSource.next(data);
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public countFavorites(): Observable<any> {
        return this.http.get(RestEndpoint.countFavorites, {})
            .pipe(
                map((data: any) => {
                    this._settingsSource.next(data);
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public async getBrands(brand: string): Promise<any> {
        try {
            const response: any = await this.http.get<any>('https://parallelum.com.br/fipe/api/v1/carros/marcas').toPromise();
            if (response) {
                for(let data of response) {
                    if(data["nome"].toLowerCase() === brand.toLowerCase()) {
                        return data["codigo"]
                    }
                }
            }
        } catch (err) { }
    }

    public async getModel(brand: string, model: string): Promise<any> {
        try {
            const response: any = await this.http.get<any>(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${brand}/modelos`).toPromise();
            if (response) {
                for(let data of response["modelos"]) {
                    if(data["nome"].toLowerCase() === model.toLowerCase()) {
                        return data["codigo"]
                    }
                }
            }
        } catch (err) { }
    }

    public async getCarCode(brand: string, model: string, year: string): Promise<any> {
        try {
            const response: any = await this.http.get<any>(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${brand}/modelos/${model}/anos`).toPromise();
            if (response) {
                for(let data of response) {
                    if(data["nome"].toLowerCase().includes(String(year).toLowerCase())) {
                        return data["codigo"]
                    }
                }
            }
        } catch (err) { }
    }

    public async getPrice(brand: string, model: string, code: string): Promise<any> {
        try {
            const response: any = await this.http.get<any>(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${brand}/modelos/${model}/anos/${code}`).toPromise();
            if (response) {
                return response["Valor"];
            }
        } catch (err) { }
    }
}
