import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../services/account/account.services';
import { LoginUser } from '../../shared/models/login.user.model'
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { TranslationConstants } from '../services/util/translation.constants';
import { SnackBarTheme } from '../models/snackbar-theme.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IContactDetails } from '../models/IContactDetails';


@Component({
  selector: 'app-simple-contact-modal',
  templateUrl: './simple-contact-modal.component.html',
  styleUrls: ['./simple-contact-modal.component.scss']
})
export class SimpleContactModalComponent implements OnInit {

    public loading: boolean;
    public contactForm: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        text: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    private contactDetails: IContactDetails = undefined;

    constructor(
        private readonly dialogRef: MatDialogRef<SimpleContactModalComponent>,
        private readonly accountService: AccountService,
        private readonly translationConstants: TranslationConstants,
        private readonly snackbarComponent: SnackbarComponent,
        @Inject(MAT_DIALOG_DATA) public data: {contactDetails: IContactDetails},
        ) 
    { 
        this.contactDetails = data.contactDetails;
    }

    public ngOnInit(): void {
        // To be responsive
        this.dialogRef.addPanelClass('custom-modal');
        this.userLogged();
    }

    public mailMe(){
        this.loading = true;
        this.accountService.sendNotification({
            name: this.contactForm.value.name,
            contact: undefined,
            emailFrom: this.contactForm.value.email,
            emailTo: this.contactDetails.email,
            period: 'undefined',
            observation: this.contactForm.value.text,
            product: undefined,
            storeId: undefined,
            subject: 'Contato - Kairos'
        }).subscribe(success => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('marketplace.scheduleContactDialog.success'), SnackBarTheme.success, 4000);
            setTimeout(() => {
                this.dialogRef.close()
            }, 2000);
        }, error => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('marketplace.simpleContactModal.error'), SnackBarTheme.error, 4000);
        }).add(() => {
            this.loading = false;
        })

    }

    public close(): void {
        this.dialogRef.close();
    }

    public userLogged(): void {
        this.accountService.isAuthenticated(true).subscribe(success => {
            if (success) {
                this.accountService.getLoggedUserDetails().subscribe((user: LoginUser) => {
                    if (user) {
                        this.contactForm.patchValue({name: user.name.charAt(0).toUpperCase() + user.name.slice(1, user.name.length), email: user.email})
                    }
                })
            }
        })
    }
}
