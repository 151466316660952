import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AccountService } from '../shared/services/account/account.services';

/**
 * Block some route if is not logged
 */
@Injectable()
export class AuthGuardService implements CanActivate
{
    constructor(
        private readonly accountService: AccountService,
        private readonly router: Router
    )
    { }

    /**
     * Security guard for some routes
     * Changed the method so that it is asynchronous, since before it was allowing the display of the page before having the Subscribe result
     */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const module: string = route.data.module as string;

            if (module){
                this.accountService.checkPermission(module).subscribe(success => {
                    if (success){
                        return true;
                    }else{
                        void this.router.navigate(['/home']);
                        return false;
                    }
                })
            }

            this.accountService.isAuthenticated().subscribe(authentication => {
                if(authentication) {
                    return resolve(true);
                } else {
                    this.router.navigate(['/account/login']);
                    return resolve(false);
                }
            });
        })
    }
}
