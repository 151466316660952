import { ShoppingCartItemAdapter } from './shopping-cart-item.adapter';
import { Injectable } from '@angular/core';
import { ShoppingCart } from '../../../models/shopping-cart.model';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartAdapter
{
    constructor(
        private readonly shoppingCartItemAdapter: ShoppingCartItemAdapter
    )
    {}

    public adapt(data: any): ShoppingCart
    {
        const shoppingCart: ShoppingCart = new ShoppingCart(
            data.id,
            data.user_id,
            data.store_id,
            data.items
                .filter((item: any) => item.quantity > 0)
                .map((item: any) => this.shoppingCartItemAdapter.adapt(item)),
            new Date(data.creation_date),
            new Date(data.last_update)
        );
        shoppingCart.store = data.store;

        return shoppingCart;
    }

}
