export const countries = [
    {
        name: {
            common: "Christmas Island",
            official: "Territory of Christmas Island",
            nativeName: {
                eng: {
                    official: "Territory of Christmas Island",
                    common: "Christmas Island"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Eritrea",
            official: "State of Eritrea",
            nativeName: {
                ara: {
                    official: "دولة إرتريا",
                    common: "إرتريا‎"
                },
                eng: {
                    official: "State of Eritrea",
                    common: "Eritrea"
                },
                tir: {
                    official: "ሃገረ ኤርትራ",
                    common: "ኤርትራ"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "91"
            ]
        }
    },
    {
        name: {
            common: "Samoa",
            official: "Independent State of Samoa",
            nativeName: {
                eng: {
                    official: "Independent State of Samoa",
                    common: "Samoa"
                },
                smo: {
                    official: "Malo Saʻoloto Tutoʻatasi o Sāmoa",
                    common: "Sāmoa"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "85"
            ]
        }
    },
    {
        name: {
            common: "North Macedonia",
            official: "Republic of North Macedonia",
            nativeName: {
                mkd: {
                    official: "Република Северна Македонија",
                    common: "Македонија"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "89"
            ]
        }
    },
    {
        name: {
            common: "Djibouti",
            official: "Republic of Djibouti",
            nativeName: {
                ara: {
                    official: "جمهورية جيبوتي",
                    common: "جيبوتي‎"
                },
                fra: {
                    official: "République de Djibouti",
                    common: "Djibouti"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "53"
            ]
        }
    },
    {
        name: {
            common: "Jordan",
            official: "Hashemite Kingdom of Jordan",
            nativeName: {
                ara: {
                    official: "المملكة الأردنية الهاشمية",
                    common: "الأردن"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "62"
            ]
        }
    },
    {
        name: {
            common: "Pakistan",
            official: "Islamic Republic of Pakistan",
            nativeName: {
                eng: {
                    official: "Islamic Republic of Pakistan",
                    common: "Pakistan"
                },
                urd: {
                    official: "اسلامی جمہوریۂ پاكستان",
                    common: "پاكستان"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "2"
            ]
        }
    },
    {
        name: {
            common: "French Polynesia",
            official: "French Polynesia",
            nativeName: {
                fra: {
                    official: "Polynésie française",
                    common: "Polynésie française"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "89"
            ]
        }
    },
    {
        name: {
            common: "Ireland",
            official: "Republic of Ireland",
            nativeName: {
                eng: {
                    official: "Republic of Ireland",
                    common: "Ireland"
                },
                gle: {
                    official: "Poblacht na hÉireann",
                    common: "Éire"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "53"
            ]
        }
    },
    {
        name: {
            common: "Mauritania",
            official: "Islamic Republic of Mauritania",
            nativeName: {
                ara: {
                    official: "الجمهورية الإسلامية الموريتانية",
                    common: "موريتانيا"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "22"
            ]
        }
    },
    {
        name: {
            common: "Denmark",
            official: "Kingdom of Denmark",
            nativeName: {
                dan: {
                    official: "Kongeriget Danmark",
                    common: "Danmark"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "5"
            ]
        }
    },
    {
        name: {
            common: "Namibia",
            official: "Republic of Namibia",
            nativeName: {
                afr: {
                    official: "Republiek van Namibië",
                    common: "Namibië"
                },
                deu: {
                    official: "Republik Namibia",
                    common: "Namibia"
                },
                eng: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                her: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                hgm: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                kwn: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                loz: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                ndo: {
                    official: "Republic of Namibia",
                    common: "Namibia"
                },
                tsn: {
                    official: "Lefatshe la Namibia",
                    common: "Namibia"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "64"
            ]
        }
    },
    {
        name: {
            common: "Ghana",
            official: "Republic of Ghana",
            nativeName: {
                eng: {
                    official: "Republic of Ghana",
                    common: "Ghana"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "33"
            ]
        }
    },
    {
        name: {
            common: "Slovakia",
            official: "Slovak Republic",
            nativeName: {
                slk: {
                    official: "Slovenská republika",
                    common: "Slovensko"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "21"
            ]
        }
    },
    {
        name: {
            common: "American Samoa",
            official: "American Samoa",
            nativeName: {
                eng: {
                    official: "American Samoa",
                    common: "American Samoa"
                },
                smo: {
                    official: "Sāmoa Amelika",
                    common: "Sāmoa Amelika"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "684"
            ]
        }
    },
    {
        name: {
            common: "Moldova",
            official: "Republic of Moldova",
            nativeName: {
                ron: {
                    official: "Republica Moldova",
                    common: "Moldova"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "73"
            ]
        }
    },
    {
        name: {
            common: "Macau",
            official: "Macao Special Administrative Region of the People's Republic of China",
            nativeName: {
                por: {
                    official: "Região Administrativa Especial de Macau da República Popular da China",
                    common: "Macau"
                },
                zho: {
                    official: "中华人民共和国澳门特别行政区",
                    common: "澳门"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "53"
            ]
        }
    },
    {
        name: {
            common: "Turks and Caicos Islands",
            official: "Turks and Caicos Islands",
            nativeName: {
                eng: {
                    official: "Turks and Caicos Islands",
                    common: "Turks and Caicos Islands"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "649"
            ]
        }
    },
    {
        name: {
            common: "Seychelles",
            official: "Republic of Seychelles",
            nativeName: {
                crs: {
                    official: "Repiblik Sesel",
                    common: "Sesel"
                },
                eng: {
                    official: "Republic of Seychelles",
                    common: "Seychelles"
                },
                fra: {
                    official: "République des Seychelles",
                    common: "Seychelles"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "48"
            ]
        }
    },
    {
        name: {
            common: "Armenia",
            official: "Republic of Armenia",
            nativeName: {
                hye: {
                    official: "Հայաստանի Հանրապետություն",
                    common: "Հայաստան"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "74"
            ]
        }
    },
    {
        name: {
            common: "Curaçao",
            official: "Country of Curaçao",
            nativeName: {
                eng: {
                    official: "Country of Curaçao",
                    common: "Curaçao"
                },
                nld: {
                    official: "Land Curaçao",
                    common: "Curaçao"
                },
                pap: {
                    official: "Pais Kòrsou",
                    common: "Pais Kòrsou"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "99"
            ]
        }
    },
    {
        name: {
            common: "Guadeloupe",
            official: "Guadeloupe",
            nativeName: {
                fra: {
                    official: "Guadeloupe",
                    common: "Guadeloupe"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "90"
            ]
        }
    },
    {
        name: {
            common: "British Indian Ocean Territory",
            official: "British Indian Ocean Territory",
            nativeName: {
                eng: {
                    official: "British Indian Ocean Territory",
                    common: "British Indian Ocean Territory"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "46"
            ]
        }
    },
    {
        name: {
            common: "Equatorial Guinea",
            official: "Republic of Equatorial Guinea",
            nativeName: {
                fra: {
                    official: "République de la Guinée Équatoriale",
                    common: "Guinée équatoriale"
                },
                por: {
                    official: "República da Guiné Equatorial",
                    common: "Guiné Equatorial"
                },
                spa: {
                    official: "República de Guinea Ecuatorial",
                    common: "Guinea Ecuatorial"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "40"
            ]
        }
    },
    {
        name: {
            common: "Syria",
            official: "Syrian Arab Republic",
            nativeName: {
                ara: {
                    official: "الجمهورية العربية السورية",
                    common: "سوريا"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "63"
            ]
        }
    },
    {
        name: {
            common: "Angola",
            official: "Republic of Angola",
            nativeName: {
                por: {
                    official: "República de Angola",
                    common: "Angola"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "44"
            ]
        }
    },
    {
        name: {
            common: "Tajikistan",
            official: "Republic of Tajikistan",
            nativeName: {
                rus: {
                    official: "Республика Таджикистан",
                    common: "Таджикистан"
                },
                tgk: {
                    official: "Ҷумҳурии Тоҷикистон",
                    common: "Тоҷикистон"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "92"
            ]
        }
    },
    {
        name: {
            common: "United States Minor Outlying Islands",
            official: "United States Minor Outlying Islands",
            nativeName: {
                eng: {
                    official: "United States Minor Outlying Islands",
                    common: "United States Minor Outlying Islands"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "68"
            ]
        }
    },
    {
        name: {
            common: "Åland Islands",
            official: "Åland Islands",
            nativeName: {
                swe: {
                    official: "Landskapet Åland",
                    common: "Åland"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "5818"
            ]
        }
    },
    {
        name: {
            common: "Poland",
            official: "Republic of Poland",
            nativeName: {
                pol: {
                    official: "Rzeczpospolita Polska",
                    common: "Polska"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "8"
            ]
        }
    },
    {
        name: {
            common: "Saudi Arabia",
            official: "Kingdom of Saudi Arabia",
            nativeName: {
                ara: {
                    official: "المملكة العربية السعودية",
                    common: "العربية السعودية"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "66"
            ]
        }
    },
    {
        name: {
            common: "Bosnia and Herzegovina",
            official: "Bosnia and Herzegovina",
            nativeName: {
                bos: {
                    official: "Bosna i Hercegovina",
                    common: "Bosna i Hercegovina"
                },
                hrv: {
                    official: "Bosna i Hercegovina",
                    common: "Bosna i Hercegovina"
                },
                srp: {
                    official: "Босна и Херцеговина",
                    common: "Босна и Херцеговина"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "87"
            ]
        }
    },
    {
        name: {
            common: "United States Virgin Islands",
            official: "Virgin Islands of the United States",
            nativeName: {
                eng: {
                    official: "Virgin Islands of the United States",
                    common: "United States Virgin Islands"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "340"
            ]
        }
    },
    {
        name: {
            common: "New Caledonia",
            official: "New Caledonia",
            nativeName: {
                fra: {
                    official: "Nouvelle-Calédonie",
                    common: "Nouvelle-Calédonie"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "87"
            ]
        }
    },
    {
        name: {
            common: "San Marino",
            official: "Republic of San Marino",
            nativeName: {
                ita: {
                    official: "Repubblica di San Marino",
                    common: "San Marino"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "78"
            ]
        }
    },
    {
        name: {
            common: "Czechia",
            official: "Czech Republic",
            nativeName: {
                ces: {
                    official: "Česká republika",
                    common: "Česko"
                },
                slk: {
                    official: "Česká republika",
                    common: "Česko"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "20"
            ]
        }
    },
    {
        name: {
            common: "Guatemala",
            official: "Republic of Guatemala",
            nativeName: {
                spa: {
                    official: "República de Guatemala",
                    common: "Guatemala"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "02"
            ]
        }
    },
    {
        name: {
            common: "South Korea",
            official: "Republic of Korea",
            nativeName: {
                kor: {
                    official: "대한민국",
                    common: "한국"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "2"
            ]
        }
    },
    {
        name: {
            common: "Estonia",
            official: "Republic of Estonia",
            nativeName: {
                est: {
                    official: "Eesti Vabariik",
                    common: "Eesti"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "72"
            ]
        }
    },
    {
        name: {
            common: "Nepal",
            official: "Federal Democratic Republic of Nepal",
            nativeName: {
                nep: {
                    official: "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
                    common: "नेपाल"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "77"
            ]
        }
    },
    {
        name: {
            common: "Kosovo",
            official: "Republic of Kosovo",
            nativeName: {
                sqi: {
                    official: "Republika e Kosovës",
                    common: "Kosova"
                },
                srp: {
                    official: "Република Косово",
                    common: "Косово"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "83"
            ]
        }
    },
    {
        name: {
            common: "Botswana",
            official: "Republic of Botswana",
            nativeName: {
                eng: {
                    official: "Republic of Botswana",
                    common: "Botswana"
                },
                tsn: {
                    official: "Lefatshe la Botswana",
                    common: "Botswana"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "67"
            ]
        }
    },
    {
        name: {
            common: "Philippines",
            official: "Republic of the Philippines",
            nativeName: {
                eng: {
                    official: "Republic of the Philippines",
                    common: "Philippines"
                },
                fil: {
                    official: "Republic of the Philippines",
                    common: "Pilipinas"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "3"
            ]
        }
    },
    {
        name: {
            common: "Saint Pierre and Miquelon",
            official: "Saint Pierre and Miquelon",
            nativeName: {
                fra: {
                    official: "Collectivité territoriale de Saint-Pierre-et-Miquelon",
                    common: "Saint-Pierre-et-Miquelon"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "08"
            ]
        }
    },
    {
        name: {
            common: "Mongolia",
            official: "Mongolia",
            nativeName: {
                mon: {
                    official: "Монгол улс",
                    common: "Монгол улс"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "76"
            ]
        }
    },
    {
        name: {
            common: "Faroe Islands",
            official: "Faroe Islands",
            nativeName: {
                dan: {
                    official: "Færøerne",
                    common: "Færøerne"
                },
                fao: {
                    official: "Føroyar",
                    common: "Føroyar"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "98"
            ]
        }
    },
    {
        name: {
            common: "Paraguay",
            official: "Republic of Paraguay",
            nativeName: {
                grn: {
                    official: "Tetã Paraguái",
                    common: "Paraguái"
                },
                spa: {
                    official: "República de Paraguay",
                    common: "Paraguay"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "95"
            ]
        }
    },
    {
        name: {
            common: "Svalbard and Jan Mayen",
            official: "Svalbard og Jan Mayen",
            nativeName: {
                nor: {
                    official: "Svalbard og Jan Mayen",
                    common: "Svalbard og Jan Mayen"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "779"
            ]
        }
    },
    {
        name: {
            common: "Tokelau",
            official: "Tokelau",
            nativeName: {
                eng: {
                    official: "Tokelau",
                    common: "Tokelau"
                },
                smo: {
                    official: "Tokelau",
                    common: "Tokelau"
                },
                tkl: {
                    official: "Tokelau",
                    common: "Tokelau"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "90"
            ]
        }
    },
    {
        name: {
            common: "Guernsey",
            official: "Bailiwick of Guernsey",
            nativeName: {
                eng: {
                    official: "Bailiwick of Guernsey",
                    common: "Guernsey"
                },
                fra: {
                    official: "Bailliage de Guernesey",
                    common: "Guernesey"
                },
                nfr: {
                    official: "Dgèrnésiais",
                    common: "Dgèrnésiais"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Caribbean Netherlands",
            official: "Bonaire, Sint Eustatius and Saba",
            nativeName: {
                nld: {
                    official: "Bonaire, Sint Eustatius en Saba",
                    common: "Caribisch Nederland"
                },
                pap: {
                    official: "Boneiru, Sint Eustatius y Saba",
                    common: "Boneiru, Sint Eustatius y Saba"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "99"
            ]
        }
    },
    {
        name: {
            common: "Algeria",
            official: "People's Democratic Republic of Algeria",
            nativeName: {
                ara: {
                    official: "الجمهورية الديمقراطية الشعبية الجزائرية",
                    common: "الجزائر"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "13"
            ]
        }
    },
    {
        name: {
            common: "France",
            official: "French Republic",
            nativeName: {
                fra: {
                    official: "République française",
                    common: "France"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "3"
            ]
        }
    },
    {
        name: {
            common: "Netherlands",
            official: "Kingdom of the Netherlands",
            nativeName: {
                nld: {
                    official: "Koninkrijk der Nederlanden",
                    common: "Nederland"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Greenland",
            official: "Greenland",
            nativeName: {
                kal: {
                    official: "Kalaallit Nunaat",
                    common: "Kalaallit Nunaat"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "99"
            ]
        }
    },
    {
        name: {
            common: "Sint Maarten",
            official: "Sint Maarten",
            nativeName: {
                eng: {
                    official: "Sint Maarten",
                    common: "Sint Maarten"
                },
                fra: {
                    official: "Saint-Martin",
                    common: "Saint-Martin"
                },
                nld: {
                    official: "Sint Maarten",
                    common: "Sint Maarten"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "721"
            ]
        }
    },
    {
        name: {
            common: "Chad",
            official: "Republic of Chad",
            nativeName: {
                ara: {
                    official: "جمهورية تشاد",
                    common: "تشاد‎"
                },
                fra: {
                    official: "République du Tchad",
                    common: "Tchad"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "35"
            ]
        }
    },
    {
        name: {
            common: "Finland",
            official: "Republic of Finland",
            nativeName: {
                fin: {
                    official: "Suomen tasavalta",
                    common: "Suomi"
                },
                swe: {
                    official: "Republiken Finland",
                    common: "Finland"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "58"
            ]
        }
    },
    {
        name: {
            common: "Panama",
            official: "Republic of Panama",
            nativeName: {
                spa: {
                    official: "República de Panamá",
                    common: "Panamá"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "07"
            ]
        }
    },
    {
        name: {
            common: "Palestine",
            official: "State of Palestine",
            nativeName: {
                ara: {
                    official: "دولة فلسطين",
                    common: "فلسطين"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "70"
            ]
        }
    },
    {
        name: {
            common: "Yemen",
            official: "Republic of Yemen",
            nativeName: {
                ara: {
                    official: "الجمهورية اليمنية",
                    common: "اليَمَن"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "67"
            ]
        }
    },
    {
        name: {
            common: "Brazil",
            official: "Federative Republic of Brazil",
            nativeName: {
                por: {
                    official: "República Federativa do Brasil",
                    common: "Brasil"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "5"
            ]
        }
    },
    {
        name: {
            common: "Nigeria",
            official: "Federal Republic of Nigeria",
            nativeName: {
                eng: {
                    official: "Federal Republic of Nigeria",
                    common: "Nigeria"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "34"
            ]
        }
    },
    {
        name: {
            common: "Palau",
            official: "Republic of Palau",
            nativeName: {
                eng: {
                    official: "Republic of Palau",
                    common: "Palau"
                },
                pau: {
                    official: "Beluu er a Belau",
                    common: "Belau"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "80"
            ]
        }
    },
    {
        name: {
            common: "Japan",
            official: "Japan",
            nativeName: {
                jpn: {
                    official: "日本",
                    common: "日本"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Tanzania",
            official: "United Republic of Tanzania",
            nativeName: {
                eng: {
                    official: "United Republic of Tanzania",
                    common: "Tanzania"
                },
                swa: {
                    official: "Jamhuri ya Muungano wa Tanzania",
                    common: "Tanzania"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "55"
            ]
        }
    },
    {
        name: {
            common: "Kazakhstan",
            official: "Republic of Kazakhstan",
            nativeName: {
                kaz: {
                    official: "Қазақстан Республикасы",
                    common: "Қазақстан"
                },
                rus: {
                    official: "Республика Казахстан",
                    common: "Казахстан"
                }
            }
        },
        idd: {
            root: "+7",
            suffixes: [
                "6",
                "7"
            ]
        }
    },
    {
        name: {
            common: "Portugal",
            official: "Portuguese Republic",
            nativeName: {
                por: {
                    official: "República português",
                    common: "Portugal"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "51"
            ]
        }
    },
    {
        name: {
            common: "South Georgia",
            official: "South Georgia and the South Sandwich Islands",
            nativeName: {
                eng: {
                    official: "South Georgia and the South Sandwich Islands",
                    common: "South Georgia"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "00"
            ]
        }
    },
    {
        name: {
            common: "Sri Lanka",
            official: "Democratic Socialist Republic of Sri Lanka",
            nativeName: {
                sin: {
                    official: "ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය",
                    common: "ශ්‍රී ලංකාව"
                },
                tam: {
                    official: "இலங்கை சனநாயக சோசலிசக் குடியரசு",
                    common: "இலங்கை"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Germany",
            official: "Federal Republic of Germany",
            nativeName: {
                deu: {
                    official: "Bundesrepublik Deutschland",
                    common: "Deutschland"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "9"
            ]
        }
    },
    {
        name: {
            common: "Western Sahara",
            official: "Sahrawi Arab Democratic Republic",
            nativeName: {
                ber: {
                    official: "Sahrawi Arab Democratic Republic",
                    common: "Western Sahara"
                },
                mey: {
                    official: "الجمهورية العربية الصحراوية الديمقراطية",
                    common: "الصحراء الغربية"
                },
                spa: {
                    official: "República Árabe Saharaui Democrática",
                    common: "Sahara Occidental"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "125288",
                "125289"
            ]
        }
    },
    {
        name: {
            common: "Colombia",
            official: "Republic of Colombia",
            nativeName: {
                spa: {
                    official: "República de Colombia",
                    common: "Colombia"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "7"
            ]
        }
    },
    {
        name: {
            common: "Peru",
            official: "Republic of Peru",
            nativeName: {
                aym: {
                    official: "Piruw Suyu",
                    common: "Piruw"
                },
                que: {
                    official: "Piruw Ripuwlika",
                    common: "Piruw"
                },
                spa: {
                    official: "República del Perú",
                    common: "Perú"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Senegal",
            official: "Republic of Senegal",
            nativeName: {
                fra: {
                    official: "République du Sénégal",
                    common: "Sénégal"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "21"
            ]
        }
    },
    {
        name: {
            common: "Kyrgyzstan",
            official: "Kyrgyz Republic",
            nativeName: {
                kir: {
                    official: "Кыргыз Республикасы",
                    common: "Кыргызстан"
                },
                rus: {
                    official: "Кыргызская Республика",
                    common: "Киргизия"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "96"
            ]
        }
    },
    {
        name: {
            common: "Afghanistan",
            official: "Islamic Republic of Afghanistan",
            nativeName: {
                prs: {
                    official: "جمهوری اسلامی افغانستان",
                    common: "افغانستان"
                },
                pus: {
                    official: "د افغانستان اسلامي جمهوریت",
                    common: "افغانستان"
                },
                tuk: {
                    official: "Owganystan Yslam Respublikasy",
                    common: "Owganystan"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "3"
            ]
        }
    },
    {
        name: {
            common: "Turkey",
            official: "Republic of Turkey",
            nativeName: {
                tur: {
                    official: "Türkiye Cumhuriyeti",
                    common: "Türkiye"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "0"
            ]
        }
    },
    {
        name: {
            common: "Liberia",
            official: "Republic of Liberia",
            nativeName: {
                eng: {
                    official: "Republic of Liberia",
                    common: "Liberia"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "31"
            ]
        }
    },
    {
        name: {
            common: "South Africa",
            official: "Republic of South Africa",
            nativeName: {
                afr: {
                    official: "Republiek van Suid-Afrika",
                    common: "South Africa"
                },
                eng: {
                    official: "Republic of South Africa",
                    common: "South Africa"
                },
                nbl: {
                    official: "IRiphabliki yeSewula Afrika",
                    common: "Sewula Afrika"
                },
                nso: {
                    official: "Rephaboliki ya Afrika-Borwa ",
                    common: "Afrika-Borwa"
                },
                sot: {
                    official: "Rephaboliki ya Afrika Borwa",
                    common: "Afrika Borwa"
                },
                ssw: {
                    official: "IRiphabhulikhi yeNingizimu Afrika",
                    common: "Ningizimu Afrika"
                },
                tsn: {
                    official: "Rephaboliki ya Aforika Borwa",
                    common: "Aforika Borwa"
                },
                tso: {
                    official: "Riphabliki ra Afrika Dzonga",
                    common: "Afrika Dzonga"
                },
                ven: {
                    official: "Riphabuḽiki ya Afurika Tshipembe",
                    common: "Afurika Tshipembe"
                },
                xho: {
                    official: "IRiphabliki yaseMzantsi Afrika",
                    common: "Mzantsi Afrika"
                },
                zul: {
                    official: "IRiphabliki yaseNingizimu Afrika",
                    common: "Ningizimu Afrika"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "7"
            ]
        }
    },
    {
        name: {
            common: "Chile",
            official: "Republic of Chile",
            nativeName: {
                spa: {
                    official: "República de Chile",
                    common: "Chile"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "6"
            ]
        }
    },
    {
        name: {
            common: "Malta",
            official: "Republic of Malta",
            nativeName: {
                eng: {
                    official: "Republic of Malta",
                    common: "Malta"
                },
                mlt: {
                    official: "Repubblika ta ' Malta",
                    common: "Malta"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "56"
            ]
        }
    },
    {
        name: {
            common: "Kiribati",
            official: "Independent and Sovereign Republic of Kiribati",
            nativeName: {
                eng: {
                    official: "Independent and Sovereign Republic of Kiribati",
                    common: "Kiribati"
                },
                gil: {
                    official: "Ribaberiki Kiribati",
                    common: "Kiribati"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "86"
            ]
        }
    },
    {
        name: {
            common: "Cape Verde",
            official: "Republic of Cabo Verde",
            nativeName: {
                por: {
                    official: "República de Cabo Verde",
                    common: "Cabo Verde"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "38"
            ]
        }
    },
    {
        name: {
            common: "Jersey",
            official: "Bailiwick of Jersey",
            nativeName: {
                eng: {
                    official: "Bailiwick of Jersey",
                    common: "Jersey"
                },
                fra: {
                    official: "Bailliage de Jersey",
                    common: "Jersey"
                },
                nrf: {
                    official: "Bailliage dé Jèrri",
                    common: "Jèrri"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Iceland",
            official: "Iceland",
            nativeName: {
                isl: {
                    official: "Ísland",
                    common: "Ísland"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "54"
            ]
        }
    },
    {
        name: {
            common: "Gambia",
            official: "Republic of the Gambia",
            nativeName: {
                eng: {
                    official: "Republic of the Gambia",
                    common: "Gambia"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "20"
            ]
        }
    },
    {
        name: {
            common: "Vanuatu",
            official: "Republic of Vanuatu",
            nativeName: {
                bis: {
                    official: "Ripablik blong Vanuatu",
                    common: "Vanuatu"
                },
                eng: {
                    official: "Republic of Vanuatu",
                    common: "Vanuatu"
                },
                fra: {
                    official: "République de Vanuatu",
                    common: "Vanuatu"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "78"
            ]
        }
    },
    {
        name: {
            common: "Uruguay",
            official: "Oriental Republic of Uruguay",
            nativeName: {
                spa: {
                    official: "República Oriental del Uruguay",
                    common: "Uruguay"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "98"
            ]
        }
    },
    {
        name: {
            common: "Oman",
            official: "Sultanate of Oman",
            nativeName: {
                ara: {
                    official: "سلطنة عمان",
                    common: "عمان"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "68"
            ]
        }
    },
    {
        name: {
            common: "India",
            official: "Republic of India",
            nativeName: {
                eng: {
                    official: "Republic of India",
                    common: "India"
                },
                hin: {
                    official: "भारत गणराज्य",
                    common: "भारत"
                },
                tam: {
                    official: "இந்தியக் குடியரசு",
                    common: "இந்தியா"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Laos",
            official: "Lao People's Democratic Republic",
            nativeName: {
                lao: {
                    official: "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
                    common: "ສປປລາວ"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "56"
            ]
        }
    },
    {
        name: {
            common: "Aruba",
            official: "Aruba",
            nativeName: {
                nld: {
                    official: "Aruba",
                    common: "Aruba"
                },
                pap: {
                    official: "Aruba",
                    common: "Aruba"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "97"
            ]
        }
    },
    {
        name: {
            common: "Martinique",
            official: "Martinique",
            nativeName: {
                fra: {
                    official: "Martinique",
                    common: "Martinique"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "96"
            ]
        }
    },
    {
        name: {
            common: "Comoros",
            official: "Union of the Comoros",
            nativeName: {
                ara: {
                    official: "الاتحاد القمري",
                    common: "القمر‎"
                },
                fra: {
                    official: "Union des Comores",
                    common: "Comores"
                },
                zdj: {
                    official: "Udzima wa Komori",
                    common: "Komori"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "69"
            ]
        }
    },
    {
        name: {
            common: "Spain",
            official: "Kingdom of Spain",
            nativeName: {
                spa: {
                    official: "Reino de España",
                    common: "España"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Espanola",
            official: "Kingdom of Spain",
            nativeName: {
                spa: {
                    official: "Reino de España",
                    common: "España"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Antigua and Barbuda",
            official: "Antigua and Barbuda",
            nativeName: {
                eng: {
                    official: "Antigua and Barbuda",
                    common: "Antigua and Barbuda"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "268"
            ]
        }
    },
    {
        name: {
            common: "Uzbekistan",
            official: "Republic of Uzbekistan",
            nativeName: {
                rus: {
                    official: "Республика Узбекистан",
                    common: "Узбекистан"
                },
                uzb: {
                    official: "O'zbekiston Respublikasi",
                    common: "O‘zbekiston"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "98"
            ]
        }
    },
    {
        name: {
            common: "Maldives",
            official: "Republic of the Maldives",
            nativeName: {
                div: {
                    official: "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
                    common: "ދިވެހިރާއްޖޭގެ"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "60"
            ]
        }
    },
    {
        name: {
            common: "Gibraltar",
            official: "Gibraltar",
            nativeName: {
                eng: {
                    official: "Gibraltar",
                    common: "Gibraltar"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "50"
            ]
        }
    },
    {
        name: {
            common: "Indonesia",
            official: "Republic of Indonesia",
            nativeName: {
                ind: {
                    official: "Republik Indonesia",
                    common: "Indonesia"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "2"
            ]
        }
    },
    {
        name: {
            common: "Pitcairn Islands",
            official: "Pitcairn Group of Islands",
            nativeName: {
                eng: {
                    official: "Pitcairn Group of Islands",
                    common: "Pitcairn Islands"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Vietnam",
            official: "Socialist Republic of Vietnam",
            nativeName: {
                vie: {
                    official: "Cộng hòa xã hội chủ nghĩa Việt Nam",
                    common: "Việt Nam"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Malaysia",
            official: "Malaysia",
            nativeName: {
                eng: {
                    official: "Malaysia",
                    common: "Malaysia"
                },
                msa: {
                    official: "مليسيا",
                    common: "مليسيا"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "0"
            ]
        }
    },
    {
        name: {
            common: "Cook Islands",
            official: "Cook Islands",
            nativeName: {
                eng: {
                    official: "Cook Islands",
                    common: "Cook Islands"
                },
                rar: {
                    official: "Kūki 'Āirani",
                    common: "Kūki 'Āirani"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "82"
            ]
        }
    },
    {
        name: {
            common: "Eswatini",
            official: "Kingdom of Eswatini",
            nativeName: {
                eng: {
                    official: "Kingdom of Eswatini",
                    common: "Eswatini"
                },
                ssw: {
                    official: "Umbuso weSwatini",
                    common: "eSwatini"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "68"
            ]
        }
    },
    {
        name: {
            common: "Uganda",
            official: "Republic of Uganda",
            nativeName: {
                eng: {
                    official: "Republic of Uganda",
                    common: "Uganda"
                },
                swa: {
                    official: "Republic of Uganda",
                    common: "Uganda"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "56"
            ]
        }
    },
    {
        name: {
            common: "Madagascar",
            official: "Republic of Madagascar",
            nativeName: {
                fra: {
                    official: "République de Madagascar",
                    common: "Madagascar"
                },
                mlg: {
                    official: "Repoblikan'i Madagasikara",
                    common: "Madagasikara"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "61"
            ]
        }
    },
    {
        name: {
            common: "Fiji",
            official: "Republic of Fiji",
            nativeName: {
                eng: {
                    official: "Republic of Fiji",
                    common: "Fiji"
                },
                fij: {
                    official: "Matanitu Tugalala o Viti",
                    common: "Viti"
                },
                hif: {
                    official: "रिपब्लिक ऑफ फीजी",
                    common: "फिजी"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "79"
            ]
        }
    },
    {
        name: {
            common: "Norway",
            official: "Kingdom of Norway",
            nativeName: {
                nno: {
                    official: "Kongeriket Noreg",
                    common: "Noreg"
                },
                nob: {
                    official: "Kongeriket Norge",
                    common: "Norge"
                },
                smi: {
                    official: "Norgga gonagasriika",
                    common: "Norgga"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "7"
            ]
        }
    },
    {
        name: {
            common: "Antarctica",
            official: "Antarctica",
            nativeName: {}
        },
        idd: {
            root: "",
            suffixes: []
        }
    },
    {
        name: {
            common: "Bouvet Island",
            official: "Bouvet Island",
            nativeName: {
                nor: {
                    official: "Bouvetøya",
                    common: "Bouvetøya"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "7"
            ]
        }
    },
    {
        name: {
            common: "Cyprus",
            official: "Republic of Cyprus",
            nativeName: {
                ell: {
                    official: "Δημοκρατία της Κύπρος",
                    common: "Κύπρος"
                },
                tur: {
                    official: "Kıbrıs Cumhuriyeti",
                    common: "Kıbrıs"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "57"
            ]
        }
    },
    {
        name: {
            common: "Taiwan",
            official: "Republic of China (Taiwan)",
            nativeName: {
                zho: {
                    official: "中華民國",
                    common: "台灣"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "86"
            ]
        }
    },
    {
        name: {
            common: "Papua New Guinea",
            official: "Independent State of Papua New Guinea",
            nativeName: {
                eng: {
                    official: "Independent State of Papua New Guinea",
                    common: "Papua New Guinea"
                },
                hmo: {
                    official: "Independen Stet bilong Papua Niugini",
                    common: "Papua Niu Gini"
                },
                tpi: {
                    official: "Independen Stet bilong Papua Niugini",
                    common: "Papua Niugini"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "75"
            ]
        }
    },
    {
        name: {
            common: "Rwanda",
            official: "Republic of Rwanda",
            nativeName: {
                eng: {
                    official: "Republic of Rwanda",
                    common: "Rwanda"
                },
                fra: {
                    official: "République rwandaise",
                    common: "Rwanda"
                },
                kin: {
                    official: "Repubulika y'u Rwanda",
                    common: "Rwanda"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "50"
            ]
        }
    },
    {
        name: {
            common: "DR Congo",
            official: "Democratic Republic of the Congo",
            nativeName: {
                fra: {
                    official: "République démocratique du Congo",
                    common: "RD Congo"
                },
                kon: {
                    official: "Repubilika ya Kongo Demokratiki",
                    common: "Repubilika ya Kongo Demokratiki"
                },
                lin: {
                    official: "Republiki ya Kongó Demokratiki",
                    common: "Republiki ya Kongó Demokratiki"
                },
                lua: {
                    official: "Ditunga dia Kongu wa Mungalaata",
                    common: "Ditunga dia Kongu wa Mungalaata"
                },
                swa: {
                    official: "Jamhuri ya Kidemokrasia ya Kongo",
                    common: "Jamhuri ya Kidemokrasia ya Kongo"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "43"
            ]
        }
    },
    {
        name: {
            common: "Cameroon",
            official: "Republic of Cameroon",
            nativeName: {
                eng: {
                    official: "Republic of Cameroon",
                    common: "Cameroon"
                },
                fra: {
                    official: "République du Cameroun",
                    common: "Cameroun"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "37"
            ]
        }
    },
    {
        name: {
            common: "Serbia",
            official: "Republic of Serbia",
            nativeName: {
                srp: {
                    official: "Република Србија",
                    common: "Србија"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "81"
            ]
        }
    },
    {
        name: {
            common: "Lithuania",
            official: "Republic of Lithuania",
            nativeName: {
                lit: {
                    official: "Lietuvos Respublikos",
                    common: "Lietuva"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "70"
            ]
        }
    },
    {
        name: {
            common: "French Southern and Antarctic Lands",
            official: "Territory of the French Southern and Antarctic Lands",
            nativeName: {
                fra: {
                    official: "Territoire des Terres australes et antarctiques françaises",
                    common: "Terres australes et antarctiques françaises"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "62"
            ]
        }
    },
    {
        name: {
            common: "Cambodia",
            official: "Kingdom of Cambodia",
            nativeName: {
                khm: {
                    official: "ព្រះរាជាណាចក្រកម្ពុជា",
                    common: "Kâmpŭchéa"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "55"
            ]
        }
    },
    {
        name: {
            common: "Saint Helena, Ascension and Tristan da Cunha",
            official: "Saint Helena, Ascension and Tristan da Cunha",
            nativeName: {
                eng: {
                    official: "Saint Helena, Ascension and Tristan da Cunha",
                    common: "Saint Helena, Ascension and Tristan da Cunha"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "90",
                "47"
            ]
        }
    },
    {
        name: {
            common: "United Arab Emirates",
            official: "United Arab Emirates",
            nativeName: {
                ara: {
                    official: "الإمارات العربية المتحدة",
                    common: "دولة الإمارات العربية المتحدة"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "71"
            ]
        }
    },
    {
        name: {
            common: "Bermuda",
            official: "Bermuda",
            nativeName: {
                eng: {
                    official: "Bermuda",
                    common: "Bermuda"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "441"
            ]
        }
    },
    {
        name: {
            common: "Monaco",
            official: "Principality of Monaco",
            nativeName: {
                fra: {
                    official: "Principauté de Monaco",
                    common: "Monaco"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "77"
            ]
        }
    },
    {
        name: {
            common: "Northern Mariana Islands",
            official: "Commonwealth of the Northern Mariana Islands",
            nativeName: {
                cal: {
                    official: "Commonwealth of the Northern Mariana Islands",
                    common: "Northern Mariana Islands"
                },
                cha: {
                    official: "Sankattan Siha Na Islas Mariånas",
                    common: "Na Islas Mariånas"
                },
                eng: {
                    official: "Commonwealth of the Northern Mariana Islands",
                    common: "Northern Mariana Islands"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "670"
            ]
        }
    },
    {
        name: {
            common: "Togo",
            official: "Togolese Republic",
            nativeName: {
                fra: {
                    official: "République togolaise",
                    common: "Togo"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "28"
            ]
        }
    },
    {
        name: {
            common: "Luxembourg",
            official: "Grand Duchy of Luxembourg",
            nativeName: {
                deu: {
                    official: "Großherzogtum Luxemburg",
                    common: "Luxemburg"
                },
                fra: {
                    official: "Grand-Duché de Luxembourg",
                    common: "Luxembourg"
                },
                ltz: {
                    official: "Groussherzogtum Lëtzebuerg",
                    common: "Lëtzebuerg"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "52"
            ]
        }
    },
    {
        name: {
            common: "Mauritius",
            official: "Republic of Mauritius",
            nativeName: {
                eng: {
                    official: "Republic of Mauritius",
                    common: "Mauritius"
                },
                fra: {
                    official: "République de Maurice",
                    common: "Maurice"
                },
                mfe: {
                    official: "Republik Moris",
                    common: "Moris"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "30"
            ]
        }
    },
    {
        name: {
            common: "Argentina",
            official: "Argentine Republic",
            nativeName: {
                grn: {
                    official: "Argentine Republic",
                    common: "Argentina"
                },
                spa: {
                    official: "República Argentina",
                    common: "Argentina"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Grenada",
            official: "Grenada",
            nativeName: {
                eng: {
                    official: "Grenada",
                    common: "Grenada"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "473"
            ]
        }
    },
    {
        name: {
            common: "Nicaragua",
            official: "Republic of Nicaragua",
            nativeName: {
                spa: {
                    official: "República de Nicaragua",
                    common: "Nicaragua"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "05"
            ]
        }
    },
    {
        name: {
            common: "Niue",
            official: "Niue",
            nativeName: {
                eng: {
                    official: "Niue",
                    common: "Niue"
                },
                niu: {
                    official: "Niuē",
                    common: "Niuē"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "83"
            ]
        }
    },
    {
        name: {
            common: "Guyana",
            official: "Co-operative Republic of Guyana",
            nativeName: {
                eng: {
                    official: "Co-operative Republic of Guyana",
                    common: "Guyana"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "92"
            ]
        }
    },
    {
        name: {
            common: "Niger",
            official: "Republic of Niger",
            nativeName: {
                fra: {
                    official: "République du Niger",
                    common: "Niger"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "27"
            ]
        }
    },
    {
        name: {
            common: "Benin",
            official: "Republic of Benin",
            nativeName: {
                fra: {
                    official: "République du Bénin",
                    common: "Bénin"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "29"
            ]
        }
    },
    {
        name: {
            common: "Saint Lucia",
            official: "Saint Lucia",
            nativeName: {
                eng: {
                    official: "Saint Lucia",
                    common: "Saint Lucia"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "758"
            ]
        }
    },
    {
        name: {
            common: "Tuvalu",
            official: "Tuvalu",
            nativeName: {
                eng: {
                    official: "Tuvalu",
                    common: "Tuvalu"
                },
                tvl: {
                    official: "Tuvalu",
                    common: "Tuvalu"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "88"
            ]
        }
    },
    {
        name: {
            common: "Norfolk Island",
            official: "Territory of Norfolk Island",
            nativeName: {
                eng: {
                    official: "Territory of Norfolk Island",
                    common: "Norfolk Island"
                },
                pih: {
                    official: "Teratri of Norf'k Ailen",
                    common: "Norf'k Ailen"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "72"
            ]
        }
    },
    {
        name: {
            common: "Egypt",
            official: "Arab Republic of Egypt",
            nativeName: {
                ara: {
                    official: "جمهورية مصر العربية",
                    common: "مصر"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "0"
            ]
        }
    },
    {
        name: {
            common: "Saint Kitts and Nevis",
            official: "Federation of Saint Christopher and Nevis",
            nativeName: {
                eng: {
                    official: "Federation of Saint Christopher and Nevis",
                    common: "Saint Kitts and Nevis"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "869"
            ]
        }
    },
    {
        name: {
            common: "Lesotho",
            official: "Kingdom of Lesotho",
            nativeName: {
                eng: {
                    official: "Kingdom of Lesotho",
                    common: "Lesotho"
                },
                sot: {
                    official: "Kingdom of Lesotho",
                    common: "Lesotho"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "66"
            ]
        }
    },
    {
        name: {
            common: "Tonga",
            official: "Kingdom of Tonga",
            nativeName: {
                eng: {
                    official: "Kingdom of Tonga",
                    common: "Tonga"
                },
                ton: {
                    official: "Kingdom of Tonga",
                    common: "Tonga"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "76"
            ]
        }
    },
    {
        name: {
            common: "Georgia",
            official: "Georgia",
            nativeName: {
                kat: {
                    official: "საქართველო",
                    common: "საქართველო"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "95"
            ]
        }
    },
    {
        name: {
            common: "Ethiopia",
            official: "Federal Democratic Republic of Ethiopia",
            nativeName: {
                amh: {
                    official: "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
                    common: "ኢትዮጵያ"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "51"
            ]
        }
    },
    {
        name: {
            common: "Dominican Republic",
            official: "Dominican Republic",
            nativeName: {
                spa: {
                    official: "República Dominicana",
                    common: "República Dominicana"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "809",
                "829",
                "849"
            ]
        }
    },
    {
        name: {
            common: "Saint Vincent and the Grenadines",
            official: "Saint Vincent and the Grenadines",
            nativeName: {
                eng: {
                    official: "Saint Vincent and the Grenadines",
                    common: "Saint Vincent and the Grenadines"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "784"
            ]
        }
    },
    {
        name: {
            common: "Belize",
            official: "Belize",
            nativeName: {
                bjz: {
                    official: "Belize",
                    common: "Belize"
                },
                eng: {
                    official: "Belize",
                    common: "Belize"
                },
                spa: {
                    official: "Belice",
                    common: "Belice"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "01"
            ]
        }
    },
    {
        name: {
            common: "Isle of Man",
            official: "Isle of Man",
            nativeName: {
                eng: {
                    official: "Isle of Man",
                    common: "Isle of Man"
                },
                glv: {
                    official: "Ellan Vannin or Mannin",
                    common: "Mannin"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Morocco",
            official: "Kingdom of Morocco",
            nativeName: {
                ara: {
                    official: "المملكة المغربية",
                    common: "المغرب"
                },
                ber: {
                    official: "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
                    common: "ⵍⵎⴰⵖⵔⵉⴱ"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "12"
            ]
        }
    },
    {
        name: {
            common: "Haiti",
            official: "Republic of Haiti",
            nativeName: {
                fra: {
                    official: "République d'Haïti",
                    common: "Haïti"
                },
                hat: {
                    official: "Repiblik Ayiti",
                    common: "Ayiti"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "09"
            ]
        }
    },
    {
        name: {
            common: "Mayotte",
            official: "Department of Mayotte",
            nativeName: {
                fra: {
                    official: "Département de Mayotte",
                    common: "Mayotte"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "62"
            ]
        }
    },
    {
        name: {
            common: "Burkina Faso",
            official: "Burkina Faso",
            nativeName: {
                fra: {
                    official: "République du Burkina",
                    common: "Burkina Faso"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "26"
            ]
        }
    },
    {
        name: {
            common: "Bangladesh",
            official: "People's Republic of Bangladesh",
            nativeName: {
                ben: {
                    official: "বাংলাদেশ গণপ্রজাতন্ত্রী",
                    common: "বাংলাদেশ"
                }
            }
        },
        idd: {
            root: "+8",
            suffixes: [
                "80"
            ]
        }
    },
    {
        name: {
            common: "Kuwait",
            official: "State of Kuwait",
            nativeName: {
                ara: {
                    official: "دولة الكويت",
                    common: "الكويت"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "65"
            ]
        }
    },
    {
        name: {
            common: "Réunion",
            official: "Réunion Island",
            nativeName: {
                fra: {
                    official: "Ile de la Réunion",
                    common: "La Réunion"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "62"
            ]
        }
    },
    {
        name: {
            common: "Jamaica",
            official: "Jamaica",
            nativeName: {
                eng: {
                    official: "Jamaica",
                    common: "Jamaica"
                },
                jam: {
                    official: "Jamaica",
                    common: "Jamaica"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "876"
            ]
        }
    },
    {
        name: {
            common: "Romania",
            official: "Romania",
            nativeName: {
                ron: {
                    official: "România",
                    common: "România"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "0"
            ]
        }
    },
    {
        name: {
            common: "São Tomé and Príncipe",
            official: "Democratic Republic of São Tomé and Príncipe",
            nativeName: {
                por: {
                    official: "República Democrática do São Tomé e Príncipe",
                    common: "São Tomé e Príncipe"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "39"
            ]
        }
    },
    {
        name: {
            common: "Bahamas",
            official: "Commonwealth of the Bahamas",
            nativeName: {
                eng: {
                    official: "Commonwealth of the Bahamas",
                    common: "Bahamas"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "242"
            ]
        }
    },
    {
        name: {
            common: "Mexico",
            official: "United Mexican States",
            nativeName: {
                spa: {
                    official: "Estados Unidos Mexicanos",
                    common: "México"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "2"
            ]
        }
    },
    {
        name: {
            common: "Saint Martin",
            official: "Saint Martin",
            nativeName: {
                fra: {
                    official: "Saint-Martin",
                    common: "Saint-Martin"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "90"
            ]
        }
    },
    {
        name: {
            common: "El Salvador",
            official: "Republic of El Salvador",
            nativeName: {
                spa: {
                    official: "República de El Salvador",
                    common: "El Salvador"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "03"
            ]
        }
    },
    {
        name: {
            common: "Saint Barthélemy",
            official: "Collectivity of Saint Barthélemy",
            nativeName: {
                fra: {
                    official: "Collectivité de Saint-Barthélemy",
                    common: "Saint-Barthélemy"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "90"
            ]
        }
    },
    {
        name: {
            common: "Micronesia",
            official: "Federated States of Micronesia",
            nativeName: {
                eng: {
                    official: "Federated States of Micronesia",
                    common: "Micronesia"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "91"
            ]
        }
    },
    {
        name: {
            common: "Turkmenistan",
            official: "Turkmenistan",
            nativeName: {
                rus: {
                    official: "Туркменистан",
                    common: "Туркмения"
                },
                tuk: {
                    official: "Türkmenistan",
                    common: "Türkmenistan"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "93"
            ]
        }
    },
    {
        name: {
            common: "Anguilla",
            official: "Anguilla",
            nativeName: {
                eng: {
                    official: "Anguilla",
                    common: "Anguilla"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "264"
            ]
        }
    },
    {
        name: {
            common: "Central African Republic",
            official: "Central African Republic",
            nativeName: {
                fra: {
                    official: "République centrafricaine",
                    common: "République centrafricaine"
                },
                sag: {
                    official: "Ködörösêse tî Bêafrîka",
                    common: "Bêafrîka"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "36"
            ]
        }
    },
    {
        name: {
            common: "Suriname",
            official: "Republic of Suriname",
            nativeName: {
                nld: {
                    official: "Republiek Suriname",
                    common: "Suriname"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "97"
            ]
        }
    },
    {
        name: {
            common: "Belgium",
            official: "Kingdom of Belgium",
            nativeName: {
                deu: {
                    official: "Königreich Belgien",
                    common: "Belgien"
                },
                fra: {
                    official: "Royaume de Belgique",
                    common: "Belgique"
                },
                nld: {
                    official: "Koninkrijk België",
                    common: "België"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "2"
            ]
        }
    },
    {
        name: {
            common: "Sweden",
            official: "Kingdom of Sweden",
            nativeName: {
                swe: {
                    official: "Konungariket Sverige",
                    common: "Sverige"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "6"
            ]
        }
    },
    {
        name: {
            common: "Bolivia",
            official: "Plurinational State of Bolivia",
            nativeName: {
                aym: {
                    official: "Wuliwya Suyu",
                    common: "Wuliwya"
                },
                grn: {
                    official: "Tetã Volívia",
                    common: "Volívia"
                },
                que: {
                    official: "Buliwya Mamallaqta",
                    common: "Buliwya"
                },
                spa: {
                    official: "Estado Plurinacional de Bolivia",
                    common: "Bolivia"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "91"
            ]
        }
    },
    {
        name: {
            common: "Montenegro",
            official: "Montenegro",
            nativeName: {
                cnr: {
                    official: "Црна Гора",
                    common: "Црна Гора"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "82"
            ]
        }
    },
    {
        name: {
            common: "Mozambique",
            official: "Republic of Mozambique",
            nativeName: {
                por: {
                    official: "República de Moçambique",
                    common: "Moçambique"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "58"
            ]
        }
    },
    {
        name: {
            common: "Latvia",
            official: "Republic of Latvia",
            nativeName: {
                lav: {
                    official: "Latvijas Republikas",
                    common: "Latvija"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "71"
            ]
        }
    },
    {
        name: {
            common: "Malawi",
            official: "Republic of Malawi",
            nativeName: {
                eng: {
                    official: "Republic of Malawi",
                    common: "Malawi"
                },
                nya: {
                    official: "Chalo cha Malawi, Dziko la Malaŵi",
                    common: "Malaŵi"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "65"
            ]
        }
    },
    {
        name: {
            common: "Vatican City",
            official: "Vatican City State",
            nativeName: {
                ita: {
                    official: "Stato della Città del Vaticano",
                    common: "Vaticano"
                },
                lat: {
                    official: "Status Civitatis Vaticanæ",
                    common: "Vaticanæ"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "906698",
                "79"
            ]
        }
    },
    {
        name: {
            common: "Montserrat",
            official: "Montserrat",
            nativeName: {
                eng: {
                    official: "Montserrat",
                    common: "Montserrat"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "664"
            ]
        }
    },
    {
        name: {
            common: "Austria",
            official: "Republic of Austria",
            nativeName: {
                bar: {
                    official: "Republik Österreich",
                    common: "Österreich"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "3"
            ]
        }
    },
    {
        name: {
            common: "Albania",
            official: "Republic of Albania",
            nativeName: {
                sqi: {
                    official: "Republika e Shqipërisë",
                    common: "Shqipëria"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "55"
            ]
        }
    },
    {
        name: {
            common: "British Virgin Islands",
            official: "Virgin Islands",
            nativeName: {
                eng: {
                    official: "Virgin Islands",
                    common: "British Virgin Islands"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "284"
            ]
        }
    },
    {
        name: {
            common: "Zambia",
            official: "Republic of Zambia",
            nativeName: {
                eng: {
                    official: "Republic of Zambia",
                    common: "Zambia"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "60"
            ]
        }
    },
    {
        name: {
            common: "French Guiana",
            official: "Guiana",
            nativeName: {
                fra: {
                    official: "Guyane",
                    common: "Guyane française"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "94"
            ]
        }
    },
    {
        name: {
            common: "Liechtenstein",
            official: "Principality of Liechtenstein",
            nativeName: {
                deu: {
                    official: "Fürstentum Liechtenstein",
                    common: "Liechtenstein"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "23"
            ]
        }
    },
    {
        name: {
            common: "Qatar",
            official: "State of Qatar",
            nativeName: {
                ara: {
                    official: "دولة قطر",
                    common: "قطر"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "74"
            ]
        }
    },
    {
        name: {
            common: "Solomon Islands",
            official: "Solomon Islands",
            nativeName: {
                eng: {
                    official: "Solomon Islands",
                    common: "Solomon Islands"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "77"
            ]
        }
    },
    {
        name: {
            common: "Nauru",
            official: "Republic of Nauru",
            nativeName: {
                eng: {
                    official: "Republic of Nauru",
                    common: "Nauru"
                },
                nau: {
                    official: "Republic of Nauru",
                    common: "Nauru"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "74"
            ]
        }
    },
    {
        name: {
            common: "Greece",
            official: "Hellenic Republic",
            nativeName: {
                ell: {
                    official: "Ελληνική Δημοκρατία",
                    common: "Ελλάδα"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "0"
            ]
        }
    },
    {
        name: {
            common: "Libya",
            official: "State of Libya",
            nativeName: {
                ara: {
                    official: "الدولة ليبيا",
                    common: "‏ليبيا"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "18"
            ]
        }
    },
    {
        name: {
            common: "Guinea-Bissau",
            official: "Republic of Guinea-Bissau",
            nativeName: {
                por: {
                    official: "República da Guiné-Bissau",
                    common: "Guiné-Bissau"
                },
                pov: {
                    official: "República da Guiné-Bissau",
                    common: "Guiné-Bissau"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "45"
            ]
        }
    },
    {
        name: {
            common: "Barbados",
            official: "Barbados",
            nativeName: {
                eng: {
                    official: "Barbados",
                    common: "Barbados"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "246"
            ]
        }
    },
    {
        name: {
            common: "Honduras",
            official: "Republic of Honduras",
            nativeName: {
                spa: {
                    official: "República de Honduras",
                    common: "Honduras"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "04"
            ]
        }
    },
    {
        name: {
            common: "Cayman Islands",
            official: "Cayman Islands",
            nativeName: {
                eng: {
                    official: "Cayman Islands",
                    common: "Cayman Islands"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "345"
            ]
        }
    },
    {
        name: {
            common: "Italy",
            official: "Italian Republic",
            nativeName: {
                ita: {
                    official: "Repubblica italiana",
                    common: "Italia"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "9"
            ]
        }
    },
    {
        name: {
            common: "Wallis and Futuna",
            official: "Territory of the Wallis and Futuna Islands",
            nativeName: {
                fra: {
                    official: "Territoire des îles Wallis et Futuna",
                    common: "Wallis et Futuna"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "81"
            ]
        }
    },
    {
        name: {
            common: "Andorra",
            official: "Principality of Andorra",
            nativeName: {
                cat: {
                    official: "Principat d'Andorra",
                    common: "Andorra"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "76"
            ]
        }
    },
    {
        name: {
            common: "Ecuador",
            official: "Republic of Ecuador",
            nativeName: {
                spa: {
                    official: "República del Ecuador",
                    common: "Ecuador"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "93"
            ]
        }
    },
    {
        name: {
            common: "Kenya",
            official: "Republic of Kenya",
            nativeName: {
                eng: {
                    official: "Republic of Kenya",
                    common: "Kenya"
                },
                swa: {
                    official: "Republic of Kenya",
                    common: "Kenya"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "54"
            ]
        }
    },
    {
        name: {
            common: "Bahrain",
            official: "Kingdom of Bahrain",
            nativeName: {
                ara: {
                    official: "مملكة البحرين",
                    common: "‏البحرين"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "73"
            ]
        }
    },
    {
        name: {
            common: "United Kingdom",
            official: "United Kingdom of Great Britain and Northern Ireland",
            nativeName: {
                eng: {
                    official: "United Kingdom of Great Britain and Northern Ireland",
                    common: "United Kingdom"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Guinea",
            official: "Republic of Guinea",
            nativeName: {
                fra: {
                    official: "République de Guinée",
                    common: "Guinée"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "24"
            ]
        }
    },
    {
        name: {
            common: "Heard Island and McDonald Islands",
            official: "Heard Island and McDonald Islands",
            nativeName: {
                eng: {
                    official: "Heard Island and McDonald Islands",
                    common: "Heard Island and McDonald Islands"
                }
            }
        },
        idd: {
            root: "",
            suffixes: []
        }
    },
    {
        name: {
            common: "Brunei",
            official: "Nation of Brunei, Abode of Peace",
            nativeName: {
                msa: {
                    official: "Nation of Brunei, Abode Damai",
                    common: "Negara Brunei Darussalam"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "73"
            ]
        }
    },
    {
        name: {
            common: "Guam",
            official: "Guam",
            nativeName: {
                cha: {
                    official: "Guåhån",
                    common: "Guåhån"
                },
                eng: {
                    official: "Guam",
                    common: "Guam"
                },
                spa: {
                    official: "Guam",
                    common: "Guam"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "671"
            ]
        }
    },
    {
        name: {
            common: "Australia",
            official: "Commonwealth of Australia",
            nativeName: {
                eng: {
                    official: "Commonwealth of Australia",
                    common: "Australia"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Slovenia",
            official: "Republic of Slovenia",
            nativeName: {
                slv: {
                    official: "Republika Slovenija",
                    common: "Slovenija"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "86"
            ]
        }
    },
    {
        name: {
            common: "Thailand",
            official: "Kingdom of Thailand",
            nativeName: {
                tha: {
                    official: "ราชอาณาจักรไทย",
                    common: "ประเทศไทย"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "6"
            ]
        }
    },
    {
        name: {
            common: "New Zealand",
            official: "New Zealand",
            nativeName: {
                eng: {
                    official: "New Zealand",
                    common: "New Zealand"
                },
                mri: {
                    official: "Aotearoa",
                    common: "Aotearoa"
                },
                nzs: {
                    official: "New Zealand",
                    common: "New Zealand"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "4"
            ]
        }
    },
    {
        name: {
            common: "Tunisia",
            official: "Tunisian Republic",
            nativeName: {
                ara: {
                    official: "الجمهورية التونسية",
                    common: "تونس"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "16"
            ]
        }
    },
    {
        name: {
            common: "Marshall Islands",
            official: "Republic of the Marshall Islands",
            nativeName: {
                eng: {
                    official: "Republic of the Marshall Islands",
                    common: "Marshall Islands"
                },
                mah: {
                    official: "Republic of the Marshall Islands",
                    common: "M̧ajeļ"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "92"
            ]
        }
    },
    {
        name: {
            common: "Sierra Leone",
            official: "Republic of Sierra Leone",
            nativeName: {
                eng: {
                    official: "Republic of Sierra Leone",
                    common: "Sierra Leone"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "32"
            ]
        }
    },
    {
        name: {
            common: "Bhutan",
            official: "Kingdom of Bhutan",
            nativeName: {
                dzo: {
                    official: "འབྲུག་རྒྱལ་ཁབ་",
                    common: "འབྲུག་ཡུལ་"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "75"
            ]
        }
    },
    {
        name: {
            common: "United States",
            official: "United States of America",
            nativeName: {
                eng: {
                    official: "United States of America",
                    common: "United States"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "201",
                "202",
                "203",
                "205",
                "206",
                "207",
                "208",
                "209",
                "210",
                "212",
                "213",
                "214",
                "215",
                "216",
                "217",
                "218",
                "219",
                "220",
                "224",
                "225",
                "227",
                "228",
                "229",
                "231",
                "234",
                "239",
                "240",
                "248",
                "251",
                "252",
                "253",
                "254",
                "256",
                "260",
                "262",
                "267",
                "269",
                "270",
                "272",
                "274",
                "276",
                "281",
                "283",
                "301",
                "302",
                "303",
                "304",
                "305",
                "307",
                "308",
                "309",
                "310",
                "312",
                "313",
                "314",
                "315",
                "316",
                "317",
                "318",
                "319",
                "320",
                "321",
                "323",
                "325",
                "327",
                "330",
                "331",
                "334",
                "336",
                "337",
                "339",
                "346",
                "347",
                "351",
                "352",
                "360",
                "361",
                "364",
                "380",
                "385",
                "386",
                "401",
                "402",
                "404",
                "405",
                "406",
                "407",
                "408",
                "409",
                "410",
                "412",
                "413",
                "414",
                "415",
                "417",
                "419",
                "423",
                "424",
                "425",
                "430",
                "432",
                "434",
                "435",
                "440",
                "442",
                "443",
                "447",
                "458",
                "463",
                "464",
                "469",
                "470",
                "475",
                "478",
                "479",
                "480",
                "484",
                "501",
                "502",
                "503",
                "504",
                "505",
                "507",
                "508",
                "509",
                "510",
                "512",
                "513",
                "515",
                "516",
                "517",
                "518",
                "520",
                "530",
                "531",
                "534",
                "539",
                "540",
                "541",
                "551",
                "559",
                "561",
                "562",
                "563",
                "564",
                "567",
                "570",
                "571",
                "573",
                "574",
                "575",
                "580",
                "585",
                "586",
                "601",
                "602",
                "603",
                "605",
                "606",
                "607",
                "608",
                "609",
                "610",
                "612",
                "614",
                "615",
                "616",
                "617",
                "618",
                "619",
                "620",
                "623",
                "626",
                "628",
                "629",
                "630",
                "631",
                "636",
                "641",
                "646",
                "650",
                "651",
                "657",
                "660",
                "661",
                "662",
                "667",
                "669",
                "678",
                "681",
                "682",
                "701",
                "702",
                "703",
                "704",
                "706",
                "707",
                "708",
                "712",
                "713",
                "714",
                "715",
                "716",
                "717",
                "718",
                "719",
                "720",
                "724",
                "725",
                "727",
                "730",
                "731",
                "732",
                "734",
                "737",
                "740",
                "743",
                "747",
                "754",
                "757",
                "760",
                "762",
                "763",
                "765",
                "769",
                "770",
                "772",
                "773",
                "774",
                "775",
                "779",
                "781",
                "785",
                "786",
                "801",
                "802",
                "803",
                "804",
                "805",
                "806",
                "808",
                "810",
                "812",
                "813",
                "814",
                "815",
                "816",
                "817",
                "818",
                "828",
                "830",
                "831",
                "832",
                "843",
                "845",
                "847",
                "848",
                "850",
                "854",
                "856",
                "857",
                "858",
                "859",
                "860",
                "862",
                "863",
                "864",
                "865",
                "870",
                "872",
                "878",
                "901",
                "903",
                "904",
                "906",
                "907",
                "908",
                "909",
                "910",
                "912",
                "913",
                "914",
                "915",
                "916",
                "917",
                "918",
                "919",
                "920",
                "925",
                "928",
                "929",
                "930",
                "931",
                "934",
                "936",
                "937",
                "938",
                "940",
                "941",
                "947",
                "949",
                "951",
                "952",
                "954",
                "956",
                "959",
                "970",
                "971",
                "972",
                "973",
                "975",
                "978",
                "979",
                "980",
                "984",
                "985",
                "989"
            ]
        }
    },
    {
        name: {
            common: "Switzerland",
            official: "Swiss Confederation",
            nativeName: {
                fra: {
                    official: "Confédération suisse",
                    common: "Suisse"
                },
                gsw: {
                    official: "Schweizerische Eidgenossenschaft",
                    common: "Schweiz"
                },
                ita: {
                    official: "Confederazione Svizzera",
                    common: "Svizzera"
                },
                roh: {
                    official: "Confederaziun svizra",
                    common: "Svizra"
                }
            }
        },
        idd: {
            root: "+4",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Falkland Islands",
            official: "Falkland Islands",
            nativeName: {
                eng: {
                    official: "Falkland Islands",
                    common: "Falkland Islands"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "00"
            ]
        }
    },
    {
        name: {
            common: "Cocos (Keeling) Islands",
            official: "Territory of the Cocos (Keeling) Islands",
            nativeName: {
                eng: {
                    official: "Territory of the Cocos (Keeling) Islands",
                    common: "Cocos (Keeling) Islands"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "1"
            ]
        }
    },
    {
        name: {
            common: "Gabon",
            official: "Gabonese Republic",
            nativeName: {
                fra: {
                    official: "République gabonaise",
                    common: "Gabon"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "41"
            ]
        }
    },
    {
        name: {
            common: "Dominica",
            official: "Commonwealth of Dominica",
            nativeName: {
                eng: {
                    official: "Commonwealth of Dominica",
                    common: "Dominica"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "767"
            ]
        }
    },
    {
        name: {
            common: "Canada",
            official: "Canada",
            nativeName: {
                eng: {
                    official: "Canada",
                    common: "Canada"
                },
                fra: {
                    official: "Canada",
                    common: "Canada"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                ""
            ]
        }
    },
    {
        name: {
            common: "Trinidad and Tobago",
            official: "Republic of Trinidad and Tobago",
            nativeName: {
                eng: {
                    official: "Republic of Trinidad and Tobago",
                    common: "Trinidad and Tobago"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "868"
            ]
        }
    },
    {
        name: {
            common: "Puerto Rico",
            official: "Commonwealth of Puerto Rico",
            nativeName: {
                eng: {
                    official: "Commonwealth of Puerto Rico",
                    common: "Puerto Rico"
                },
                spa: {
                    official: "Estado Libre Asociado de Puerto Rico",
                    common: "Puerto Rico"
                }
            }
        },
        idd: {
            root: "+1",
            suffixes: [
                "787",
                "939"
            ]
        }
    },
    {
        name: {
            common: "Singapore",
            official: "Republic of Singapore",
            nativeName: {
                eng: {
                    official: "Republic of Singapore",
                    common: "Singapore"
                },
                zho: {
                    official: "新加坡共和国",
                    common: "新加坡"
                },
                msa: {
                    official: "Republik Singapura",
                    common: "Singapura"
                },
                tam: {
                    official: "சிங்கப்பூர் குடியரசு",
                    common: "சிங்கப்பூர்"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "5"
            ]
        }
    },
    {
        name: {
            common: "Hungary",
            official: "Hungary",
            nativeName: {
                hun: {
                    official: "Magyarország",
                    common: "Magyarország"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "6"
            ]
        }
    },
    {
        name: {
            common: "Costa Rica",
            official: "Republic of Costa Rica",
            nativeName: {
                spa: {
                    official: "República de Costa Rica",
                    common: "Costa Rica"
                }
            }
        },
        idd: {
            root: "+5",
            suffixes: [
                "06"
            ]
        }
    },
    {
        name: {
            common: "Israel",
            official: "State of Israel",
            nativeName: {
                ara: {
                    official: "دولة إسرائيل",
                    common: "إسرائيل"
                },
                heb: {
                    official: "מדינת ישראל",
                    common: "ישראל"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "72"
            ]
        }
    },
    {
        name: {
            common: "Bulgaria",
            official: "Republic of Bulgaria",
            nativeName: {
                bul: {
                    official: "Република България",
                    common: "България"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "59"
            ]
        }
    },
    {
        name: {
            common: "Azerbaijan",
            official: "Republic of Azerbaijan",
            nativeName: {
                aze: {
                    official: "Azərbaycan Respublikası",
                    common: "Azərbaycan"
                }
            }
        },
        idd: {
            root: "+9",
            suffixes: [
                "94"
            ]
        }
    },
    {
        name: {
            common: "Timor-Leste",
            official: "Democratic Republic of Timor-Leste",
            nativeName: {
                por: {
                    official: "República Democrática de Timor-Leste",
                    common: "Timor-Leste"
                },
                tet: {
                    official: "Repúblika Demokrátika Timór-Leste",
                    common: "Timór-Leste"
                }
            }
        },
        idd: {
            root: "+6",
            suffixes: [
                "70"
            ]
        }
    },
    {
        name: {
            common: "Croatia",
            official: "Republic of Croatia",
            nativeName: {
                hrv: {
                    official: "Republika Hrvatska",
                    common: "Hrvatska"
                }
            }
        },
        idd: {
            root: "+3",
            suffixes: [
                "85"
            ]
        }
    },
    {
        name: {
            common: "Ivory Coast",
            official: "Republic of Côte d'Ivoire",
            nativeName: {
                fra: {
                    official: "République de Côte d'Ivoire",
                    common: "Côte d'Ivoire"
                }
            }
        },
        idd: {
            root: "+2",
            suffixes: [
                "25"
            ]
        }
    }
];