import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { HeaderComponent } from './header/header.component';
import { TranslatePipe } from '../directives/translate.directive';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FormsModule } from '@angular/forms';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { FooterComponent } from './footer/footer.component';
import { BottomNavigationComponent } from '../bottom-navigation/bottom-navigation.component';
import { CardListComponent } from './card-list/card-list.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        CardListComponent,
        TranslatePipe,
        BottomNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        DragScrollModule,
        MaterialModule,
        FormsModule,
        NgxShimmerLoadingModule
    ],
    exports: [
        TranslateModule,
        HeaderComponent,
        CardListComponent,
        FooterComponent,
        BottomNavigationComponent,
        TranslatePipe,
        FormsModule
    ]
})
export class LayoutModule { }