import { ErrorHandlerService } from './error.handler.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Image } from '../../models/image.model';

/**
 * Axia Image service
 */
@Injectable()
export class ImageService {

    constructor(
        private readonly http: HttpClient,
        private readonly errorHandler: ErrorHandlerService
    )
    { }

    /**
     * Upload an image
     * @param image Image to be uploaded to s3
     */
    public uploadImage(image: Image): Observable<any> {
        const header: HttpHeaders = new HttpHeaders({ });
        return this.http.post<any>(RestEndpoint.files, image, {headers: header})
            .pipe(
                catchError((err) => { return this.errorHandler.validate(err); })
            );
    }
}
