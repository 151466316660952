import JSEncrypt from 'jsencrypt';
import * as CryptoJS from 'crypto-js';  
import { v4 as uuid4 } from 'uuid';

// tslint: disable-next-line: max-classes-per-file
class EncryptedBase
{
    private static PRIVATE_KEY: string;

    constructor(rsaKey: string)
    {
        EncryptedBase.PRIVATE_KEY = rsaKey;
    }

    protected encrypt(msg: string): any
    {
        const encryptor = new JSEncrypt({});

        encryptor.setPublicKey(EncryptedBase.PRIVATE_KEY);

        return encryptor.encrypt(msg);
    }
}

export class LoginEncrypted extends EncryptedBase
{
    public email: string;
    public password: string;
    public username: string;
    public secretcode: string;
    public code: string;
    public configureForInternal?: boolean;

    constructor(email: string, password: string, username: string, code: string, rsaKey: string, configureForInternal?: boolean)
    {
        super(rsaKey);
        this.email = email;
        this.username = username;
        this.password = this.encrypt(password);
        this.code = code;
        this.secretcode = code;
        this.configureForInternal = configureForInternal;
    }
}

export class TransactionPasswordEncrypted extends EncryptedBase
{
    public password: string;

    constructor(password: string, rsaKey: string)
    {
        super(rsaKey);
        this.password = this.encrypt(password);
    }
}

export class AesKeyEncrypted extends EncryptedBase
{
    public aesKey: string;

    constructor(aesKey: string, rsaKey: string)
    {
        super(rsaKey);
        this.aesKey = this.encrypt(aesKey);;
    }
}

export interface IPayloadEncrypted 
{
    /*
    * Key randomically generated and encripted by RSA
    */
    param0: string;

    /*
    * Payload encripted by AES using the decripted key above
    */
    param1: string;
}

/*
* This class receives a request payload and encrypts it using AES e RSA systems
*/
export class PayloadEncrypted 
{
    public payload: IPayloadEncrypted;

    constructor(request: any, rsaKey: string)
    {
        const aesKey: string = uuid4();

        //Encripts payload using AES + key above
        const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(request), aesKey).toString();

        //Encripts key using RSA
        const encryptedKey : AesKeyEncrypted = new AesKeyEncrypted(aesKey, rsaKey);
   
        this.payload = {
            param0: encryptedKey.aesKey,
            param1: encryptedMessage
        }
    }
}