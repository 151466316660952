import BigNumber from 'bignumber.js';
import { Injectable } from '@angular/core';
import { ShoppingCartItem } from '../../../models/shopping-cart-item.model';
import { ProductsBackend } from '../../../models/products.model';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartItemAdapter
{
    constructor(
    )
    {}

    public adapt(item: any): ShoppingCartItem
    {
        return new ShoppingCartItem(
            item.id,
            new ProductsBackend(item.item),
            new BigNumber(item.quantity)
        );
    }
}
