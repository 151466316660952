<div class="bottom-navigation" *ngIf="!loading && showBottom">
    <div class="bottom-navigation-background justify-center">
        <div class="buttons-container">
            <button class="centralized-button" *ngIf="walletEnabled" mat-menu-item routerLink="/home">
                <div class="container-mat-icon" [ngClass]="{'selected': platform.WALLET === currentPlatform}">
                    <mat-icon
                        class="medias-icon" 
                        [matTooltip]="'header.menuUser.digitalBanking' | translate" 
                        [matTooltipClass]="'tooltip-info'">account_balance_wallet</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.WALLET === currentPlatform}" *ngIf="quantityButtons <= 4">{{ 'button.wallet' | translate }}</span>
            </button>
            <button class="centralized-button" mat-menu-item routerLink="/digital-banking" *ngIf="digitalBankingEnabled">
                <div class="container-mat-icon" [ngClass]="{'selected': platform.BANKING === currentPlatform}">
                    <mat-icon
                        class="medias-icon"
                        [matTooltip]="'header.menuUser.digitalBanking' | translate" 
                        [matTooltipClass]="'tooltip-info'">payments</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.BANKING === currentPlatform}" *ngIf="quantityButtons <= 4">{{'button.banking' | translate }}</span>
            </button>
            <button class="centralized-button" mat-menu-item routerLink="/exchange" *ngIf="exchangeEnabled">
                <div class="container-mat-icon active" [ngClass]="{'selected': platform.EXCHANGE === currentPlatform}">
                    <mat-icon
                        class="medias-icon"
                        [matTooltip]="'header.menuUser.exchange' | translate" 
                        [matTooltipClass]="'tooltip-info'">swap_horiz</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.EXCHANGE === currentPlatform}" *ngIf="quantityButtons <= 4">{{ 'button.exchange' | translate }}</span>
            </button>
            <button class="centralized-button" mat-menu-item routerLink="/marketplace" *ngIf="marketplaceEnabled">
                <div class="container-mat-icon" [ngClass]="{'selected': platform.MARKETPLACE === currentPlatform}">
                    <mat-icon
                        class="medias-icon" 
                        [matTooltip]="'header.menuUser.marketplace' | translate" 
                        [matTooltipClass]="'tooltip-info'">store</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.MARKETPLACE === currentPlatform}" *ngIf="quantityButtons <= 4">{{ 'button.marketplace' | translate }}</span>
            </button>
            <button class="centralized-button" mat-menu-item routerLink="/tokenization" *ngIf="tokenizationEnabled">
                <div class="container-mat-icon" [ngClass]="{'selected': platform.TOKENIZATION === currentPlatform}">
                    <mat-icon
                        class="medias-icon"
                        [matTooltip]="'header.menuUser.tokenization' | translate" 
                        [matTooltipClass]="'tooltip-info'">assessment</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.TOKENIZATION === currentPlatform}" *ngIf="quantityButtons <= 4">{{ 'button.tokenization' | translate }}</span>
            </button>
            <button class="centralized-button" mat-menu-item routerLink="/tokenization/crowdfunding" *ngIf="crowdfundingEnabled">
                <div class="container-mat-icon" [ngClass]="{'selected': platform.CROWDFUNDING === currentPlatform}">
                    <mat-icon 
                        class="medias-icon" 
                        [matTooltip]="'header.menuUser.crowdfunding' | translate" 
                        [matTooltipClass]="'tooltip-info'">workspaces</mat-icon>
                </div>
                <span [ngClass]="{'selected': platform.CROWDFUNDING === currentPlatform}" *ngIf="quantityButtons <= 4">{{ 'button.crowdfunding' | translate }}</span>
            </button>
        </div>
    </div>
</div>
