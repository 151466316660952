import { MediaType } from "./media-type.enum";
import { PriceHistory } from "./price-history.model";
import { ProductMedia } from "./product-media.model";
import { BdmProductsSpecialPrice } from "./products.bdm.special.price";

export class ProductsBackend {
    public id: string;

    public name: string;

    public description: string;

    public category_id: string;

    public store_id?: string;

    public is_vip?: boolean;

    public freeShipping?: boolean;

    public enabled?: boolean;

    public important?: boolean;

    public stock_amount?: number;

    public grace_period?: number;

    public values?: object;

    public when?: Date;

    public disabled?: Date;

    public midias?: Array<ProductMedia>;

    public price?: number;

    public who?: string;

    public prices?: Array<PriceHistory>;
    public favorites?: Array<any>;

    public promotions?: Array<PromotionApplication>;

    ////////////////
    public oldPrice?: number;
    public discount?: number;
    public isFavorite?: boolean;
    public fipePrice?: number;
    public rating?: any;
    public photos?: string[];
    public video?: string;
    public address?: any;
    public bdmPrice?: BdmProductsSpecialPrice;
    public days?: Array<EventDaysDto>;
    public waterMark?: string;

    constructor(data: ProductsBackend) {
        if (data.promotions && data.promotions[0]) {
            const promotion: PromotionApplication = data.promotions[0];
            if (promotion.enabled) {
                this.oldPrice = data.prices[0].price;
                this.price = promotion.promotion.method === 'A' ? data.prices[0].price - promotion.promotion.amount_to_apply : data.prices[0].price *
                    (1 - (promotion.promotion.amount_to_apply / 100));
                this.discount = this.oldPrice - this.price;
            } else {
                this.price = data.prices[0].price;
            }
        } else {
            this.price = data.prices[0].price;
        }
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.stock_amount = data.stock_amount;
        this.store_id = data.store_id;
        this.is_vip = data.is_vip;
        this.enabled = data.enabled;
        this.category_id = data.category_id;
        this.important = data.important;
        this.favorites = data.favorites;
        this.grace_period = data.grace_period;
        this.values = data.values;
        this.address = data.address;
        this.when = data.when;
        this.midias = data.midias;
        this.prices = data.prices;
        this.days = data.days;
        this.bdmPrice = data.bdmPrice;
        this.photos = [];
        if (data.midias) {

            for (let midia of data.midias) {
                this.photos.push(midia.midia_url);

                if(midia.type === MediaType.VIDEO) {
                    this.video = midia.midia_url;
                }
            }
        }

        if(data?.favorites?.length > 0) {
            this.isFavorite = true;
        } else {
            this.favorites = [];
        }
    }


    // public getOldPrice(): number {
    //     return this.prices[0].price;
    // }

    // public getPrice(): number {
    //     return this.prices[0].price;
    // }
}


export class PromotionApplication {

    public id: string;

    public promotionId: string;

    public enabled: boolean;

    public productId: string;

    public from: Date;

    public until: Date;

    public products: ProductsBackend[];

    public promotion?: any;
}

export class EventDaysDto
{
    public id: string;
    public day: Date;
    public hours?: Array<EventsDaysHoursEntity>;
    public prices?: Array<EventsDaysPriceEntity>;
} 

export class EventsDaysHoursEntity
{
    public id: string;

    public hour: number;

    public stock_amount: number;
}

export class EventsDaysPriceEntity
{
    public id: string;

    public type: string;

    public price: number;
}