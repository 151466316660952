import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormatValueService {

    constructor() { }

    /**
     * this method format the cpf and cnpj values
     * @param identifier cpf and cnpj number
     * @returns formatedIdentifier identifier value formated
     * input 11111111111 
     * output 111.111.111-11 or
     * input 11111111111111
     * output 11.111.111/1111-11
     */
    public cpfCnpjFormater(identifier: string): string {
        let formatedIdentifier: string;
        if (identifier?.length === 11){
            formatedIdentifier = identifier.slice(0,3) + "." + identifier.slice(3,6) + "." + identifier.slice(6,9) + "-" + identifier.slice(9,11);
        } else if (identifier?.length === 14){
            formatedIdentifier = identifier.slice(0,2) + "." + identifier.slice(2,5) + "." + identifier.slice(5,8) + "/" + identifier.slice(8,12) + "-" + identifier.slice(12,14);
        }
        return formatedIdentifier;
    }
}