export interface IChatDetails {
    id: string;
    name?: string;
    createdBy: string;
    createdDate: Date;
    members?: IChatMember[];
    messages: IChatMessage[];
    isGroup?: boolean;
    user?: IChatMember;
}

export interface IChatMessage {
    id: string;
    when: Date;
    sender: IChatMember;
    receiver?: IChatMember;
    messageType: TMessageType;
    content: string | File;
    readyBy: string[];
    received?: Date;
    edited?: Date;
    readWhen?: Date;
    forwarded?: Date;
    reactions: IEmojiReaction[];
    groupId?: string;
    isGroup?: boolean;
}

export interface IChatMember {
    userId: string;
    name: string;
    username: string;
    imageUrl?: string;
    isAdmin?: boolean;
}

export type TMessageType = 'text' | 'video' | 'image';

export interface IEmojiReaction {
    userId: string[];
    emoji: string;
}

export interface ISearchChat {
    id: string;
    imageUrl?: string;
    name: string; // Username or Chat Name
    username: string; // User fullname or list of usernames (if chat)
    selected?: boolean; // Frontend only
}

export interface IChatListItem {
    id: string;
    name: string; //Username or Chat Name
    imageUrl?: string;
    isGroup?: boolean;
    unread?: number;
    lastMessage?: IChatMessage;
    update: Date;
    members: IChatMember[];
    ownerId?: string;
}

export interface IChatCreationRequest {
    senderId: string;
    authorId?: string;
    message: { content: string, type: TMessageType, isGroup?: boolean, chatId?: string, sender?: IChatMember };
    receiverId?: string;
    groupId?: string;
}

export interface IChatFullRequest {
    id: string;
    authorId: string;
    receiverId: string;
    senderId: string;
    message: { content: string, type: TMessageType };
    sentWhen: Date;
    groupId?: string;
    readWhen?: Date;
    receiver: { id: string, name: string, email: string, walletPublicData: string, selfieImage?: string };
    sender: { id: string, name: string, email: string, walletPublicData: string, selfieImage?: string };
}

export interface IChatListRequest {
    correspondentId: string;
}

export interface IListLastUnreadChatMessages {
    id: string;
    authorId: string;
    senderId:string;
    receiverId: string;
    message: string;
    sentWhen: string;
    readWhen: string;
    groupId?: string;
}

export interface IGetGroupByUserId {
    id: string;
    groupName: string;
    ownerId: string;
    createdWhen: string;
    users: Array<{ id?: string, userId: string, addedWhen?: Date, info?: { id: string, name: string, email: string, walletPublicData: string, selfieImage: string } }>;
}

export interface IGetChatList {
    correspondentId: string;
    name: string; //Username or Chat Name
    imageUrl?: string;
    isGroup?: boolean;
    unread?: number;
    lastMessage?: IChatMessage;
    update: Date;
    members: IChatMember[];
}

export interface IMessageReadRequest {
    isGroup: boolean;
    chatId: string;
    senderId: string;
    receiverId: string;
    message: string | File;
}

export interface IMessageWebSocket {
    message: {
        content: string,
        type: TMessageType,
        edited?: Date,
        deleted?: boolean,
        senderId?: string,
        messageId?: string,
        chatId?: string,
        isGroup?: boolean,
        action?: WebSocketActions,
        reactions?: IEmojiReaction[],
        sender: IChatMember,
    },
    senderId: string,
    receiverId: string,
}

// export interface IRegisterReactionRequest {
//     content: string | File,
//     type: TMessageType,
//     edited?: Date,
//     senderId?: string,
//     messageId?: string,
//     membersGroup?: Array<{ userId: string }>,
//     chatId?: string,
//     isGroup?: boolean,
//     reactions?: IEmojiReaction[],
// }

export interface IMessageSenderChatList {
    id: string;
    message: string; 
    chatId: string;
    type: TMessageType; 
    // senderId: string;
    sender: IChatMember,
    isGroup?: boolean;
}

export interface IChatGroupRequest {
    id: string, 
    groupName: string, 
    ownerId: string, 
    users: Array<{ userId: string }>
}

export enum WebSocketActions {
    DELETED_MSG = 'DELETED_MSG',
    UPDATE_MSG = 'UPDATE_MSG',
    CREATED_CHAT_GROUP = 'CREATED_CHAT_GROUP',
    DELETED_CHAT_GROUP = 'DELETED_CHAT_GROUP',
    MEMBER_ADDED_CHAT_GROUP = 'MEMBER_ADDED_CHAT_GROUP',
    REACTION_MSG = 'REACTION_MSG',
}