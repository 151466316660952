import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID,APP_INITIALIZER,ErrorHandler, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationConstants } from './shared/services/util/translation.constants';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LayoutModule } from './shared/layout/layout.module';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ApplicationInitializerFactory } from './translation.initializer';
import { CapacitorHttpProvider } from './interceptors/capacitor.http.provider';
import { App as CapacitorApp } from '@capacitor/app';
import { PixelModule } from 'ngx-pixel';
import { environment } from 'src/environments/environment';
import { ServicesModule } from './shared/services/services.module';

registerLocaleData(ptBr);

CapacitorApp.addListener('backButton', ({canGoBack}) => {
  if(!canGoBack){
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

/**
 * Custom HTTP language loader
 *
 * @param httpClient The httpClient
 */
export function LangHttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient, './../i18n/', '.json');
}

@NgModule({
    declarations: [ AppComponent ],
    imports: [

        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: LangHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        environment.pixelId !== '' ? PixelModule.forRoot({ enabled: true, pixelId: environment.pixelId }) : BrowserModule,
        BrowserModule,
        ServicesModule,
        BrowserAnimationsModule,
        NgxShimmerLoadingModule,
        LayoutModule
    ],
    providers: [
        TranslationConstants,
        CapacitorHttpProvider,
        { provide: LOCALE_ID, useValue: 'pt-br' },
          {
            provide: APP_INITIALIZER,
            useFactory: ApplicationInitializerFactory,
            deps: [ TranslateService, Injector ],
            multi: true
          },
        Title,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
