<div class="simple-contact modal">
    <div class="modal-header">
        <h1>{{ 'marketplace.simpleContactModal.title' | translate }}</h1>
        <mat-icon (click)="close()" class="close" mat-dialog-close>close</mat-icon>
    </div>

    <div [formGroup]="contactForm">
        <div class="input-wrapper">
            <input class="input" formControlName="name" type="text" maxlength="100" [placeholder]="'marketplace.simpleContactModal.name' | translate">
            <div class="not-match absolute" *ngIf="(contactForm?.controls?.name?.invalid && contactForm?.controls?.name?.touched)">
                {{ 'marketplace.simpleContactModal.hint.name' | translate }}
            </div>
        </div>

        <div class="input-wrapper">
            <input class="input" formControlName="email" type="text" maxlength="100" [placeholder]="'marketplace.simpleContactModal.email' | translate">
            <div class="not-match absolute" *ngIf="(contactForm?.controls?.email?.invalid && contactForm?.controls?.email?.touched)">
                {{ 'marketplace.simpleContactModal.hint.email' | translate }}
            </div>
        </div>

        <div class="input-wrapper">
            <textarea class="custom-text-area" formControlName="text" [placeholder]="'marketplace.simpleContactModal.tellUs' | translate" maxlength="500"></textarea>
            <p class="counter">{{ contactForm?.value?.text?.length }} / 500</p>
            <div class="not-match absolute" *ngIf="(contactForm?.controls?.text?.invalid && contactForm?.controls?.text?.touched)">
                {{ 'marketplace.simpleContactModal.hint.text' | translate }}
            </div>
        </div>

    </div>

    <div class="modal-buttons">
        <button (click)="close()" mat-flat-button class="btn outlined">{{ 'button.cancel' | translate }}</button>
        <button [disabled]="loading || contactForm?.invalid" (click)="mailMe()" mat-flat-button class="btn default">{{ 'marketplace.simpleContactModal.send' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>
