export const environment = {
    apiBaseUrl: 'API_BASE_URL',
    pageTitle: 'Vinco',
    isCapacitor:false,
    mockExchange: false,
    tokenIdWall: 'U2FsdGVkX18MvEtUxdhTaTJ/RUI+2opGnrgGlS8X2KXrQ9W4TQ==',
    projectName: 'Vinco',
    firstRoute: '/home',
    graphUrl: 'https://dillianz-site.herokuapp.com/dlz_graph',
    showGraph: false,
    collectionId: 'e40b9792-0b78-4a1a-b2a5-45c61e659667',
    pixelId: '',
    BankingCheckout: false,
    baseUrl: 'vinco.vc',
    showAccountImage: false,
    tokenExchange: 'USDT',
    isKrakenIntegrationEnabled: true,
    signupExternal: false,
    signupURL: '',
    companyName: 'Vinco',
    WS_ENDPOINT: "wss://exi.vinco.vc",
    decimalPlaces: '1.6-6',
    decimalsPlacesBought: 6,
    mainRoute: 'account/login',
    showHeaderBuy: true,
    cnpj: '',
    billNameCompany:"",
    waterMark: false,
    webService: 'ws://54.144.94.179:5000'
};