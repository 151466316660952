import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Axia Melhor Envio service
 */
@Injectable()
export class MelhorEnvioService {

    public url: string = 'https://sandbox.melhorenvio.com.br';

    constructor(
        private readonly http: HttpClient
    ) { }


    public getQuotation(from: string, to: string, height: number, width: number, length: number, weight: number): Observable<any> {
        return this.http.post(`${this.url}/api/v2/me/shipment/calculate`, {
            "from": {
                "postal_code": from
            },
            "to": {
                "postal_code": to
            },
            "package": {
                "height": height,
                "width":width,
                "length":length,
                "weight": weight
            }
        },
            {
                headers: {
                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjBiNjYzNjMzNTE3OGU3MDVjZTk0OWI0NTc1NDliYWQwNTI5NzZiZjc0NzE1YjZlMThlNGY2NDhmZmY4NDFjNmM4NzUxMWRiOWFhMGQ1YmQzIn0.eyJhdWQiOiI5NTYiLCJqdGkiOiIwYjY2MzYzMzUxNzhlNzA1Y2U5NDliNDU3NTQ5YmFkMDUyOTc2YmY3NDcxNWI2ZTE4ZTRmNjQ4ZmZmODQxYzZjODc1MTFkYjlhYTBkNWJkMyIsImlhdCI6MTYyOTcyMDY2MCwibmJmIjoxNjI5NzIwNjYwLCJleHAiOjE2NjEyNTY2NjAsInN1YiI6IjBjZjRjY2E3LTE3Y2EtNGE5YS04N2Q2LWE5YTNkYmExMmYyYyIsInNjb3BlcyI6WyJjYXJ0LXJlYWQiLCJjYXJ0LXdyaXRlIiwiY29tcGFuaWVzLXJlYWQiLCJjb21wYW5pZXMtd3JpdGUiLCJjb3Vwb25zLXJlYWQiLCJjb3Vwb25zLXdyaXRlIiwibm90aWZpY2F0aW9ucy1yZWFkIiwib3JkZXJzLXJlYWQiLCJwcm9kdWN0cy1yZWFkIiwicHJvZHVjdHMtZGVzdHJveSIsInByb2R1Y3RzLXdyaXRlIiwicHVyY2hhc2VzLXJlYWQiLCJzaGlwcGluZy1jYWxjdWxhdGUiLCJzaGlwcGluZy1jYW5jZWwiLCJzaGlwcGluZy1jaGVja291dCIsInNoaXBwaW5nLWNvbXBhbmllcyIsInNoaXBwaW5nLWdlbmVyYXRlIiwic2hpcHBpbmctcHJldmlldyIsInNoaXBwaW5nLXByaW50Iiwic2hpcHBpbmctc2hhcmUiLCJzaGlwcGluZy10cmFja2luZyIsImVjb21tZXJjZS1zaGlwcGluZyIsInRyYW5zYWN0aW9ucy1yZWFkIiwidXNlcnMtcmVhZCIsInVzZXJzLXdyaXRlIiwid2ViaG9va3MtcmVhZCIsIndlYmhvb2tzLXdyaXRlIl19.EtO0syHFXdWDgD0vI4c9Ld0hP0fB_DBV_hS9uCgY1ybeXwnC7Gl_zqAFLvH_YIEuddf3b2Gta4RcKdEFT39zZN2REUFZtQNWelJMW6uQD7JyRQBoV8ERGDY-aojZroBvZ65ynO4QbfJwC4C9lC4ncThoxeQvY-KaCuUI9-RKCbzhXdrH5iNtLeIRXn1eazOq6b7M1jIYbU6Q7APwYu1OukSFmPGuiZYc7HYR2wImIsX_h3S3EgPMB3Gj0yeFLoZ4TRi2r5LP2FCol-d_kAQfWPZr-X4eXEY-2VKhzighWkRlrmUxctm6HqvbEJxgdKrD84E0taTwA7qWkx6rhkx6tRDi52e8agghMd9AlDwWliYDfMmfxRTWI_uK0onqsolJR4jwqf7lYB2WZ56tuf8nXoOZ-7afNQooTx7MXUxQzg5LxlwotEhRz745gt6WNv9jFIhlqcKfJNv99huGItFWrk9wgmRO2r3yVRe5-hZE5PblLIwRUUac_YZh7t3VE_9_w3e-JVjp1-8b_-FQfbbOk72HH48--j9CwHliDljir0YUE-ohDL5_FtI7OOvPkwR9tG-7q0SwnGVDovu4kzqKPAeKO7yYx-V91kVnjGOaj1ZUC8bmZnvia8nT9gas8BjiEEdvJUh3wUnP1X8pFW6P6L9PV1gpDiPN62S7kfsd_hw',
                    'User-Agent': 'Aplicação mateus@axiadigitalsolutions.com',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}