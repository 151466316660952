//These strings will be used to compare the name configured in the Vault, hence,
//It must be the very same name as configured over there.
export enum FeatureNames {
    TOKEN_HANDLING = 'TOKEN_HANDLING',
    REGISTRATION_KYC = 'REGISTRATION_KYC',
    NFT_RESALE = 'NFT_RESALE',
    METAMASK = 'METAMASK',
    SEND_NFT = 'SEND_NFT',
    BLOCK_CROWDFUNDING_WITHOUT_APPROVAL = 'BLOCK_CROWDFUNDING_WITHOUT_APPROVAL',
    PAYMENT_WITH_PIX = 'PAYMENT_WITH_PIX',
    PAYMENT_WITH_CIELO = "PAYMENT_WITH_CIELO",
    LOANS = 'LOANS',
    WITHDRAWAL_HANDLING = 'WITHDRAWAL_HANDLING',
    NFT_PACKAGES = 'NFT_PACKAGES',
    DEPOSITS_HANDLING = 'DEPOSITS_HANDLING',
    COIN_PAYMENTS = 'COIN_PAYMENTS',
    NFT_EXTERNAL_PURCHASE = 'NFT_EXTERNAL_PURCHASE',
    COIN_DEPOSITS = 'COIN_DEPOSITS',
    STAKING_PERMISSION='STAKING_PERMISSION',
    STAKING_SIGNMENT='STAKING_SIGNMENT',
    PAG_SEGURO = 'PAG_SEGURO',
    FRIENDS= 'FRIENDS',
    ID_WALL_SDK = 'ID_WALL_SDK',
    CLEAR_LEDGER = 'CLEAR_LEDGER',
    BRASIL_CASH = 'BRASIL_CASH',
    TRANSFERA = 'TRANSFERA',
    PAY42 = 'PAY42',
    CROWDFUNDING = 'CROWDFUNDING',
    CVM_FOOTER = 'CVM_FOOTER',
    USER_KYC='USER_KYC',
    FIAT_PAYMENTS='FIAT_PAYMENTS',
    BRLA='BRLA',
    CELCOIN_INTEGRATION = 'CELCOIN_INTEGRATION',
    STAKING_NFT = 'STAKING_NFT',
    ONE_SIGNAL = 'ONE_SIGNAL',
    AUCTION_NFT = 'AUCTION_NFT',
    NFT_VIDEO = 'NFT_VIDEO',
    BUY_TOKEN = 'BUY_TOKEN',
    FIAT_DEPOSITS = 'FIAT_DEPOSITS',
    COIN_CHART = 'COIN_CHART',
    TOKEN_WITHDRAWAL = 'TOKEN_WITHDRAWAL',
    ESCROW = 'ESCROW',
    VESTING_BLOCKCHAIN = 'VESTING_BLOCKCHAIN',
    NETELLER_PAYMENT = 'NETELLER_PAYMENT',
    WALLET = 'WALLET',
    PAYMENT_LOCATIONS_CONTROL = 'PAYMENT_LOCATIONS_CONTROL',
    DIGITAL_BANKING = 'DIGITAL_BANKING',
    EXCHANGE = 'EXCHANGE',
    IN1888_REPORT = 'IN1888_REPORT',
    MIDAS_MARKETPLACE = 'MIDAS_MARKETPLACE',
    MIDAS_EVENTS = 'MIDAS_EVENTS',
    MIDAS_HOME_NFTS = 'MIDAS_HOME_NFTS',
    MIDAS_STAKING = 'MIDAS_STAKING',
    MIDAS_TOKENIZATION = 'MIDAS_TOKENIZATION',
    LETTER_OF_CREDIT = 'LETTER_OF_CREDIT',
    SPECIAL_PRICES='SPECIAL_PRICES',
    COIN_PAYMENTS_KAIROS = 'COIN_PAYMENTS_KAIROS',
    DIGITAL_BANKING_KAIROS = 'DIGITAL_BANKING_KAIROS',
    CHAT = 'CHAT',
    PAYMENT_QUEUE = 'PAYMENT_QUEUE',
    BRASIL_BITCOIN = 'BITCOIN_BRASIL'
}