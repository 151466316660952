<div class="send-asset-modal modal">
    <div class="modal-header">
        <h1>{{ 'myAssets.modal.title' | translate }}</h1>
        <mat-icon class="close" mat-dialog-close>close</mat-icon>
    </div>

    <div class="asset-detail">
        <img class="asset-img" [src]="getMainPhoto(asset)" [alt]="asset?.name">
        <div>
            <p class="asset-name">{{ asset?.name }}</p>
            <p class="asset-identity">{{ 'myAssets.id' | translate }} {{ asset?.blockchainId }}</p>
        </div>
    </div>

    <label class="input-label">{{ 'myAssets.modal.walletNumber' | translate }}</label>
    <div class="input-icon">
        <input class="input etc" [(ngModel)]="walletNumber" [placeholder]="'myAssets.modal.insertHere' | translate">
    </div>
    <label class="input-label-error" *ngIf="walletNumber && !validateWalletFormat()">
        {{ ('managePackages.crud.wrongWalletFormat' | translate) }}
    </label>

    <div class="modal-buttons">
        <button (click)="close()" mat-flat-button class="btn outlined">{{ 'button.cancel' | translate }}</button>
        <button [disabled]="!validateWalletFormat() || loading" (click)="sendAsset()" mat-flat-button class="btn default">{{ 'myAssets.sendAsset' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>