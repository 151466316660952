import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { countries } from 'src/app/constants/countries.constants';

@Injectable()
export class NationalityService {

   constructor(
      private readonly http: HttpClient
   ) { }

   public getAll(): Array<INationality> {
      const nationalityList: Array<INationality> = countries.map((item: any) => {
         return {
            name: item.name?.common,
            idd: item.idd?.root && item.idd?.suffixes[0] ? item.idd.root + item.idd.suffixes[0] : ''
         }
      })
      return nationalityList.sort((a, b) => {
         return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
   }
}

export interface INationality {
   idd: any;
   name: string;
}