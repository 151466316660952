import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { IFiatCurrency } from 'src/app/shared/models/IFiatCurrency';
import { SnackBarTheme } from 'src/app/shared/models/snackbar-theme.enum';
import { AccountService } from 'src/app/shared/services/account/account.services';
import { AssetsService } from 'src/app/shared/services/account/asset.services';
import { OnkeypressService } from 'src/app/shared/services/util/onkeypress.service';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { ValueConverterService } from 'src/app/shared/services/util/value-converter.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';

@Component({
    selector: 'app-modal-auction',
    templateUrl: './modal-auction.component.html',
    styleUrls: ['./modal-auction.component.scss']
})
export class ModalAuctionComponent implements OnInit {

    public loading: boolean = false;
    public asset: Assets;
    public minimumPrice: string = '';
    public deadLine: Date = new Date(new Date().setDate(new Date().getDate() + 1)); // Tomorrow.
    public fiatCurrency: IFiatCurrency = new IFiatCurrency();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { asset: Assets },
        private readonly dialogRef: MatDialogRef<ModalAuctionComponent>,
        private readonly snackbarComponent: SnackbarComponent,
        private readonly accountService: AccountService,
        private readonly assetsService: AssetsService,
        private readonly keypressService: OnkeypressService,
        public readonly valueConverterService: ValueConverterService,
        private readonly translationConstants: TranslationConstants
    ) { }

    public ngOnInit(): void {
        this.initData();
    }

    public initData(): void {
        if (this.data?.asset) {
            this.fiatCurrency = this.accountService.getFiatCurrency();
            this.asset = new Assets(this.data.asset);
        } else {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalAuction.snackbar.error'), SnackBarTheme.success, 4000);
            this.closeModal();
        }
    }

    public getMainPhoto(asset: Assets): string {
        return this.assetsService.getAssetMainPhoto(asset);
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public performTransaction(): void {
        this.loading = true;

        this.deadLine.setHours(0, 0, 0, 0); // To use the initial time of the day.

        this.assetsService.createAuction(this.asset.nftId, this.valueConverterService.handlingMonetaryAmount(this.minimumPrice), this.deadLine).subscribe(success => {
            if (success) {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalAuction.snackbar.success'), SnackBarTheme.success, 4000);
            } else {
                this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalAuction.snackbar.error'), SnackBarTheme.success, 4000);
            }
        }, error => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalAuction.snackbar.error'), SnackBarTheme.error, 4000);
        }).add(() => {
            this.loading = false;
            this.dialogRef.close(true);
        });
    }

    public onKeyPressEvent(event: any): void {
        this.keypressService.onlyAllowNumbers(event, false, 20);
    }

    public checkValue(): boolean {
        const value = this.valueConverterService.handlingMonetaryAmount(this.minimumPrice);
        if (value.isGreaterThan(0) && this.deadLine) {
            return false;
        } else {
            return true;
        }
    }

    public dateFilter = (date: Date | null): boolean => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date ? date > today : false;
    }
}
