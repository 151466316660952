import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarTheme } from '../models/snackbar-theme.enum';
import { Snackbar } from '../../constants/snackbar.constants';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
    constructor(
        public snackBar: MatSnackBar
    ) { }

    public openSnackBar(message: string, theme: SnackBarTheme, duration?: number): void {
        let themeNew: string;
        if (theme === SnackBarTheme.error) {
            themeNew = 'error_new';
        } else if (theme === SnackBarTheme.success) {
            themeNew = 'success_new';
        } else {
            themeNew = 'default_new';
        }

        this.snackBar.open(message, 'X', {
            duration: duration || Snackbar.DEFAULT_DURATION,
            panelClass: [themeNew]
        });
    }
}