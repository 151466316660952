import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../../constants/rest-endpoint.constants";

@Injectable({
    providedIn: 'root'
})
export class OrderService {


    constructor(
        private readonly http: HttpClient
    ) { }

    public getOrders(): Observable<Array<any>> {
        return this.http.get(RestEndpoint.getAllOrders, {params: { kairosRequest: 'true'}})
            .pipe(
                map((data: Array<any>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getOrderById(id: number): Observable<any> {
        return of(undefined);
    }

    public changeOrderStatus(parameter: {id: string, status: string}): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.changeOrderStatus, parameter)
            .pipe(
                map((templateId: string) => {
                    return true;
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }
}