export class LocalStorageKeys{
    public static USER_LOGGED_KEY: string = 'USER_LOGGED';

    public static CREATED_BANKING: string = 'CREATED_BANKING';

    public static FIAT_CURRENCY_KEY: string = 'FIAT_CURRENCY';

    //To be concatenated with feature name aftwards, leading to, for instance, FEATURE_isTokenEnabled
    public static FEATURE_STATUS: string = 'FEATURE_';

    public static USER_EMAIL_ENTRY: string = 'USER_EMAIL_ENTRY';

    public static TRANSLATABLE_TEXTS: string = 'TRANSLATABLE_TEXTS';

    public static ENIATO_HEADER_LINKS: string = 'ENIATO_HEADER_LINKS';

    public static NETWORK_LIST_SYNC: string = 'NETWORK_LIST_SYNC';
    public static NETWORK_LIST: string = 'NETWORK_LIST';

    public static MY_STAKED_NFTS: string = 'MY_STAKED_NFTS';

    public static UNIT_OF_MONEY: string = 'UNIT_OF_MONEY';

    public static HIDE_BALANCE: string = 'HIDE_BALANCE';


    public static HIDE_BANKING_BALANCE: string = 'HIDE_BANKING_BALANCE';

    public static LANGUAGE_LIST: string = 'LANGUAGE_LIST';

    public static LIST_OF_BANKS: string = 'LIST_OF_BANKS';

    public static BANK_ACCOUNT_ORDERED: string = 'BANK_ACCOUNT_ORDERED';

    // Used to store the id of the product that will be favorited when the user is not logged in yet
    public static FAVORITE_PRODUCT_ID: string = 'FAVORITE_PRODUCT_ID';

    public static SHIPPING_DATA: string = 'SHIPPING_DATA';

    public static CART_DETAILS: string = 'CART_DETAILS';
}