import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../../constants/rest-endpoint.constants";
import { RequestDocument } from "../../../models/DocumentList";
import { Requests } from "../../../models/Requests";

@Injectable({
    providedIn: 'root'
})
export class LetterOfCreditService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public getRequests(): Observable<Array<Requests>> {
        return this.http.get(RestEndpoint.getAllRequests, {})
            .pipe(
                map((data: Array<Requests>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getRequest(letterIdentifier: string): Observable<Requests> {
        return this.http.get(RestEndpoint.getRequest, {
            params: {
                letterIdentifier
            }
        })
            .pipe(
                map((data: Requests) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getLettersByNumber(id: string): Observable<Requests> {
        return;
    }

    public createNewRequest(creditLetter: Requests): Observable<boolean> {
        return this.http.post<any>(`${RestEndpoint.createRequest}`, creditLetter)
            .pipe(
                map((data: any) => data.id)
            );
    }

    public updateLetter(updatedLetter: Requests): Observable<boolean> {
        return this.http.put<any>(`${RestEndpoint.updateRequest}`, updatedLetter)
        .pipe(
            map((data: any) => data)
        );
    }

    public assignToMe(updatedLetter: Requests): Observable<boolean> {
        return this.http.put<any>(`${RestEndpoint.assignToMe}`, updatedLetter)
        .pipe(
            map((data: any) => data.id)
        );
    }

    public createDocument(requestDocument: RequestDocument): Observable<string> {
        return this.http.post<any>(`${RestEndpoint.createDocument}`, requestDocument)
            .pipe(
                map((data: any) => data.id)
            );
    }

    public deleteDocument(documentId: string): Observable<boolean> {
        return this.http.delete<string>(`${RestEndpoint.deleteDocument}/${documentId}`)
            .pipe(
                map((data: any) => data.id)
            );
    }
}
