import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AvailableNFTsReport, FinalizedStakingApplication, NFTStakingApplication, NFTStakingConfig, NFTStakingPool, NFTStakingPoolSearch, NFTStakingSummary, StakedNFT } from '../../models/INFTStaking';
import { SessionStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from '../../utils/local.storage.keys';

@Injectable()
export class NFTStakingService {

    constructor(
        private readonly sessionStorage: SessionStorageService,
        private readonly http: HttpClient
    ) {
    }

    public getAllStakingCampaigns(assetId?: string, byUser?: boolean): Observable<NFTStakingConfig[]> {
        let params: HttpParams = new HttpParams();
        if (assetId){
            params = params.set('assetId', assetId);
        }
        if (byUser){
            params = params.set('byUser', String(byUser));
        }

        return this.http.get(RestEndpoint.getAllStakingCampaigns, { params: params })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getStakingCampaignDetails(config_id: string): Observable<NFTStakingConfig>{
        let params: HttpParams = new HttpParams();
        params = params.set('configId', config_id);

        return this.http.get(RestEndpoint.getStakingCampaignDetails, { params: params })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllAssetsForStakingCampaign(searchCriteria?: NFTStakingPoolSearch): Observable<NFTStakingPool[]> {

        let params: HttpParams = new HttpParams();
        params = params.set('configId', searchCriteria.config_id);
        return this.http.get(RestEndpoint.getAllAssetsForStakingCampaign, { params: params })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createStaking(nft_id: string, config_id: string): Observable<NFTStakingApplication> {
        return this.http.post<any>(RestEndpoint.createStaking,
                {'nft_id': nft_id, 'config_id': config_id}
            )
            .pipe(
                map((userData: NFTStakingApplication) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public removeFromStaking(application_id: string): Observable<boolean> {
        return this.http.put<any>(RestEndpoint.removeFromStaking,
                {'application_id': application_id}
            )
            .pipe(
                map((data: boolean) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getSummary(): Observable<NFTStakingSummary> {
        return this.http.get(RestEndpoint.getSummary).
            pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMyStakedNFTs(forceLoad: boolean = false): Observable<StakedNFT[]> {
        const stakedListSaved: string = this.sessionStorage.get(LocalStorageKeys.MY_STAKED_NFTS);
        if (stakedListSaved && !forceLoad) {
            return of(JSON.parse(stakedListSaved) as StakedNFT[]);
        } else {
            return this.http.get(RestEndpoint.getMyStakedNFTs).
                pipe(
                    map((data: StakedNFT[]) => {
                        // Store the staked NFTs to avoid much calls to backend (used for staked details modals)
                        this.setMyStakedNFTsOnStorage(data);
                        return data;
                    }),
                    catchError((err) => {
                        throw (err);
                    })
                );
        }
    }

    // Store the staked NFTs to avoid much calls to backend
    public setMyStakedNFTsOnStorage(stakedList: StakedNFT[]): void {
        const dataObj: string = JSON.stringify(stakedList || []);
        this.sessionStorage.set(LocalStorageKeys.MY_STAKED_NFTS, dataObj);
    }

    public getMyStakedNFTDetails(nftId: string): Observable<StakedNFT> {
        const stakedListSaved: string = this.sessionStorage.get(LocalStorageKeys.MY_STAKED_NFTS);
        const stakedListObj: Array<StakedNFT> = stakedListSaved ? JSON.parse(stakedListSaved) : undefined;
        if (stakedListObj) {
            return of(stakedListObj.find(data => data?.application?.nft_id === nftId));
        } else {
            this.getMyStakedNFTs(true).subscribe(stakedList => {
                return of(stakedList.find(data => data?.application?.nft_id === nftId));
            });
        }
    }

    public getAllNFTsAvailableForStaking(): Observable<AvailableNFTsReport[]> {
        return this.http.get(RestEndpoint.getAllNFTsAvailableForStaking).
            pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getSummarizedStakingApplication(applicationId: string): Observable<FinalizedStakingApplication> {
        let params: HttpParams = new HttpParams();
        params = params.set('applicationId', applicationId);

        return this.http.get(RestEndpoint.getSummarizedStakingApplication, { params: params }).
        pipe(
            map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            })
        );

    }
}
