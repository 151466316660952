import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { map, catchError } from 'rxjs/operators';
import { Collections, CollectionSearchInput, CollectionsSummaryResponse } from '../../models/collections';
import { Network } from '../../models/network.model';
import { CollectionStatistics, SortCollectionsStatistics } from '../../models/collection-statistics.model';
import { LocalStorageKeys } from '../../utils/local.storage.keys';

@Injectable()
export class CollectionsService {

    constructor(
        private readonly http: HttpClient,
        private readonly storage: LocalStorageService
    ) { }

    public createCollection(request: Collections): Observable<any> {
        return this.http.post<any>(RestEndpoint.createCollections, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateCollections(request: Collections): Observable<any> {

        return this.http.put<any>(RestEndpoint.updateCollections, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public deleteCollections(id: string): Observable<boolean> {
        const url = RestEndpoint.deleteCollections + id;
        return this.http.delete<boolean>(url)
            .pipe(
                catchError((err) => {
                    throw err;

                })
            );
    }

    public getCollections(search?: CollectionSearchInput): Observable<any> {
        let params = new HttpParams();
        if (search) {
            for (const key of Object.keys(search)) {
                if (search[key]) {
                    params = params.set(key, (Array.isArray(search[key]) ? search[key].join(',') : search[key]));
                }
            }
        }

        return this.http.get(RestEndpoint.getCollections, { params })
            .pipe(
                map((data: CollectionsSummaryResponse) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getNetworks(onlySync: boolean = false): Observable<Network[]> {
        let storage: string = LocalStorageKeys.NETWORK_LIST;
        if (onlySync){
            storage = LocalStorageKeys.NETWORK_LIST_SYNC;
        }
        const networkList: string = this.storage.get(storage);
        if (networkList) {
            const networkListObj: Array<Network> = JSON.parse(networkList);
            return of(networkListObj || []);
        }

        return this.http.get(RestEndpoint.getNetworks, { params: { onlySync }})
            .pipe(
                map((data: Network[]) => {
                    this.storage.set(storage, JSON.stringify(data || []));
                    return data || [];
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getCollection(collectionIdentifier: string): Observable<Collections> {

        return this.http.get(RestEndpoint.getCollection, { params: { collectionIdentifier }})
            .pipe(
                map((data: Collections) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMinToBuy(): Observable<any> {
        return this.http.get(RestEndpoint.getMinToBuy)
        .pipe(
            map((data: {value: number}) => {
                return data?.value;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getSumUsedByCollection(collectionId: string): Observable<number> {
        return this.http.get(RestEndpoint.getSumUsedByCollection, {params: {collectionId}})
        .pipe(
            map((data: {value: number}) => {
                return data?.value;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getAllCollectionsStatistics(sortRule: SortCollectionsStatistics): Observable<Array<CollectionStatistics>> {
        return this.http.get(RestEndpoint.getAllCollectionsStatistics, { params: { sortRule } })
            .pipe(
                map((data: Array<CollectionStatistics>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    /**
     * Returns the statistics of a single collection, given the id of the collection.
     * @param collectionIdentifier 
     * @returns The statistic for given collection
     */
    public getCollectionStatistics(collectionIdentifier: string): Observable<any> {
        return this.http.get(RestEndpoint.getCollectionStatistics, { params: { collectionIdentifier } })
            .pipe(
                map((data: CollectionStatistics) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    
}
