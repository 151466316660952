import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../../constants/rest-endpoint.constants";
import { Parameter } from "../../../models/parameter.model";

@Injectable({
    providedIn: 'root'
})
export class FlexibleCharacteristicsService {

    constructor(
        private readonly http: HttpClient
    ) { }

    
    public getParameters(): Observable<Array<Parameter>> {
        return this.http.get(RestEndpoint.getAllParameters, {})
            .pipe(
                map((data: Array<Parameter>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }
}