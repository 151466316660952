import { Assets } from "./IAssets.model";
import { ICustomerNFTs } from "./ICustomerNFTs";

export enum UNIT_OF_TIME{
    D = 'D',
    M = 'M'
}

export class NFTStakingConfig{
    public id: string;
    public title: string;
    public staking_length: number;
    public unit_of_time: UNIT_OF_TIME;
    public quantity_reward: number;
    public quantity_user_has: number;
    public enabled: boolean;
    public nft_pools?: NFTStakingPool[];
    public estimated_end_date?: Date;
    public user_has?: boolean;
    constructor (object: NFTStakingConfig){
        Object.assign(this, object);
    }
}

export class NFTStakingConfigSearch{
    public userId?: string;
    public assetId?: string;
    constructor (object?: NFTStakingConfigSearch){
        if (object){
            Object.assign(this, object);
        }
    }
}

export class NFTStakingPool{
    public id: string;
    public config_id: string;
    public asset_id: string;
    public enabled: boolean;
    public configs: NFTStakingConfig;
    public asset_detail: Assets;
}

export class NFTStakingPoolSearch{
    public config_id: string;
    public asset_id?: string;
}

export class StakedNFT{
    public application: NFTStakingApplication;
    public config: NFTStakingConfig;
    public progress: number;
    constructor(object?: NFTStakingApplication){
        if (object){
            Object.assign(this, object);
        }
    }
}

export class NFTStakingApplication{
    public id: string;
    public nft_id: string;
    public config_id: string;
    public customer_id: string;
    public start_date: Date;
    public end_date: Date;
    public early_leave: boolean;
    public leave_date?: Date;
    public reward_provided: boolean;
    public nft_details?: ICustomerNFTs;
    public config?: NFTStakingConfig
    constructor (object?: NFTStakingApplication){
        if (object){
            Object.assign(this, object);
        }
    }
}

export class NFTStakingApplicationCreate{
    public nft_id: string;
    public config_id: string;
    public customer_id: string;
    public start_date: Date;
    public end_date: Date;
    constructor(object: NFTStakingApplicationCreate){
        Object.assign(this, object);
    }
}

export class NFTStakingApplicationRemove{
    public application_id: string;
    public customer_id: string;
    constructor(object: NFTStakingApplicationRemove){
        Object.assign(this, object);
    }
}

export class NFTStakingSummary{
    public stakedNFTs: number;
    public availablesForStaking: number;
}

export class AvailableNFTsReport{
    public nft_details:ICustomerNFTs;
    public campaing_details: NFTStakingConfig[];
}

export class NFTStakingApplicationRewards{
    public id: string;
    public appl_id: string;
    public nft_id: string;
    public paid_at: Date;
}

export class FinalizedStakingApplication {
    public campaign_name: string;
    public staking_length: number;
    public unit_of_time: UNIT_OF_TIME;
    public end_date: Date;
}