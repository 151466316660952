import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HandleDateService {

    constructor() { }

    /**
     * Put some separator in date string without separator
     * * @param dateString Date in string formatted like DDMMyyyy
     * * @param separator Desired separator. 
     * Ex: '/'* @returns dd/mm/yyyy*/
    public dateStringWithSeparator(dateString: string, separator: string): string {
        const day: string = dateString.slice(0, 2);
        const month: string = dateString.slice(2, 4);
        const year: string = dateString.slice(4, undefined);
        return `${day}${separator}${month}${separator}${year}`;
    }
}
     
     