<div class="resell-asset-modal modal">
    <div class="modal-header">
        <h1>{{ 'myAssets.modalSplit.title' | translate }}</h1>
        <mat-icon class="close" mat-dialog-close>close</mat-icon>
    </div>
 
    <div class="asset-detail">
        <img class="asset-img" [src]="getMainPhoto(asset)" [alt]="asset?.description">
        <div>
            <p class="asset-name">{{ asset?.name }}</p>
            <p class="asset-identity">{{ 'myAssets.id' | translate }} {{ asset?.blockchainId }}</p>     
            <p class="asset-identity" *ngIf="asset?.price > 0">{{ 'productDetails.bidvalue' | translate }}: {{ asset?.price }}</p>     
        </div>
    </div>

    <label class="input-label">{{ ('myAssets.modalSplit.minimumParts' | translate) }}</label>
    <div class="input-icon">
        <input type="numeric" class="input etc" [(ngModel)]="minimumPrice" (input)="newParts()" >
    </div>

    <div *ngFor="let part of parts; index as i;">
        <label class="input-label">{{ ('myAssets.modalSplit.amount' | translate) + ' ' + (i + 1) + '?' }}</label>
        <div class="inline-input-date">
            <input type="numeric" class="input etc" [(ngModel)]="part.amount">
        </div>
    </div>

    <div class="modal-buttons">
        <button mat-flat-button class="btn outlined" (click)="closeModal()">{{ 'button.cancel' | translate }}</button>
        <button [disabled]="checkValue()" mat-flat-button class="btn default" (click)="performTransaction()">{{ 'myAssets.modalSplit.aution' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>