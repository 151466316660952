import BigNumber from 'bignumber.js';
import { ProductsBackend } from './products.model';

export class ShoppingCartItem
{
    public totalAmount?: number;
    public tokenAmount?: number;
    public expirationDate?: Date;
    public event_types?: any;
    
    constructor(
        public id: string,
        public item: ProductsBackend,
        public quantity: BigNumber,
    )
    {
    }
}
