import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "angular-web-storage";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { IEniatoHeaderLink } from "../../models/IEniatoHeaderLink";
import { IEniatoHomeSectionData } from "../../models/IEniatoHomeSectionData.model";
import { LocalStorageKeys } from "../../utils/local.storage.keys";

@Injectable()
export class UXConfigsService {

    constructor(
        private readonly http: HttpClient,
        private readonly storage: LocalStorageService
    ) { }

    public getEniatoHeaderLinks(forceLoad: boolean = false): Observable<Array<IEniatoHeaderLink>> {
        if (!forceLoad && this.storage.get(LocalStorageKeys.ENIATO_HEADER_LINKS)){
            return of(this.storage.get(LocalStorageKeys.ENIATO_HEADER_LINKS));
        }

        return this.http.get(RestEndpoint.getEniatoHeaderLinks)
            .pipe(
                map((data: Array<IEniatoHeaderLink>) => {
                    this.storage.set(LocalStorageKeys.ENIATO_HEADER_LINKS, data);
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getOrderOfHomeSections(): Observable<Array<IEniatoHomeSectionData>> {
        return this.http.get(RestEndpoint.getOrderOfHomeSections)
            .pipe(
                map((data: Array<IEniatoHomeSectionData>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }
}