import { Directive, ElementRef, HostListener } from '@angular/core';
import { ValueConverterService } from 'src/app/shared/services/util/value-converter.service';

@Directive({
    selector: '[appMaskDecimalNumber]'
})
export class MaskDecimalNumberDirective {

    constructor(
        private el: ElementRef,
        public readonly valueConverterService: ValueConverterService,
    ) { }

    @HostListener('input', ['$event'])
    public inputChange(event: any): void {
        this.onInputChange(event.target.value);
    }

    /**
     * It formats a given number into a decimal number with 2 decimal places. E.g. 7815000 returns 78.150,00
     * @param originalValue 
     */
    public onInputChange(originalValue: string) {
        let newVal = this.valueConverterService.formatMonetaryInput(originalValue, false);
        if (newVal){
            this.el.nativeElement.value = newVal;
        }
    }
}
