import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import BigNumber from 'bignumber.js';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { IFiatCurrency } from 'src/app/shared/models/IFiatCurrency';
import { SnackBarTheme } from 'src/app/shared/models/snackbar-theme.enum';
import { AccountService } from 'src/app/shared/services/account/account.services';
import { AssetsService } from 'src/app/shared/services/account/asset.services';
import { OnkeypressService } from 'src/app/shared/services/util/onkeypress.service';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { ValueConverterService } from 'src/app/shared/services/util/value-converter.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';

@Component({
    selector: 'app-modal-split',
    templateUrl: './modal-split.component.html',
    styleUrls: ['./modal-split.component.scss']
})
export class ModalSplitComponent implements OnInit {

    public loading: boolean = false;
    public asset: Assets;
    public minimumPrice: number = 0;
    public deadLine: Date = new Date(new Date().setDate(new Date().getDate() + 1)); // Tomorrow.
    public fiatCurrency: IFiatCurrency = new IFiatCurrency();
    public parts: {amount: number}[] = []

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { asset: Assets },
        private readonly dialogRef: MatDialogRef<ModalSplitComponent>,
        private readonly snackbarComponent: SnackbarComponent,
        private readonly accountService: AccountService,
        private readonly assetsService: AssetsService,
        private readonly keypressService: OnkeypressService,
        public readonly valueConverterService: ValueConverterService,
        private readonly translationConstants: TranslationConstants
    ) { }

    public ngOnInit(): void {
        this.initData();
    }

    public newParts(): void {
        this.parts = [];
        for(let i = 0; i < this.minimumPrice; i ++) {
            this.parts.push(Object.assign({}));
        }
    }

    public initData(): void {
        if (this.data?.asset) {
            this.fiatCurrency = this.accountService.getFiatCurrency();
            this.asset = new Assets(this.data.asset);
        } else {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalAuction.snackbar.error'), SnackBarTheme.success, 4000);
            this.closeModal();
        }
    }

    public getMainPhoto(asset: Assets): string {
        return this.assetsService.getAssetMainPhoto(asset);
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public performTransaction(): void {
        this.loading = true;

        this.assetsService.createSplit(this.asset.nftId, this.parts).subscribe(success => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalSplit.snackbar.success'), SnackBarTheme.success, 4000);
        }, error => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('myAssets.modalSplit.snackbar.error'), SnackBarTheme.error, 4000);
        }).add(() => {
            this.loading = false;
            this.dialogRef.close(true);
        });
    }

    public onKeyPressEvent(event: any): void {
        this.keypressService.onlyAllowNumbers(event, false, 20);
    }

    public checkValue(): boolean {
        const value = this.minimumPrice;
        const valueOriginal = this.asset.price;

        let tempAmount = new BigNumber(0);
        for(let part of this.parts) {
            tempAmount = new BigNumber(part.amount).plus(tempAmount);
        }

        if (value > 0 && this.parts?.length >= 2 && new BigNumber(valueOriginal).isEqualTo(tempAmount)) {
            return false;
        } else {
            return true;
        }
    }

    public dateFilter = (date: Date | null): boolean => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date ? date > today : false;
    }
}
