import { Injectable } from '@angular/core';
import BigNumber from 'bignumber.js';
import { FinalizedStakingApplication, NFTStakingApplication, NFTStakingConfig, UNIT_OF_TIME } from '../../models/INFTStaking';

@Injectable({
    providedIn: 'root'
})
export class NftConversionService {

    constructor (

    ) { }

    /**
     * Verify the quantity of time and the unit of time to return a word to translate.
     * @param stakedConfig The campaign that will be verified.
     * @returns The word to concatenate to the translate path.
     */
    public checkPeriod(stakedConfig: FinalizedStakingApplication): string {
        if (stakedConfig?.unit_of_time === UNIT_OF_TIME.D && stakedConfig?.staking_length === 1) {
            return 'day';
        } else if (stakedConfig?.unit_of_time === UNIT_OF_TIME.D && stakedConfig?.staking_length > 1) {
            return 'days';
        } else if (stakedConfig?.unit_of_time === UNIT_OF_TIME.M && stakedConfig?.staking_length === 1) {
            return 'month';
        } else if (stakedConfig?.unit_of_time === UNIT_OF_TIME.M && stakedConfig?.staking_length > 1) {
            return 'months';
        }
    }

    /**
     * Calculates the days remaining to the end of the campaign to redeem the reward, using
     * the current day and the end day of the campaign. Disregards hours, minutes and seconds.
     * @param applicationConfig Campaign application information.
     * @returns Returns the number of days remaining until the reward is redeemed.
     */
    public calculateRemainingPeriod(applicationConfig: NFTStakingApplication): number {
        const today: Date = new Date();
        const endDay: Date = new Date(applicationConfig?.end_date);
        today.setHours(0,0,0,0);
        endDay.setHours(0,0,0,0);
        const difference: BigNumber =  new BigNumber(endDay.getTime() - today.getTime());
        return Number(difference.dividedBy(new BigNumber(1000 * 60 * 60 * 24)));
    }

    /**
     * Verify the quantity of the NFT to return a word to translate.
     * @param quantity quantity of NFT
     * @returns The word to concatenate to the translate path.
     */
    public checkQuantity(quantity: number): string {
        if (quantity === 1) {
            return 'nft';
        } else {
            return 'nfts';
        }
    }

    /**
     * Checks the number of days until the day of the reward redemption from the current day of viewing the campaign.
     * @param campaignDetails The campaign that will be verified.
     * @returns The date the user will receive the campaign reward.
     */
    public checkRemaingTime(campaignDetails: NFTStakingConfig): Date {
        const today: Date = new Date();
        today.setHours(0,0,0,0);

        const period: number = campaignDetails?.staking_length;

        if (campaignDetails?.unit_of_time === UNIT_OF_TIME.D) {
            today.setDate(today.getDate() + period);
        } else {
            today.setDate(today.getDate() + period * 30);
        }

        return today;
    }

    public calculateProgressPercentage(percentage: number): number {
        const result: BigNumber = new BigNumber(percentage).multipliedBy(new BigNumber(100));
        return Number((result).toFixed(2));
    }
}
