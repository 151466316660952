<div class="footer">
    <div class="content">
        <div class="social-networks">
            <img src="/assets/images/logo_white.svg" alt="logo" class="logo" (click)="goToHome()">
            <img src="/assets/images/cvm.svg" class="logo" (click)="goToCvm()" *ngIf="isCrowdfundingEnabled">
        </div>

        <div class="institutional column">
            <span (click)="goToUrl('aboutUs')">{{ 'footer.aboutUs' | translate }}</span>
            <span (click)="goToUrl('policies/privacy')">{{ 'footer.privacyPolicy' | translate }}</span>

            <span (click)="goToUrl('policies/terms')">{{ 'footer.terms' | translate }}</span>
            <span (click)="goToUrl('policies/material')">{{ 'footer.material' | translate }}</span>
            <span (click)="goToUrl('policies/code')">{{ 'footer.code' | translate }}</span>

            <span (click)="openContactChannel()" *ngIf="enableContactButton">{{ 'footer.contact' | translate }}</span>
        </div>
    </div>
    <div class="all-rights centered" id="firstRightsLine">
        <p >{{ companyName }}&nbsp;{{ (displayHiphen ? '-' : '') }}&nbsp;</p>
        <p >{{ ('footer.allRights' | translate) }}</p>
    </div>
</div>
