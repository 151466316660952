import { Component, OnInit, HostListener } from '@angular/core';
import { IExternalLinks } from '../../../shared/models/IExternalLinks';
import { ConfigReaderService } from '../../../shared/services/util/config.reader.service';
import { UtilService } from '../../../shared/services/util/util.service';
import { forkJoin } from 'rxjs';
import { SimpleContactModalComponent } from '../../../shared/simple-contact-modal/simple-contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EContactChannels, IContactDetails } from '../../../shared/models/IContactDetails';
import { environment } from 'src/environments/environment';
import { FeaturesStatusService } from '../../services/util/features-status.service';
import { FeatureNames } from '../../models/feature-names.enum';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    private externalLinks: IExternalLinks = undefined;
    private contactDetails: IContactDetails = undefined;
    public enableContactButton: boolean = false;
    public socialMedias: Array<string> = [];
    public displayHiphen: boolean = true;
    public companyName: string;
    public isCrowdfundingEnabled: boolean = false;

    constructor(
        public dialog: MatDialog,
        private readonly myConfigReader: ConfigReaderService,
        private readonly featureStatusService: FeaturesStatusService,
        private readonly router: Router
    ) { }

    public ngOnInit(): void {
        forkJoin([
            this.myConfigReader.getAllExternalLinks(),
            this.myConfigReader.getContactDetails(),
            this.featureStatusService.getFeatureStatus(FeatureNames.CVM_FOOTER)
        ]).subscribe(async ([externalLinks, contactDetails, isCrowdfundingEnabled]) => {
            this.externalLinks = externalLinks;
            this.socialMedias = Object.keys(this.externalLinks?.socialMidias) || [];
            this.contactDetails = new IContactDetails(contactDetails);
            this.enableContactButton = this.contactDetails.enableContactButton();
            this.isCrowdfundingEnabled = isCrowdfundingEnabled;
        });
        this.companyName = environment.projectName;
    }

    public ngAfterViewInit(): void {
        this.checkDisplayHiphen();
    }

    @HostListener('window:resize', ['$event'])
    private checkDisplayHiphen() {
        const firstRightsLineHeight: number = document.getElementById('firstRightsLine').offsetHeight;
        this.displayHiphen = firstRightsLineHeight === 55;
    }

    public goToUrl(url: string): void {
        UtilService.goToUrl(url, this.externalLinks, this.router);
    }

    public goToHome(): void {
        window.open('/home');
    }

    public openContactChannel(): void {
        const channel: EContactChannels = this.contactDetails.channelToUse();
        if (EContactChannels.EMAIL === channel){
            this.dialog.open( SimpleContactModalComponent, {
                panelClass: 'customDialog',
                data: {
                    contactDetails: this.contactDetails
                }
            } );
        }else if (EContactChannels.CHAT === channel){
            window.open(this.contactDetails.tawkTo);
        }
    }

    public goToCvm(): void {
        window.open('https://www.gov.br/cvm/pt-br', '_blank');
    }
}
