import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../../constants/rest-endpoint.constants";
import { LocalStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from "../../../utils/local.storage.keys";
import { ShippingResponse } from "../../../models/states";
import { BigNumber } from 'bignumber.js';
import { ProductsBackend } from "../../../models/products.model";

@Injectable({
    providedIn: 'root'
})
export class PricesService {

    constructor(
        private readonly http: HttpClient,
        private readonly storage: LocalStorageService,
    ) { }

    public getShippingData(forceLoad: boolean = false): Observable<ShippingResponse> {
        if (!forceLoad && this.storage.get(LocalStorageKeys.SHIPPING_DATA)){
            return of(this.storage.get(LocalStorageKeys.SHIPPING_DATA));
        }

        return this.http.get(RestEndpoint.getShippingData)
            .pipe(
                map((data: ShippingResponse) => {
                    this.storage.set(LocalStorageKeys.SHIPPING_DATA, data);
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public calculatePrice(uf: string, city: string, product: ProductsBackend, quantity: number, shippingData: ShippingResponse): BigNumber {

        let shippingAmount: BigNumber;
        let shippingSafeAmount: BigNumber;
        let shippingSafeAmountType: string;

        /**
         * The first priority is to consider the city
         */
        if(shippingData.cities?.length > 0) {
            const cityData = shippingData.cities.find(dt => dt.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "") === city?.normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
            shippingAmount = cityData?.shipping_amount;
            shippingSafeAmount = cityData?.shipping_safe_amount;
            shippingSafeAmountType = cityData?.shipping_safe_amount_type;
        }

        /**
         * Lets consider the state so
         */
        if(!shippingAmount || !shippingSafeAmount || !shippingSafeAmountType) {
            const state = shippingData.states.find(dt => dt.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "") === uf?.normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
            if(!shippingAmount) {
                shippingAmount = state?.shipping_amount;
            }

            /**
             * In case the safe shipping amount is not defined, we must also collect the safe amount type and vice versa
             */
            if(!shippingSafeAmount || !shippingSafeAmountType) {
                shippingSafeAmount = state?.shipping_safe_amount;
                shippingSafeAmountType = state?.shipping_safe_amount_type;
            }
        }

        /**
         * Lets start by calculate the price multiplying the quantity by the price
         */
        const productPrice: BigNumber = new BigNumber(product.price).multipliedBy(quantity);

        /**
         * Lets now calculate the shipping
         */
        const shippingFinalAmount: BigNumber = new BigNumber(shippingAmount) || new BigNumber(0);

        /**
         * Now we must consider the safe amount
         */
        const shippingSafetyAmount: BigNumber = this.calculateShippingSafetyAmount(shippingSafeAmount || product.bdmPrice?.safetyCharges, shippingAmount, shippingSafeAmountType);

        /**
         * We will sum the first 3 items
         */
        const sumMainAmount: BigNumber = new BigNumber(productPrice).plus(shippingFinalAmount).plus(shippingSafetyAmount);

        /**
         * Lets start to use the percentages
         */
        const admChargesAmount: BigNumber = sumMainAmount.multipliedBy(product.bdmPrice.administrativeCharges).dividedBy(100);

        const reservationChargesAmount: BigNumber = sumMainAmount.multipliedBy(product.bdmPrice.reservationCharges).dividedBy(100);

        const marginChargesAmount: BigNumber = sumMainAmount.multipliedBy(product.bdmPrice.marginCharges).dividedBy(100);

        const fundsChargesAmount: BigNumber = sumMainAmount.multipliedBy(product.bdmPrice.fundsCharges).dividedBy(100);

        /**
         * To finish the price calculation, we must calculate the BDM percentage
         */
        const auxAmount: BigNumber = sumMainAmount.plus(admChargesAmount).plus(reservationChargesAmount).plus(marginChargesAmount).plus(fundsChargesAmount);

        const bdmChargesAmount: BigNumber = auxAmount.multipliedBy(product.bdmPrice.bdmCharges).dividedBy(100);

        /**
         * This is the price calculation
         */
        return auxAmount.plus(bdmChargesAmount);
    }

    private calculateShippingSafetyAmount(shippingSafetyAmount: BigNumber, shippingAmount: BigNumber, shippingSafetyAmountType: string): BigNumber {
        if(new BigNumber(shippingSafetyAmount).isZero() || new BigNumber(shippingSafetyAmount).isNaN()) {
            return new BigNumber(0);
        }

        if(shippingSafetyAmountType === 'P') {
            if(new BigNumber(shippingAmount).isZero() || new BigNumber(shippingAmount).isNaN()) {
                throw new Error('Impossible to calculate the shipping safety amount');
            }
            return new BigNumber(shippingAmount).multipliedBy(shippingSafetyAmount).dividedBy(100);
        } else {
            return new BigNumber(shippingSafetyAmount);
        }
    }
}
