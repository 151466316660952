import { Injectable } from '@angular/core';
import { IEniatoHomeSectionEnum } from '../../models/IEniatoHomeSection.enum';
import { IEniatoHomeSectionData } from '../../models/IEniatoHomeSectionData.model';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    // It is changed after app component finishes loading the service to fetch new section list
    public loaded: boolean = false;

    // Default order of sections in case it is not provided by customer
    public sectionList: Array<IEniatoHomeSectionData> = [
        { section: IEniatoHomeSectionEnum.INTRODUCTION, order: 1, showSection: true },
        { section: IEniatoHomeSectionEnum.CARD_CONTENT, order: 2, showSection: true }, // Search bar and Cards
        { section: IEniatoHomeSectionEnum.CATEGORIES, order: 3, showSection: true }, // Categories
        { section: IEniatoHomeSectionEnum.CAROUSEL_BANNERS, order: 4, showSection: true } // Banners
    ];

    constructor() { }
}