import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CustomerBalance } from '../../models/customer.balance';
import { AssetsSearchInput } from '../../models/AssetsSearchInput.model';
import { Assets } from '../../models/IAssets.model';
import { ReportGeneralJournal } from '../../models/report.general.journal';
import { MarketJournal, MarketJournalInput } from '../../models/market-journal.model';
import { FinanceResponse } from '../../models/finance.response';
import { Router } from '@angular/router';
import { NFTPackage } from '../../models/INFTPackage';

@Injectable()
export class PackagesService {

    /**
     * Stores the user to be resetted
     */
    public userEmail: string;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router

    ) {
    }

    public searchPackageCategory(category: string): void {
        this.router.navigate(['category/' + category]);
    }

    public createPackage(request: NFTPackage): Observable<any> {
        return this.http.post<any>(RestEndpoint.createPackage, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public sendNft(request: {
        customerIdToTransferTo: string,
        unitOfMoney: string,
        assetId: string
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.sendNFTs, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public buyNFTs(request: {
        unitOfMoney: string,
        assetId: string,
        principalAmount: number
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.buyNFT, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }



    public updateDetailsPackage(request: any): Observable<any> {

        return this.http.put<any>(RestEndpoint.updateDetailsPackage, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getEniatoBalance(): Observable<any> {
        return this.http.get(RestEndpoint.getEniatoBalance, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBalance(): Observable<CustomerBalance> {
        return this.http.get(RestEndpoint.balance, {})
            .pipe(
                map((data: any) => {
                    return data?.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createMarket(request: MarketJournalInput): Observable<FinanceResponse> {
        return this.http.post<any>(RestEndpoint.createMarket, request)
            .pipe(
                map((userData: FinanceResponse) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getPackageDetails(assetIdentifier?: string): Observable<any> {

        return this.http.get(RestEndpoint.getDetailsPackage, { params: {
            assetIdentifier
        } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getPackages(search?: AssetsSearchInput): Observable<NFTPackage[]> {
        let params = new HttpParams();
        if (search) {
            for (const key of Object.keys(search)) {
                if (search[key]) {
                    params = params.set(key, typeof search[key] === 'string' ? search[key] : JSON.stringify(search[key]));
                }
            }
        }

        return this.http.get(RestEndpoint.getPackages, { params })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMarketTransactions(assetId?: string): Observable<Array<MarketJournal>> {

        return this.http.get(RestEndpoint.markets, { params: {assetId }})
            .pipe(
                map((data: any) => {
                    return data?.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserTransactions(assetId?: string): Observable<ReportGeneralJournal> {

        return this.http.get(RestEndpoint.getUserTransactions)
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAsset(assetIdentifier?: string): Observable<Assets> { // TODO: Review if it is necessary in the package.service.

        return this.http.get(RestEndpoint.getAsset, { params: {assetIdentifier }}) // TODO: Review RestEndpoint.getAsset in the package.service
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getHistory(assetIdentifier?: string): Observable<Assets> { // TODO: Review if it is necessary in the package.service.

        return this.http.get(RestEndpoint.getAsset, { params: {assetIdentifier }}) // Review RestEndpoint.getAsset in the package.service
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getHttpParamsFromObject(object: any): HttpParams {
        let params = new HttpParams();
        for (const key of Object.keys(object)) {
            if (object[key]) {
                params = params.set(key, object[key]);
            }
        }
        return params;
    }

    public getCategories(): Observable<any> {
        return this.http.get(RestEndpoint.getCategories, {})
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBanners(): Observable<any> {
        return this.http.get(RestEndpoint.getBanners, {})
            .pipe(
                map((data: Assets) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}
