import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../../constants/rest-endpoint.constants";
import { Store } from "../../../models/store.model";

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public getStoreById(storeIdentifier: string): Observable<Store> {
        return this.http.get(RestEndpoint.getStore, {params: {storeIdentifier}})
            .pipe(
                map((data: Store) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }
}