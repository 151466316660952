import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { CategoryItem } from "../../models/category-item.model";

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    constructor(
        private readonly http: HttpClient
    ) { }


    public getCategories(): Observable<Array<CategoryItem>> {
        return this.http.get(RestEndpoint.getAllCategories, {})
            .pipe(
                map((data: Array<CategoryItem>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public saveCategory(categoryItem: CategoryItem): Observable<boolean> {
        return this.http.put<string>(RestEndpoint.updateCategories, categoryItem)
            .pipe(
                map((templateId: string) => {
                    if(templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public createCategory(categoryItem: CategoryItem): Observable<boolean> {
        return this.http.post<string>(RestEndpoint.createCategory, categoryItem)
            .pipe(
                map((templateId: string) => {
                    if(templateId) {
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    public deleteCategory(id: string): Observable<boolean> {
        const url = RestEndpoint.deleteCategory + id;
        return this.http.delete<boolean>(url)
            .pipe(
                catchError((err) => {
                    throw err;

                })
            );
    }

    
}