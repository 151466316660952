import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "angular-web-storage";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RestEndpoint } from "../../../constants/rest-endpoint.constants";
import { IMetaMaskParameters } from "../../models/IMetaMaskParameters";

@Injectable()
export class MetamaskService {

    constructor(
        private readonly http: HttpClient,
        private readonly storage: LocalStorageService
    ) { }

    public getMetaMaskData(): Observable<IMetaMaskParameters> {

        return this.http.get(RestEndpoint.getMetamaskParameters, {})
            .pipe(
                map((data: IMetaMaskParameters) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }
}