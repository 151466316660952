<div class="resell-asset-modal modal">
    <div class="modal-header">
        <h1>{{ 'myAssets.modalResell.title' | translate }}</h1>
        <mat-icon class="close" mat-dialog-close>close</mat-icon>
    </div>

    <div class="asset-detail">
        <img class="asset-img" [src]="getMainPhoto(asset)" [alt]="asset?.description">
        <div>
            <p class="asset-name">{{ asset?.name }}</p>
            <p class="asset-identity">{{ 'myAssets.id' | translate }} {{ asset?.blockchainId }}</p>     
        </div>
    </div>

    <label class="input-label">{{ ('myAssets.modalResell.howMuch' | translate) + ' (' + fiatCurrency.currency + ')?' }}</label>
    <div class="input-icon">
        <input type="text" class="input etc" placeholder="0,00" (keypress)="onKeyPressEvent($event)" [(ngModel)]="resaleValue" appMaskDecimalNumber >
    </div>

    <label class="input-label">{{ 'myAssets.modal.userEmail' | translate }}</label>
    <div class="input-icon">
        <input class="input etc" [(ngModel)]="only_for_customer" [placeholder]="'myAssets.modal.insertHere' | translate">
    </div>

    <div class="modal-buttons" *ngIf="!isEditing">
        <button mat-flat-button class="btn outlined" (click)="closeModal()">{{ 'button.cancel' | translate }}</button>
        <button [disabled]="checkValue()" mat-flat-button class="btn default" (click)="resellAsset()">{{ 'myAssets.modalResell.resell' | translate }}</button>
    </div>

    <div class="modal-buttons" *ngIf="isEditing">
        <button (click)="closeResell()" mat-flat-button class="btn outlined">{{ 'myAssets.modalResell.closeResale' | translate }}</button>
        <button [disabled]="checkValue()" mat-flat-button class="btn default" (click)="saveChange()">{{ 'myAssets.modalResell.saveChange' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>