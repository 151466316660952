import { Component } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { FintechAccountStatus } from '../models/BankingAccount';
import { FeatureNames } from '../models/feature-names.enum';
import { MultiplatFormEnum } from '../models/multplatform.enum';
import { UserIdentifierEnum } from '../models/user-identifier.enum';
import { AccountService } from '../services/account/account.services';
import { ConfigReaderService } from '../services/util/config.reader.service';
import { FeaturesStatusService } from '../services/util/features-status.service';

@Component({
    selector: 'app-bottom-navigation',
    templateUrl: './bottom-navigation.component.html',
    styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent {

    public explorerLink: string = '';
    public showTransactions: boolean = true;
    public currentPlatform: MultiplatFormEnum = MultiplatFormEnum.WALLET;
    public urls: Array<string> = [];
    public digitalBankingEnabled: boolean = false;
    public exchangeEnabled: boolean = true;
    public showHeaderBuy: boolean = false;
    public marketplaceEnabled: boolean = true;
    public tokenizationEnabled: boolean = false;
    public showBottom: boolean = false;
    public isMultiplatform: boolean = false;
    public platform: typeof MultiplatFormEnum = MultiplatFormEnum;
    public eventsEnabled: boolean = false;
    public walletEnabled: boolean = false;
    public loading: boolean = true;
    public hasMfa: boolean = true;
    public crowdfundingEnabled: boolean = false;
    public quantityButtons: number = 0;

    constructor(
        private readonly configService: ConfigReaderService,
        private readonly router: Router,
        private readonly featureStatusService: FeaturesStatusService,
        private readonly accountService: AccountService
    ) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.urls.push(event.url);
                this.getCurrentPlatform(event.url);
            }
        });
    }

    public ngOnInit(): void {

        this.configService.getAllExternalLinks().subscribe(links => {
            this.explorerLink = links.contact || '';
        });


        this.accountService.getLoggedUserDetails().subscribe(user => {
            if(user?.documentType === UserIdentifierEnum.External || user?.bankingAccount?.fintechStatus !== FintechAccountStatus.APPROVED) {
                this.showTransactions = false;
            }
        });


        let hasEnabled = 0;
        this.featureStatusService.getFeatureStatus(FeatureNames.DIGITAL_BANKING, true).subscribe(status => {
            this.digitalBankingEnabled = status;

            if(this.digitalBankingEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });
        this.featureStatusService.getFeatureStatus(FeatureNames.EXCHANGE).subscribe(status => {
            this.exchangeEnabled = status;

            if(this.exchangeEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.MIDAS_MARKETPLACE).subscribe(status => {
            this.marketplaceEnabled = status;

            if(this.marketplaceEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.MIDAS_TOKENIZATION).subscribe(status => {
            this.tokenizationEnabled = status;

            if(this.tokenizationEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.CROWDFUNDING, true).subscribe(crowdfundingEnabled => {
            this.crowdfundingEnabled = crowdfundingEnabled;

            if(this.crowdfundingEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.WALLET).subscribe(status => {
            this.walletEnabled = status;

            if(this.walletEnabled) {
                hasEnabled = hasEnabled + 1;
            }
        });

        if(hasEnabled > 1) {
            this.quantityButtons = hasEnabled;
            this.showBottom = true;
        }

        setTimeout(() => {
            this.loading = false;
        }, 10000);
    }

    public goToExplorer(): void {
        window.open(this.explorerLink);
    }

    public getCurrentPlatform(url: string): void {
        if (url.includes('/digital-banking')) {
            this.currentPlatform = MultiplatFormEnum.BANKING;
        } else if (url.includes('crowdfunding')) {
            this.currentPlatform = MultiplatFormEnum.CROWDFUNDING;
        } else if (url.includes('/exchange')) {
            this.currentPlatform = MultiplatFormEnum.EXCHANGE;
        } else if (url.includes('/marketplace')) {
            this.currentPlatform = MultiplatFormEnum.MARKETPLACE;
        } else if (url.includes('/tokenization')){
            this.currentPlatform = MultiplatFormEnum.TOKENIZATION;
        } else {
            this.currentPlatform = MultiplatFormEnum.WALLET;
        }
    }
}
