import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeatureNames } from 'src/app/shared/models/feature-names.enum';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { AssetsService } from 'src/app/shared/services/account/asset.services';
import { FeaturesStatusService } from 'src/app/shared/services/util/features-status.service';
import { NftConversionService } from 'src/app/shared/services/util/nft.conversion.service';
import { forkJoin } from 'rxjs';
import { FinalizedStakingApplication, NFTStakingApplication, StakedNFT } from 'src/app/shared/models/INFTStaking';
import { AccountService } from 'src/app/shared/services/account/account.services';
import { IFiatCurrency } from 'src/app/shared/models/IFiatCurrency';
import BigNumber from 'bignumber.js';
import { CollectionsService } from 'src/app/shared/services/account/collections.service';
import { LanguagesEnum } from 'src/app/shared/models/languages.enum';
import { FormatStringService } from 'src/app/shared/services/util/format-string.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackBarTheme } from 'src/app/shared/models/snackbar-theme.enum';
import { environment } from 'src/environments/environment';
import { CalculateRemainingAuctionTimeService } from 'src/app/shared/services/util/calculate-remaining-auction-time.service';
import { ModalSendComponent } from 'src/app/tokenization/my-assets/modal-send/modal-send.component';
import { ModalResellComponent } from 'src/app/tokenization/my-assets/modal-resell/modal-resell.component';
import { ModalAuctionComponent } from 'src/app/tokenization/my-assets/modal-auction/modal-auction.component';
import { ModalSplitComponent } from 'src/app/tokenization/my-assets/modal-split/modal-split.component';

@Component({
    selector: 'app-card-list',
    templateUrl: './card-list.component.html',
    styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit, OnChanges {

    @Input() public assetList: Array<Assets> = [];
    @Input() public autoScroll: boolean = false;
    @Output() public reloadData: EventEmitter<boolean> = new EventEmitter(false);

    public isResellAllowed: boolean = false;
    public isNFTStakingEnabled: boolean = false;
    public isSendNFTAllowed: boolean = false;
    public isAuctionNFTAllowed: boolean = false;
    public currentPage: string = 'MY_ASSETS';
    public stakedContainerId: string = '';
    public campaignDetails: StakedNFT;
    public rewardIconId: string = '';
    public isAuthenticated: boolean = false;
    public selectedLanguage: LanguagesEnum = undefined;
    public fiatCurrency: IFiatCurrency = new IFiatCurrency();
    public isImageLoaded: boolean = false;
    public userLoggedId: string = '';

    constructor(
        private readonly accountService: AccountService,
        private readonly featuresStatusService: FeaturesStatusService,
        private readonly assetsService: AssetsService,
        private readonly collectionService: CollectionsService,
        private readonly router: Router,
        private readonly nftConversionService: NftConversionService,
        private readonly dialog: MatDialog,
        private readonly formatStringService: FormatStringService,
        private readonly clipboard: Clipboard,
        private readonly snackbarComponent: SnackbarComponent,
        private readonly translationConstants: TranslationConstants,
        private readonly calculateRemainingAuctionTimeService: CalculateRemainingAuctionTimeService
    ) { }

    public ngOnInit(): void {
        forkJoin([
            this.featuresStatusService.getFeatureStatus(FeatureNames.NFT_RESALE),
            this.featuresStatusService.getFeatureStatus(FeatureNames.SEND_NFT),
            this.featuresStatusService.getFeatureStatus(FeatureNames.STAKING_NFT),
            this.featuresStatusService.getFeatureStatus(FeatureNames.AUCTION_NFT),
            this.accountService.isAuthenticated(false, false)
        ]).subscribe(
            ([ResellAllowed, SendNFTAllowed, NFTStakingEnabled, auctionEnabled, isAuthenticated]
        ) => {
            this.isResellAllowed = ResellAllowed;
            this.isSendNFTAllowed = SendNFTAllowed;
            this.isNFTStakingEnabled = NFTStakingEnabled;
            this.isAuctionNFTAllowed = auctionEnabled;
            this.isAuthenticated = isAuthenticated ? true : false;
        });

        this.defineCurrentPage();
        this.fiatCurrency = this.accountService.getFiatCurrency();
        this.selectedLanguage = this.accountService.getUserLanguage();
    }

    private defineCurrentPage(): void {
        if (this.router.url.includes('tokenization/my-assets') || this.router.url === '/home'){
            this.currentPage = 'MY_ASSETS';
        } else {
            this.currentPage = '';
        }
    }

    public getEllipsis(value: string): string {
        return this.formatStringService.getEllipsisInTheMiddle(value, 12);
    }

    public ngOnChanges(): void {
        this.scrollCardAfterLoad();

        for(let asset of this.assetList) {
            if (asset?.collection?.price && !asset?.nftId) {
                this.collectionService.getSumUsedByCollection(asset?.collection.id).subscribe(maxToBuy => {
                    asset.availableTokens = new BigNumber(asset?.collection["price"]).minus(new BigNumber(maxToBuy)).toNumber() as any;
                });
            }

            if (asset?.auction?.deadline) {
                asset.auctionTimeExpiration = this.calculateRemainingAuctionTimeService.calculateRemainingAuctionTime(asset);
            }
        }
    }

    public displaySpecificInfoAsset(asset: Assets): boolean {
        if (asset?.collection_id === environment.collectionId && this.currentPage) {
            return true;
        } else {
            return false;
        }
    }

    public goToLink(asset: any): void {
        window.open(asset?.external_link, '_blank');
    }

    public copyContractAddress(asset: Assets): void {
        const copied: boolean = this.clipboard.copy(asset?.collection?.contract_address);
        if (copied) {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate('snackbar.copied'), SnackBarTheme.success, 3000);
        }
    }

    public scrollCardAfterLoad(): void {
        if (!this.autoScroll) {
            return;
        }
        const cardHeight = document.querySelector('.card')?.clientHeight;
        const optionsToScroll: ScrollToOptions = {
            behavior: 'smooth',
            top: window.scrollY + (cardHeight ? cardHeight : 460)
        }
        window.scrollTo(optionsToScroll);
    }

    public getCorrectPrice(asset: Assets): number {
        return this.assetsService.getAssetCorrectPrice(asset, this.isResellAllowed);
    }

    public getAssetOriginalPrice(asset: Assets): number {
        return this.assetsService.getAssetOriginalPrice(asset, null);
    }

    public getSymbom(asset: Assets): string {
        if(asset.collection?.priceCurrency) {
            return asset.collection.priceCurrency;
        } else {
            return this.fiatCurrency.symbol;
        }
    }

    public getMainPhoto(asset: any): string {
        return this.assetsService.getAssetMainPhoto(asset);
    }

    public goToDetails(asset: Assets) {
        if (asset) {
            //In case there is a nftId, it means we are talking about a resale, hence, we must to search a specific NFT which is stored in a different table
            //In case not, we are looking to a general NFT will be bought for the very first time
            if (asset.nftId) {
                this.router.navigate([`/tokenization/product-details/nft:${asset.nftId}`]);
            } else {
                this.router.navigate([`/tokenization/product-details/asset:${asset.id}`]);
            }
        }
    }

    public goToCollectionProfile(collectionId: string): void {
        if (!collectionId) {
            return;
        }
        this.router.navigate([`/tokenization/collection-profile/${collectionId}`]);
    }

    public openExternalLink(asset: Assets):void {
        window.open(asset.external_link, '_blank');
    }

    public goToStakingNft(): void {
        this.router.navigate(['/tokenization/stake-nft/staked']);
    }

    public sendAsset(asset: Assets) {
        if ((asset?.resale?.onResale && this.isResellAllowed) || asset?.on_staking){
            return;
        }
        const dialogRef = this.dialog.open(ModalSendComponent, {
            // 'custom-modal' class to be responsive
            panelClass: ['send-asset-modal', 'custom-modal'],
            data: {
                asset
            }
        });

        // If you confirmed the shipment, we need to update the asset list.
        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                this.reloadData.emit(true);
            }
        });
    }

    public resellAsset(asset: Assets, isEditing: boolean): void {
        if (asset?.on_staking){
            return;
        }

        const dialogRef = this.dialog.open(ModalResellComponent, {
            // 'custom-modal' class to be responsive
            panelClass: ['resell-asset-modal', 'custom-modal'],
            data: { asset, isEditing }
        });

        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                this.reloadData.emit(true);
            }
        });
    }

    public openStakedModal(containerIndex: number): void {
        this.stakedContainerId = `staked-container-${containerIndex}`;
    }

    public openRewardModal(containerIndex: number): void {
        this.rewardIconId = `reward-icon-${containerIndex}`;
    }

    public checkPeriod(stakedConfig: FinalizedStakingApplication): string {
        return this.nftConversionService.checkPeriod(stakedConfig);
    }

    public calculateRemainingPeriod(applicationConfig: NFTStakingApplication): number {
        return this.nftConversionService.calculateRemainingPeriod(applicationConfig);
    }

    public progressPercentage(percentage: number): number {
        return this.nftConversionService.calculateProgressPercentage(percentage);
    }

    public setResaleTooltip(asset: Assets): string {
        let tranlatePath: string = '';
        if (asset?.on_staking) {
            tranlatePath = 'myAssets.resellAssetStaking';
        } else if (asset?.auction) {
            tranlatePath = 'myAssets.resellAssetAuction';
        } else if (asset?.resale?.onResale) {
            tranlatePath = 'myAssets.editResale';
        } else {
            tranlatePath = 'myAssets.resell';
        }

        return tranlatePath;
    }

    public setAutionTooltip(asset: Assets): string {
        let tranlatePath: string = '';
        if (asset?.on_staking) {
            tranlatePath = 'myAssets.auctionAssetForStaking';
        } else if (asset?.resale?.onResale) {
            tranlatePath = 'myAssets.resellAssetStaking';
        }  else if (asset?.auction) {
            tranlatePath = 'myAssets.assetAlreadyAtAuction';
        } else {
            tranlatePath = 'myAssets.auction';
        }

        return tranlatePath;
    }

    public setSplitTooltip(asset: Assets): string {
        let tranlatePath: string = 'myAssets.split';

        return tranlatePath;
    }

    public setSendTooltip(asset: Assets): string {
        let tranlatePath: string = '';
        if (asset?.on_staking) {
            tranlatePath = 'myAssets.sendAssetStaking';
        } else if (asset?.resale?.onResale) {
            tranlatePath = 'myAssets.sendAssetResale';
        } else if (asset?.auction) {
            tranlatePath = 'myAssets.sendAssetAuction';
        } else {
            tranlatePath = 'myAssets.sendAsset';
        }

        return tranlatePath;
    }

    public auctionAsset(asset: Assets): void {
        if (asset?.on_staking || asset?.resale?.onResale) {
            return;
        }

        const dialogRef = this.dialog.open(ModalAuctionComponent, {
            // 'custom-modal' class to be responsive
            panelClass: ['resell-asset-modal', 'custom-modal'],
            data: { asset }
        });

        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                this.reloadData.emit(true);
            }
        });
    }

    public splitAsset(asset: Assets): void {
        if (asset?.on_staking || asset?.resale?.onResale) {
            return;
        }

        const dialogRef = this.dialog.open(ModalSplitComponent, {
            // 'custom-modal' class to be responsive
            panelClass: ['resell-asset-modal', 'custom-modal'],
            data: { asset }
        });

        dialogRef.afterClosed().subscribe(action => {
            if (action) {
                this.reloadData.emit(true);
            }
        });
    }

    public getAmountOfBidsText(quantity: number): string {
        if (quantity === 0) {
            return this.translationConstants.translate('productDetails.noBidMade');
        } else if (quantity === 1) {
            return this.translationConstants.translate('productDetails.bidMade');
        } else {
            return this.translationConstants.translate('productDetails.bidsMade');
        }
    }
}