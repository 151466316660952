import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { CapacitorHttp } from '@capacitor/core';
import { defer, from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import axios from 'axios';

@Injectable()
export class CapacitorHttpInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.isCapacitor) {
      return this.interceptNativeRequest(req, next);
    }
    return this.interceptWebRequest(req, next);
  }

  private interceptNativeRequest(req: HttpRequest<any>, _next: HttpHandler): Observable<HttpEvent<any>> {
    const { method, body, url, headers, params } = req;

    const sanitizeHeaders = (headers: HttpHeaders) => {
      const res: any = {};
      for (const key of headers.keys()) {
        res[key] = decodeURIComponent(params.get(key) || '');
      }
      res['Content-Type'] = 'application/json';
      return res;
    }


    /**
     * Transforms the type signature of Angular http params
     * to Capacitor's type signature for http params.
     *
     * Sanitizes invalid param values from the output.
     */
    const sanitizeParams = (params: HttpParams) => {
      const res: any = {};
      for (const key of params.keys()) {
        res[key] = decodeURIComponent(params.get(key) || '');
      }
      return res;
    };

    if (url?.includes('i18n')) {
      return _next.handle(req);
    }

    if (method === 'POST') {
      const options = {
        url: url,
        headers: sanitizeHeaders(headers),
        data: body || {},
        body: body || {},
        params: sanitizeParams(params)
      };
      return defer(() => CapacitorHttp.post(options)).pipe(
        catchError(res => {
          let errorResponse = new HttpErrorResponse({
            error: res?.response?.data,
            headers: new HttpHeaders(res["headers"]),
            url: res["url"],
            status: res["status"],
          })
          throw this.handleRequestError(errorResponse);
        }),
        map(res => {
          if (res["status"] >= 400) {
            let errorResponse = new HttpErrorResponse({
              error: res["data"],
              url: res["url"],
              status: res["status"],
            })
            throw this.handleRequestError(errorResponse);
          }
          return new HttpResponse({ body: res["data"] });
        })
      )
    } else if (method === 'PUT') {

      const options = {
        url: url,
        headers: sanitizeHeaders(headers),
        data: body || {},
        body: body || {},
        params: sanitizeParams(params)
      };
      return defer(() => CapacitorHttp.put(options)).pipe(
        catchError(res => {
          let errorResponse = new HttpErrorResponse({
            error: res?.response?.data,
            headers: new HttpHeaders(res["headers"]),
            url: res["url"],
            status: res["status"],
          })
          throw this.handleRequestError(errorResponse);
        }),
        map(res => {
          if (res["status"] >= 400) {
            let errorResponse = new HttpErrorResponse({
              error: res["data"],
              url: res["url"],
              status: res["status"],
            })
            throw this.handleRequestError(errorResponse);
          }
          return new HttpResponse({ body: res["data"] });
        })
      )
    } else if (method === 'DELETE') {
      const options = {
        url: url,
        data: body || {},
        headers: sanitizeHeaders(headers),
        params: sanitizeParams(params)
      };
      return defer(() => CapacitorHttp.delete(options)).pipe(
        catchError(res => {
          let errorResponse = new HttpErrorResponse({
            error: res?.response?.data,
            headers: new HttpHeaders(res["headers"]),
            url: res["url"],
            status: res["status"],
          })
          throw this.handleRequestError(errorResponse);
        }),
        map(res => {
          if (res["status"] >= 400) {
            let errorResponse = new HttpErrorResponse({
              error: res["data"],
              url: res["url"],
              status: res["status"],
            })
            throw this.handleRequestError(errorResponse);
          }
          return new HttpResponse({ body: res["data"] });
        })
      )
    } else if (method === 'GET') {
      const options = {
        url: url,
        headers: sanitizeHeaders(headers),
        params: sanitizeParams(params)
      };
      return defer(() => CapacitorHttp.get(options)).pipe(
        catchError(res => {
          let errorResponse = new HttpErrorResponse({
            error: res?.response?.data,
            headers: new HttpHeaders(res["headers"]),
            url: res["url"],
            status: res["status"],
          })
          throw this.handleRequestError(errorResponse);
        }),
        map(res => {
          if (res["status"] >= 400) {
            let errorResponse = new HttpErrorResponse({
              error: res["data"],
              url: res["url"],
              status: res["status"],
            })
            throw this.handleRequestError(errorResponse);
          }
          return new HttpResponse({ body: res["data"] });
        })
      )
    }
  }

  private interceptWebRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  private handleRequestError(error: HttpErrorResponse) {
    return error;
  }

}