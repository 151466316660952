import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LocalStorageKeys } from './shared/utils/local.storage.keys';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public previousUrl: string = '';
    private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private showBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private newWalletBrazilian: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly sessionStorage: SessionStorageService
    ) {
        const showBalance: boolean = this.sessionStorage.get(LocalStorageKeys.HIDE_BANKING_BALANCE);
        this.showBalance.next(showBalance);
    }

    public setCurrentUrl(url: string): void {
        this.previousUrl = this.currentUrl.getValue();
        this.currentUrl.next(url);
    }

    public getCurrentUrl(): Observable<string> {
        return this.currentUrl.asObservable();
    }

    public setNewWalletBrazilianSubscription(value: boolean): void {
        this.newWalletBrazilian.next(value);
    }

    public getNewWalletBrazilianSubscription(): Observable<boolean> {
        return this.newWalletBrazilian.asObservable();
    }

    public getPreviousUrl(): string {
        return this.previousUrl;
    }

    public getShowBalance(): Observable<boolean> {
        return this.showBalance.asObservable();
    }

    public toggleHideBalance(): void {
        const showBalance: boolean = this.showBalance.getValue();
        this.showBalance.next(!showBalance);
        this.sessionStorage.set(LocalStorageKeys.HIDE_BANKING_BALANCE, !showBalance);
    }
}
