import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import BigNumber from 'bignumber.js';
import { Assets } from 'src/app/shared/models/IAssets.model';
import { IFiatCurrency } from 'src/app/shared/models/IFiatCurrency';
import { SnackBarTheme } from 'src/app/shared/models/snackbar-theme.enum';
import { AccountService } from 'src/app/shared/services/account/account.services';
import { AssetsService } from 'src/app/shared/services/account/asset.services';
import { OnkeypressService } from 'src/app/shared/services/util/onkeypress.service';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { ValueConverterService } from 'src/app/shared/services/util/value-converter.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';

@Component({
    selector: 'app-modal-resell',
    templateUrl: './modal-resell.component.html',
    styleUrls: ['./modal-resell.component.scss']
})
export class ModalResellComponent implements OnInit {

    public loading: boolean;
    public asset: Assets;
    public resaleValue: string;
    public only_for_customer?: string =  undefined;
    public isEditing: boolean = false;
    public fiatCurrency: IFiatCurrency = new IFiatCurrency();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {asset: Assets, isEditing: boolean},
        private readonly dialogRef: MatDialogRef<ModalResellComponent>,
        private readonly snackbarComponent: SnackbarComponent,
        private readonly accountService: AccountService,
        private readonly assetsService: AssetsService,
        private readonly translationConstants: TranslationConstants,
        private readonly keypressService: OnkeypressService,
        public readonly valueConverterService: ValueConverterService
    ) { }

    public ngOnInit(): void {
        this.initData();
    }

    public getMainPhoto(asset: Assets): string {
        return this.assetsService.getAssetMainPhoto(asset);
    }

    public initData(): void {
        if (this.data) {
            this.fiatCurrency = this.accountService.getFiatCurrency();
            this.asset = new Assets(this.data.asset);
            console.log(this.data);
            this.resaleValue = this.valueConverterService.formatMonetaryInput(new BigNumber(this.asset.getCorrectPrice()).toFixed(2), false);
            this.isEditing = this.data.isEditing;
        }
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public closeResell(): void {
        this.performTransaction(new BigNumber(0), 'myAssets.modalResell.snackbar.resaleClosed', 'myAssets.modalResell.snackbar.resaleClosedError');
    }

    public resellAsset(): void {
        const valueToResell: BigNumber = this.valueConverterService.handlingMonetaryAmount(this.resaleValue);
        this.performTransaction(valueToResell, 'myAssets.modalResell.snackbar.nftOnResale', 'myAssets.modalResell.snackbar.nftOnResaleError');
    }

    public saveChange(): void {
        const valueToResell = this.valueConverterService.handlingMonetaryAmount(this.resaleValue);
        this.performTransaction(valueToResell, 'myAssets.modalResell.snackbar.successChanged', 'myAssets.modalResell.snackbar.changeError');
    }

    public performTransaction(valueToResell: BigNumber, sucessMessage: string, errorMessage: string){
        this.assetsService.resellAsset({nftId: this.asset.nftId, resellValue: valueToResell, only_for_customer: this.only_for_customer}).subscribe(success => {
            if (success){
                this.snackbarComponent.openSnackBar(this.translationConstants.translate(sucessMessage), SnackBarTheme.success, 4000);
            }else{
                this.snackbarComponent.openSnackBar(this.translationConstants.translate(errorMessage), SnackBarTheme.success, 4000);
            }
        }, error => {
            this.snackbarComponent.openSnackBar(this.translationConstants.translate(errorMessage), SnackBarTheme.error, 4000);
        }).add(() => {
            this.dialogRef.close(true);
        });
    }

    public onKeyPressEvent(event: any): void {
        this.keypressService.onlyAllowNumbers(event, false, 20);
    }

    public checkValue(): boolean {
        const value = this.valueConverterService.handlingMonetaryAmount(this.resaleValue);
        if (value.isGreaterThan(0)) {
            return false;
        } else {
            return true;
        }
    }
}
