import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReloadUserData {

    public reloadUserData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public shouldreloadUserData$: Observable<boolean> = this.reloadUserData.asObservable();

    constructor(

    ) {
    }

}