import { map, catchError } from 'rxjs/operators';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EscrowRequestsDto } from '../../models/escrow.requets';

@Injectable()
export class CorporateEscrowService {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public createCorporateEscrow(escrowRequest: EscrowRequestsDto): Observable<any> {

        return this.http.post(RestEndpoint.createCorporateEscrow, escrowRequest)
            .pipe(
                map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            }));
    }

    public getEscrows(): Observable<any> {

        return this.http.get(RestEndpoint.getCorporateEscrows)
            .pipe(
                map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            }));
    }

    public getEscrow(escrowId: string): Observable<any> {

        return this.http.get(RestEndpoint.getCorporateEscrow, {params: {escrowId: escrowId}})
            .pipe(
                map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            }));
    }

    public deleteEscrow(escrowId: string): Observable<any> {

        return this.http.post(RestEndpoint.deleteEscrow, {
            id: escrowId
        })
            .pipe(
                map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            }));
    }

    public updateEscrow(escrow: any): Observable<any> {

        return this.http.post(RestEndpoint.updateCorporateEscrow, escrow)
            .pipe(
                map((data: any) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            }));
    }
}