import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ViaCEPResponse } from '../../models/via.cep.response.model';
import { LanguagesEnum } from '../../models/languages.enum';
import { AccountService } from '../account/account.services';

/**
 * Axia CEP service
 */
@Injectable()
export class CepService {

    public selectedLanguage: LanguagesEnum = undefined;

    constructor(
        private readonly http: HttpClient,
        private readonly accountService: AccountService,
    ) { 
        this.selectedLanguage = this.accountService.getUserLanguage();
    }

    public findCep(zipcode: string): Observable<ViaCEPResponse> {
        const validateCep: RegExp = /^[0-9]{8}$/;
        const zipcodeDigits: string = zipcode.replace(/\D/g, '');
        if (!validateCep.test(zipcodeDigits)) {
            throw new Error('Zipcode not valid');
        }

        return this.http.get(`https://viacep.com.br/ws/${zipcodeDigits}/json`, {})
            .pipe(
                map((data: ViaCEPResponse) => {
                    data.pais = "Brasil";
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUFs(): Observable<Array<string>> {
        return this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
            .pipe(
                map((data: Array<{sigla: string}>) => {
                    return data.map(item => item.sigla);
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getCountries(): Observable<Array<string>> { //TODO check this api consult
        const userCountryName: string = this.selectedLanguage === LanguagesEnum.PORTUGUESE ? 'Brasil' : 'United States';

        return this.http.get<Array<any>>('https://restcountries.com/v3.1/all').pipe(
            map((countriesData) => {
                const countries: { name: string }[] = countriesData.map(item => {
                    return {
                        name: this.selectedLanguage === LanguagesEnum.PORTUGUESE ? item?.translations?.por?.common : item?.name?.common
                    };
                });

                // Find the user's country.
                const userCountry: { name: string } = countries.find(country => country?.name === userCountryName);
                if (userCountry) {
                    // Remove user's country from original list.
                    const countryIndex: number = countries.indexOf(userCountry);
                    countries.splice(countryIndex, 1);

                    // Sort the remaining list alphabetically.
                    countries.sort((a, b) => a.name.localeCompare(b.name));

                    // Insert the user's country at the top of the list.
                    countries.unshift(userCountry);
                }
                return countries.map(country => country?.name);
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public findCountry(): Observable<string> {
        return this.http.get(`https://ipinfo.io/json`, {})
            .pipe(
                map((data: any) => {
                    if(data) {
                        return data?.country;
                    }
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}