import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { IAnyExternalLinks, IExternalLinks } from '../../models/IExternalLinks';
import { ProductMedia } from '../../models/product-media.model';
import { MediaType } from '../../models/media-type.enum';

@Injectable({
    providedIn: 'root'
})

export class UtilService
{
    constructor(
        private router: Router,
    ) { }

    public static getHttpParamsFromObject(object: any): HttpParams
    {
        let params = new HttpParams();
        for (const key of Object.keys(object))
        {
            if (object[key])
            {
                params = params.set(key, object[key]);
            }
        }
        return params;
    }

    /**
     * This service will validate if the route is compatible with the mode, otherwise it will be redirected to its default path!
     * @param mode View mode (create, edit and view).
     * @param redirectRoute Route will be redirected if the mode is indifferent.
     */
    public validateRouteMode(mode: string, redirectRoute: string): void {
        if (mode !== 'create' && mode !== 'edit' && mode !== 'view') {
            this.router.navigate([redirectRoute]);
        }
    }

    public static goToUrl(url: string, externalLinks: IExternalLinks, router: Router): void {
        const levels: string[] = url?.split('/');
        let source: IAnyExternalLinks = externalLinks;
        for (let i = 0; i < levels.length; i++){
            source = source[levels[i]];
        }
        if (typeof source === 'string'){
            if(source[0] === '/'){
                router.navigate([source]);
            } else {
                window.open(source);
            }
        }
    }

    public static checkConfiguration(url: string, externalLinks: IExternalLinks): boolean {
        if(!externalLinks) {
            return false;
        }
        const levels: string[] = url?.split('/');
        let source: IAnyExternalLinks = externalLinks;
        for (let i = 0; i < levels.length; i++){
            source = source[levels[i]];
        }
        if (typeof source === 'string'){
            return true;
        } else {
            return false;
        }
    }

    public static readABIFile(): any{
        const abi = require(`../../../../assets/files/abi.json`);
        return abi;
    }

    /**
     * Returns the image that will be displayed on the product card.
     * @param medias the product media array where the main image will be found.
     * @returns A string containing the url of the image.
     */
    public static getExhibitionMediaData(medias: Array<ProductMedia>): string {
        const image: string = this.getMainProductImage(medias);
        if (image) {
            return image;
        } else {
            return this.getVideoImage(medias);
        }
    }

    /**
     * Returns the main image of the product.
     * @param medias the product media array where the main image will be found.
     * @returns A string containing the url of the image.
     */
    private static getMainProductImage(medias: Array<ProductMedia>): string {
        const image: ProductMedia = medias?.find(item => item?.type === MediaType.IMAGE && item?.midia_url !== undefined && Number(item?.order) === 0);
        if (image) {
            return image?.midia_url;
        }
    }

    /**
     * Returns the product video image.
     * @param medias the product media array where the main image will be found.
     * @returns A string containing the url of the image.
     */
    private static getVideoImage(medias: Array<ProductMedia>): string {
        const video: ProductMedia = medias?.find(item => item?.type === MediaType.VIDEO && item?.midia_url !== undefined);
        if(video) {
            var output = video.midia_url.substr(video.midia_url.indexOf("=") + 1);

            return `http://img.youtube.com/vi/${output}/default.jpg`
        }
        return undefined;
    }

    public downloadFileFromUrl(url: string, filename: string ='chat-img'): void {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public downloadFileFromObject(file: File, filename?: string): void {
        const downloadLink = document.createElement('a');
        const blob = new Blob([file], { type: file.type });

        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = filename ||  file.name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}
