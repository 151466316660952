import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import BigNumber from 'bignumber.js';
import { IFiatCurrency } from '../../models/IFiatCurrency';
import { AccountService } from '../account/account.services';
import { CurrencyMaskConfig, CurrencyMaskInputMode } from "ngx-currency";
import { DecimalPipe } from '@angular/common';

interface BigNumberFormat {
    prefix?: string,
    decimalSeparator: string,
    groupSeparator: string,
    groupSize?: number,
    secondaryGroupSize?: number,
    fractionGroupSeparator?: string,
    fractionGroupSize?: number,
    suffix?: string,
}
@Injectable({
    providedIn: 'root'
})
export class ValueConverterService {

    public isInEnglish: boolean = false;
    public customFormat: BigNumberFormat = undefined;
    public decimalSeparator: string = ',';
    public groupSeparator: string = '.'; // Thousand divider, billiards, etc...
    public fiatCurrency: IFiatCurrency = undefined;

    constructor(
        private readonly translate: TranslateService,
        private readonly accountService: AccountService,
        private readonly decimalPipe: DecimalPipe,
    ) {
        this.fiatCurrency = this.accountService.getFiatCurrency();

        const userLanguage: string = this.translate.getBrowserLang();
        this.isInEnglish = userLanguage?.includes('en');
        this.decimalSeparator = this.isInEnglish ? '.' : ',';
        this.groupSeparator = this.isInEnglish ? ',' : '.';
        this.customFormat = {
            decimalSeparator: this.decimalSeparator,
            groupSeparator: this.groupSeparator,
            groupSize: 3
        };
        BigNumber.config({ FORMAT: this.customFormat });
    }

    // Used in inputs with ngx-currency (currencyMask)
    public getOptionsForCurrencyMask(decimalPlaces: number = 2, hidePrefix: boolean = false, customPrefix: string = ''): CurrencyMaskConfig {
        const customCurrencyMaskConfig: CurrencyMaskConfig = {
            align: 'left',
            nullable: true,
            prefix: hidePrefix ? '' : customPrefix || `${this.fiatCurrency.symbol} `,
            suffix: '',
            inputMode: CurrencyMaskInputMode.FINANCIAL,
            allowNegative: false,
            allowZero: true,
            decimal: this.decimalSeparator,
            thousands: this.groupSeparator,
            precision: decimalPlaces,
        };
        return customCurrencyMaskConfig;
    }

    /**
     * Formats the given value in the device's default language (English or Portuguese).
     * @param value The value to be converted.
     * @param decimalPlaces Number of decimal places. Default is 2.
     * @returns Formatted value in string. E.g: 1154064 will return 11.540,64 (pt-br) or 11,540.64 (en).
     */
    public toStringFormat(
        value: number | BigNumber | string = 0,
        decimalPlaces: number = 2,
    ): string {
        if (!value) {
            return new BigNumber(0).toFormat(decimalPlaces);
        }
        if (typeof value === 'string') {
            let newVal = value.replace(/\D/g, '');
            let decimalPlace: string = '';
            let integerValue: string = '';
            if(newVal.length > decimalPlaces) {
                decimalPlace = newVal.slice(newVal.length - decimalPlaces, newVal.length);
                integerValue = newVal.slice(0, newVal.length - decimalPlaces);
            } else {
                decimalPlace = newVal;
                integerValue = '0';
            }
            let tmpDecimalPlace: BigNumber = new BigNumber(decimalPlace).dividedBy(10 ** decimalPlaces);
            const valueToReturn: BigNumber = new BigNumber(integerValue).plus(tmpDecimalPlace);
            return new BigNumber(valueToReturn).toFormat(decimalPlaces);
        } else {
            return new BigNumber(value).toFormat(decimalPlaces);
        }
    }

    /**
     *  Reverses the formatting used in the toStringFormat method.
     * @param value The formatted value in string (based on user language).
     * @returns The value in BigNumber without formatting. E.g: 11.540,64 (pt-br) or 11,540.64 (en) will return new BigNumber(11540.64)
     */
    public fromStringFormatToBigNumber(value: string, decimalPlaces: number = 6): BigNumber {
        if (!value) {
            return new BigNumber(0);
        }
        let newVal = value.replace(/\D/g, '');
        let decimalPlace: string = '';
        let integerValue: string = '';
        if(newVal.length > decimalPlaces) {
            decimalPlace = newVal.slice(newVal.length - decimalPlaces, newVal.length);
            integerValue = newVal.slice(0, newVal.length - decimalPlaces);
        } else {
            decimalPlace = newVal;
            integerValue = '0';
        }
        let tmpDecimalPlace: BigNumber = new BigNumber(decimalPlace).dividedBy(10 ** decimalPlaces);
        const valueToReturn: BigNumber = new BigNumber(integerValue).plus(tmpDecimalPlace);
        return new BigNumber(valueToReturn);
    }

    /**
     * This method calculate how many days are left for expiration based on current date.
     * @param expirationDate It's the expiration date
     *
     * @returns Returns the remaining days relative to today.
     */
    public getDifferenceInDays(expirationDate: Date): string {
        const expiration = expirationDate.getTime();
        const today = new Date().getTime();
        const leftDay = (expiration - today) / (24*3600000); // in days
        const newDate = new Date(leftDay)
        return leftDay.toFixed(0) || "0";
    }

    /**
     * This method calculate how many hours are left for expiration based on current date.
     * @param expirationDate It's the expiration date
     *
     * @returns Returns the remaining days relative to the current time.
     */
    public getDifferenceInHours(expirationDate: Date): string {
        const expiration = expirationDate.getTime();
        const today = new Date().getTime();
        const leftHours = (expiration - today) / 3600000; //  total in hours
        const onlyHours = leftHours % 24;
        return onlyHours.toFixed(0) || "0";
    }

    /**
     * Method created to handle the monetary amount formatted by mask-currency.directive
     *
     * @param value string value handled by mask-currency.directive
     * format expected: $ 5.423,99
     * @returns String provided converted in BigNumber: 5423,99
     */
    public handlingMonetaryAmount(value: string): BigNumber{
        let convertedValue = new BigNumber(0);
        if (value){
            //Remove non-digits, for instance: 5423,99 becames 542399
            let newVal: string = value.replace(/\D/g, '');

            let cents: string;
            let tempCents: BigNumber;
            let integerValue: string = '0';
            //For a monetary amount, latest 2 digits will always be the cents
            if (newVal.length <= 2){
                cents = newVal;
            }else {
                //Split the cents from integers
                //for instance, given the known example 542399
                //cents became 99 and integerValue becames 5423
                cents = newVal.slice(newVal.length - 2, newVal.length);
                integerValue = newVal.slice(0, newVal.length - 2);
            }
            //Divide by 100 to transform in decimal, the example 99 becamse to 0,99
            tempCents = new BigNumber(cents).dividedBy(100);
            //Add again to integerValue, which becames 5423,99 formated as BigNumber
            convertedValue = new BigNumber(integerValue).plus(tempCents);
        }
        return convertedValue;
    }

    /**
     * Method created to handle the monetary amount formatted by mask-currency.directive
     *
     * @param value string value handled by mask-currency.directive
     * format expected: $ 5.423,990201
     * @returns String provided converted in BigNumber: 5423,990201
     */
    public handlingTokenAmount(value: string): BigNumber{
        let convertedValue = new BigNumber(0);
        if (value){
            //Remove non-digits, for instance: 5423,990201 becames 5423990201
            let newVal: string = value.replace(/\D/g, '');

            let cents: string;
            let tempCents: BigNumber;
            let integerValue: string = '0';
            //Split the cents from integers
            //for instance, given the known example 5423990201
            //cents became 990201 and integerValue becames 5423
            cents = newVal.slice(newVal.length - 6, newVal.length);
            integerValue = newVal.slice(0, newVal.length - 6)

            //Divide by 1000000 to transform in decimal, the example 990201 becamse to 0,990201
            tempCents = new BigNumber(cents).dividedBy(1000000);
            //Add again to integerValue, which becames 5423,990201 formated as BigNumber
            convertedValue = new BigNumber(integerValue).plus(tempCents);
        }
        return convertedValue;
    }

    /**
     * Method created to handle the monetary amount formatted by mask-currency.directive
     * This method accepts only 1 decimal place
     *
     * @param value string value handled by mask-currency.directive
     * format expected: 75,0  (without the % symbol)
     * @returns String provided converted in BigNumber: 75
     */
    public handlingPercentualAmount(value: string): BigNumber{
        let convertedValue = new BigNumber(0);
        if (value && value.length > 0){
            let newVal: string = value.replace(/\D/g, '');

            if(newVal.length > 0) {

                let decimalPlace: string;
                let tempDecimalPlace: BigNumber;
                let integerValue: string = '0';
                //In case there is only 1 digit, it means the decimal place
                if (newVal.length === 1){
                    integerValue = '0';
                    decimalPlace = newVal;
                }else if(newVal.length === 2){
                    integerValue = newVal.substring(0,1);
                    decimalPlace = newVal.substring(1,2);
                }else if(newVal.length === 3){
                    integerValue = newVal.substring(0,2);
                    decimalPlace = newVal.substring(2,3);
                //In case the user typed 100 or a number bigger than 100
                //It is expected to string to be 100.0, hence, more than 3 characters
                } else{
                    integerValue = '100';
                    decimalPlace = '0';
                }
                if (decimalPlace){
                    tempDecimalPlace = new BigNumber(decimalPlace).dividedBy(10);
                }
                convertedValue = new BigNumber(integerValue).plus(tempDecimalPlace);
            }

        }

        return convertedValue;
    }

    /**
     * This method converts some monetary value into monetary value token value.
     * @param tokenPrice the token value in relation to the currency type to be formatted. Ex: 1 token = R$ 1,50 => tokenPrice = new BigNumber(1.50).
     * @param amountMoney the monetary amount, without symbol. Ex: R$123,45 => 123,45.
     * @returns value in tokens formatted '1.2-2'.
     */
    public convertCurrencyToTokens(tokenPrice: BigNumber, amountMoney: BigNumber | number | string): string {
        let amountInBig: BigNumber;
        if (typeof amountMoney === 'number' ) {
            amountInBig = new BigNumber(amountMoney);
        } else if (typeof amountMoney === 'string') {
            amountInBig = new BigNumber(parseFloat(amountMoney.replace(',','.')));
        } else {
            amountInBig = amountMoney || new BigNumber(0);
        }
        const tokenAmountInBig = amountInBig.dividedBy(tokenPrice);
        return this.decimalPipe.transform(tokenAmountInBig.toNumber() || 0, '1.6-6');
    }

    //Method to format the input monetary data
    //Given a input data 523400, such method will format to 5.234,00
    public formatMonetaryInput(originalValue: string, withCurrency$: boolean = true): string {
        //Remove all non-digit characters
        let newVal = originalValue.replace(/\D/g, '');
        if (newVal[0] === '0'){
            let i = 0;
            for(; i < newVal.length && newVal[i] === '0'; i++){
                //Do nothing indeed, just go to latest 0 in the string in order to remove it afterwards
                //for instance, given a string 0012, it must to go to index 2, indicating that from
                //index 0 to index 1 it must to be removed
            }
            newVal = newVal.substring(i, newVal.length);
        }

        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 1) {
            //Very first digit is a cent,
            //for instance, if the user typed 5, it becames 0,05
            newVal = newVal.replace(/^(\d{1})/, `0${this.decimalSeparator}0$1`);
            // newVal = newVal.replace(/^(\d{1})/, '0,0$1');
        } else if (newVal.length <= 2) {
            //Second digit is a cent,
            //for instance, if after the 5 the user types 2, it becames 0,52
            newVal = newVal.replace(/^(\d{2})/, '0,$1');
        } else if (newVal.length <= 3) {
            //Then in case the user types a 3, it becames 5,23
            newVal = newVal.replace(/^(\d{0,1})(\d{0,2})/, `$1${this.decimalSeparator}$2`);
        } else if (newVal.length <= 4) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, `$1${this.decimalSeparator}$2`);
        } else if (newVal.length <= 5) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, `$1${this.decimalSeparator}$2`);
        } else if (newVal.length <= 6) {
            //Deal with thousands: 5.234,00
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.decimalSeparator}$3`);
        } else if (newVal.length <= 7) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.decimalSeparator}$3`);
        } else if (newVal.length <= 8) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.decimalSeparator}$3`);
        } else if (newVal.length <= 9) {
            //Deal with millions: 5.234.001,58
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.groupSeparator}$3${this.decimalSeparator}$4`);
        } else if (newVal.length <= 10) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.groupSeparator}$3${this.decimalSeparator}$4`);
        } else if (newVal.length <= 11) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.groupSeparator}$3${this.decimalSeparator}$4`);
        } else {
            //10 places before comma + 2 places for cents are the max value handled.
            newVal = newVal.substring(0, 12);
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, `$1${this.groupSeparator}$2${this.groupSeparator}$3${this.groupSeparator}$4${this.decimalSeparator}$5`);
        }
        if (newVal){
            return `${withCurrency$ ? '$' : ''}` + newVal;
        }
        return ;
    }

}
